import NoteHeader from '../sections/NoteHeader';
import TemplateComponent from '../templates/TemplateComponent';
import { EncounterDTO } from '@aster/app/core/shared/dtos/encounter';
import { useCallback, useEffect, useState } from 'react';
import { Codes } from '../types/encounter';
import { defaultPrenatalData } from '../constants';
import { useUpdateEncounterMutation } from '../mutations/update-encounter.mutation';
import { useParams } from 'react-router';
import { useAuth } from '../../../authentication/AuthProvider';
import ConfirmationModal from '../../../components/ConfirmationModal';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import Typography from '../../../components/Typography';
import MultipleCheckboxes from '../../../components/MultipleCheckboxes';
import TextArea from '../../../components/TextArea';
import CollapsibleText from '../../../components/CollapsibleText';
import womanFigure from '../../../assets/Woman_Figure.webp';
import { usePatientProfileQuery } from '../queries/patient-profile.query';
import { isPatientReadonly } from '../../patients/utils/is-patient-readonly';
import { FormControlLabel, FormLabel, Radio } from '@mui/material';
import Input from '../../../components/Input';
import SelectDropdown from '../../../components/SelectDropdown';

type Item = {
  text: string;
  value: string;
  checked: boolean;
};

const defaultFlags: Item[] = [
  {
    text: 'None of the following',
    value: 'None of the following',
    checked: false,
  },
  { text: 'Pain & contractions', value: 'Pain & contractions', checked: false },
  {
    text: 'Visual changes or loss',
    value: 'Visual changes or loss',
    checked: false,
  },
  { text: 'Vaginal bleeding', value: 'Vaginal bleeding', checked: false },
  { text: 'Sudden swelling', value: 'Sudden swelling', checked: false },
  {
    text: 'Reduced fetal movement',
    value: 'Reduced fetal movement',
    checked: false,
  },
  { text: 'Headaches', value: 'Headaches', checked: false },
  { text: 'RUQ pain', value: 'RUQ pain', checked: false },
  { text: 'Leaking of fluid', value: 'Leaking of fluid', checked: false },
  { text: 'Other', value: 'Other', checked: false },
];

const buttons = [
  { value: 'prenatalIniVis', text: 'Inital Visit' },
  { value: 'week1014', text: '10-14' },
  { value: 'week1519', text: '15-19' },
];

const fetalPositionMethods = [
  { value: 'leopolds', text: "Leopold's" },
  {
    value: 'ultrasound',
    text: 'Ultrasound',
  },
];

type PrenatalNoteContentProps = {
  encounterData: EncounterDTO | undefined;
  enforceSaveMissing: boolean;
  setEnforceSaveMissing: (value: boolean) => void;
  handleUnsaved: (isDirty: boolean) => void;
  isEncounterLoading: boolean;
};

type PrenatalNoteState = {
  notes: string;
  extraFlag: string;
  imaging: string;
  mood: string;
  safety: string;
  plan: string;
  measurement: string;
  measurementData: string;
  pain?: boolean;
  painInformation: string;
  fetalHeartRate: string;
  fundalHeight: string;
  uterusPalpationDescription: string;
  fetalMovement?: boolean;
  fetalPosition: string;
  fetalPositionMethod: string;
};

export default function PrenatalNoteContent({
  encounterData,
  enforceSaveMissing,
  setEnforceSaveMissing,
  handleUnsaved,
  isEncounterLoading,
}: PrenatalNoteContentProps) {
  const [isCollapsed, setCollapsed] = useState(true);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [flagItems, setFlagItems] = useState<Item[]>(
    encounterData?.content?.flags ?? defaultFlags
  );
  const [state, setState] = useState<PrenatalNoteState>(() => ({
    notes: encounterData?.content?.notes || defaultPrenatalData.notes,
    extraFlag:
      encounterData?.content?.extraFlag || defaultPrenatalData.extraFlag,
    imaging: encounterData?.content?.imaging || defaultPrenatalData.imaging,
    mood: encounterData?.content?.mood || defaultPrenatalData.mood,
    safety: encounterData?.content?.safety || defaultPrenatalData.safety,
    plan: encounterData?.content?.plan || defaultPrenatalData.plan,
    measurement:
      encounterData?.content?.prenatalExamination?.measurement ||
      defaultPrenatalData.measurement,
    measurementData:
      encounterData?.content?.prenatalExamination?.measurementData ||
      defaultPrenatalData.measurementData,
    pain:
      encounterData?.content?.prenatalExamination?.pain ??
      defaultPrenatalData.pain,
    painInformation:
      encounterData?.content?.prenatalExamination?.painInformation,
    fetalHeartRate: encounterData?.content?.prenatalExamination?.fetalHeartRate,
    fundalHeight:
      encounterData?.content?.prenatalExamination?.fundalHeight ?? '',
    uterusPalpationDescription:
      encounterData?.content?.prenatalExamination?.uterusPalpationDescription ??
      '',
    fetalMovement: encounterData?.content?.prenatalExamination?.fetalMovement,
    fetalPosition:
      encounterData?.content?.prenatalExamination?.fetalPosition ?? '',
    fetalPositionMethod:
      encounterData?.content?.prenatalExamination?.fetalPositionMethod ?? '',
  }));
  const [selectedCodes, setSelectedCodes] = useState<Codes>(() => ({
    cptCodes: encounterData?.cptCodes ?? [],
    icdCodes: encounterData?.icdCodes ?? [],
  }));

  const isEncounterSigned = encounterData?.signedBy !== null;

  const handleChangeCodes = (codes: Codes) => {
    setSelectedCodes(codes);
  };

  const { patient, encounterId } = useParams();
  const { updateEncounter } = useUpdateEncounterMutation(encounterId, () => {
    handleUnsaved(false);
    setEnforceSaveMissing(false);
  });

  const { patientProfile } = usePatientProfileQuery(patient);

  const saveInfo = useCallback(
    (signed: boolean) => {
      const info = {
        patientId: patient,
        templateType: 'prenatal' as const,
        signed,
        content: {
          notes: state.notes,
          flags: flagItems,
          extraFlag: state.extraFlag,
          imaging: state.imaging,
          mood: state.mood,
          safety: state.safety,
          plan: state.plan,
          prenatalExamination: {
            measurement: state.measurement,
            measurementData: state.measurementData,
            pain: state.pain,
            painInformation: state.painInformation,
            fetalHeartRate: state.fetalHeartRate,
            fundalHeight: state.fundalHeight,
            uterusPalpationDescription: state.uterusPalpationDescription,
            fetalMovement: state.fetalMovement,
            fetalPosition: state.fetalPosition,
            fetalPositionMethod: state.fetalPositionMethod,
          },
        },
        cptCodes: selectedCodes.cptCodes,
        icdCodes: selectedCodes.icdCodes,
      };
      if (signed) {
        setOpenConfirmation(false);
      }
      updateEncounter.mutate(info);
    },
    [
      flagItems,
      patient,
      selectedCodes.cptCodes,
      selectedCodes.icdCodes,
      state.extraFlag,
      state.fetalHeartRate,
      state.fetalMovement,
      state.fetalPosition,
      state.fetalPositionMethod,
      state.fundalHeight,
      state.imaging,
      state.measurement,
      state.measurementData,
      state.mood,
      state.notes,
      state.pain,
      state.painInformation,
      state.plan,
      state.safety,
      state.uterusPalpationDescription,
      updateEncounter,
    ]
  );

  useEffect(() => {
    if (enforceSaveMissing) {
      saveInfo(false);
      setEnforceSaveMissing(false);
    }
  }, [enforceSaveMissing, saveInfo, setEnforceSaveMissing]);

  const toggleCollapse = () => {
    setCollapsed(!isCollapsed);
  };

  const handleFormInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    handleUnsaved(true);
  };

  const handleChangeNote = (content: string) => {
    setState((prevState) => ({
      ...prevState,
      notes: content,
    }));
    handleUnsaved(true);
  };

  const handleOption = (
    event: React.ChangeEvent<HTMLInputElement>,
    option: string
  ) => {
    setState((prevState) => ({
      ...prevState,
      [option]: event.target.value,
    }));
    handleUnsaved(true);
  };

  const handleCheckboxChange = (value: string) => {
    setFlagItems((prevItems) =>
      prevItems.map((item) =>
        item.value === value ? { ...item, checked: !item.checked } : item
      )
    );
    handleUnsaved(true);
  };

  const { profile } = useAuth();

  if (isEncounterLoading) {
    return (
      <div className="flex flex-col justify-center items-center h-full w-full">
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className="flex flex-col mt-5 px-10 flex-grow lg:overflow-y-auto">
      <NoteHeader
        encounterInfo={encounterData}
        selectedCodes={selectedCodes}
        disabled={updateEncounter.status === 'pending'}
        setSelectedCodes={handleChangeCodes}
        title="Prenatal Note"
        buttonMessageLeft="Save draft"
        actionLeft={() => saveInfo(false)}
        buttonMessageRight="Sign note"
        actionRight={() => setOpenConfirmation(true)}
        hide={isEncounterSigned || isPatientReadonly(patientProfile)}
      />

      <div className="flex flex-col md:flex-row pb-10 scroll-pb-10">
        {/* Middle section */}
        <div className="flex flex-col gap-[15px] w-full mb-10 min-w-[360px]">
          <div className="w-full">
            <TemplateComponent
              noteInfo={state.notes}
              label="Notes"
              signed={isEncounterSigned || isPatientReadonly(patientProfile)}
              handleChangeNote={handleChangeNote}
              buttons={buttons}
            />
          </div>
          <div className="w-full">
            <div className="w-full">
              <button
                onClick={toggleCollapse}
                className="w-full rounded-lg hover:bg-grayBackground flex justify-start items-center mb-2"
              >
                {!isCollapsed && <ArrowRightRoundedIcon />}
                {isCollapsed && <ArrowDropDownRoundedIcon />}
                <Typography variant="h6" text="Flags" />
              </button>
            </div>
            {isCollapsed && (
              <>
                <div className="flex flex-row w-full">
                  <MultipleCheckboxes
                    handleCheckboxChange={handleCheckboxChange}
                    items={flagItems}
                    separateInHalf={!(window.innerWidth < 900)}
                    disabled={
                      isEncounterSigned || isPatientReadonly(patientProfile)
                    }
                  />
                </div>
                <TextArea
                  name="extraFlag"
                  textValue={state.extraFlag}
                  handleChange={handleFormInput}
                  disabled={
                    isEncounterSigned || isPatientReadonly(patientProfile)
                  }
                />
              </>
            )}
          </div>
          <div>
            <CollapsibleText
              label="Imaging"
              textValue={state.imaging}
              name="imaging"
              handleChange={handleFormInput}
              disabled={isEncounterSigned || isPatientReadonly(patientProfile)}
            />
          </div>
          <div>
            <CollapsibleText
              label="Mood"
              textValue={state.mood}
              name="mood"
              handleChange={handleFormInput}
              disabled={isEncounterSigned || isPatientReadonly(patientProfile)}
            />
          </div>
          <div>
            <CollapsibleText
              label="Safety"
              textValue={state.safety}
              name="safety"
              handleChange={handleFormInput}
              disabled={isEncounterSigned || isPatientReadonly(patientProfile)}
            />
          </div>
          <div>
            <CollapsibleText
              label="Plan"
              textValue={state.plan}
              name="plan"
              handleChange={handleFormInput}
              disabled={isEncounterSigned || isPatientReadonly(patientProfile)}
            />
          </div>
        </div>
        {/* Examination Section */}
        <div className="md:w-1/3 my-2 md:my-0 mx-5 flex flex-col">
          <Typography variant="h6" text="Examination" />
          <div className="w-full mt-2">
            <img src={womanFigure} alt="Woman Figure" className="my-2" />
          </div>
          <div className="mt-10 space-y-6">
            <div className="space-y-1">
              <FormLabel className="text-[14px] font-semibold text-black">
                Fundal Height
              </FormLabel>
              <Input
                name="fundalHeight"
                className="[&>input]:p-3"
                placeholder="Fundal Height (cm)"
                textValue={state.fundalHeight}
                handleChange={(evt) => handleFormInput(evt)}
              />
            </div>
            <div className="space-y-1">
              <FormLabel className="text-[14px] font-semibold text-black">
                Uterus Palpation
              </FormLabel>
              <Input
                name="uterusPalpationDescription"
                className="[&>input]:p-3"
                placeholder="Enter description"
                textValue={state.uterusPalpationDescription}
                handleChange={(evt) => handleFormInput(evt)}
              />
            </div>
            <div className="space-y-4">
              <div className="space-y-1">
                <FormLabel className="text-[14px] font-semibold text-black">
                  Pain
                </FormLabel>
                <div className="flex-col space-y-4">
                  <FormControlLabel
                    className="w-full"
                    label="Yes"
                    control={
                      <Radio
                        checked={state.pain === true}
                        onChange={() =>
                          setState((state) => ({ ...state, pain: true }))
                        }
                        disableRipple
                      />
                    }
                  />
                  <FormControlLabel
                    className="w-full"
                    label="No"
                    control={
                      <Radio
                        checked={state.pain === false}
                        onChange={() =>
                          setState((state) => ({ ...state, pain: false }))
                        }
                        disableRipple
                      />
                    }
                  />
                </div>
              </div>
              <Input
                name="painInformation"
                className="[&>input]:p-3"
                placeholder="Enter a note"
                textValue={state.painInformation}
                handleChange={(evt) => handleFormInput(evt)}
              />
            </div>
            <div className="space-y-1">
              <FormLabel className="text-[14px] font-semibold text-black">
                Fetal Movement
              </FormLabel>
              <div className="flex-col space-y-4">
                <FormControlLabel
                  className="w-full"
                  label="Yes"
                  control={
                    <Radio
                      checked={state.fetalMovement === true}
                      onChange={() =>
                        setState((state) => ({
                          ...state,
                          fetalMovement: true,
                        }))
                      }
                      disableRipple
                    />
                  }
                />
                <FormControlLabel
                  className="w-full"
                  label="No"
                  control={
                    <Radio
                      checked={state.fetalMovement === false}
                      onChange={() =>
                        setState((state) => ({
                          ...state,
                          fetalMovement: false,
                        }))
                      }
                      disableRipple
                    />
                  }
                />
              </div>
            </div>
            <div className="space-y-1">
              <FormLabel className="text-[14px] font-semibold text-black">
                Fetal Heart Rate (bpm)
              </FormLabel>
              <Input
                name="fetalHeartRate"
                className="[&>input]:p-3"
                placeholder="Enter FHR"
                textValue={state.fetalHeartRate}
                handleChange={(evt) => handleFormInput(evt)}
              />
            </div>
            <div className="space-y-4">
              <div className="space-y-1">
                <FormLabel className="text-[14px] font-semibold text-black">
                  Fetal Position
                </FormLabel>
                <div className="flex-col space-y-4">
                  <FormControlLabel
                    className="w-full"
                    label="Vertex"
                    control={
                      <Radio
                        checked={state.fetalPosition === 'vertex'}
                        onChange={() =>
                          setState((state) => ({
                            ...state,
                            fetalPosition: 'vertex',
                          }))
                        }
                        disableRipple
                      />
                    }
                  />
                  <FormControlLabel
                    className="w-full"
                    label="Breech"
                    control={
                      <Radio
                        checked={state.fetalPosition === 'breech'}
                        onChange={() =>
                          setState((state) => ({
                            ...state,
                            fetalPosition: 'breech',
                          }))
                        }
                        disableRipple
                      />
                    }
                  />
                  <FormControlLabel
                    className="w-full"
                    label="Transverse"
                    control={
                      <Radio
                        checked={state.fetalPosition === 'transverse'}
                        onChange={() =>
                          setState((state) => ({
                            ...state,
                            fetalPosition: 'transverse',
                          }))
                        }
                        disableRipple
                      />
                    }
                  />
                </div>
              </div>
              <SelectDropdown
                placeholder="FP Method"
                options={fetalPositionMethods}
                value={state.fetalPositionMethod}
                handleChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                  handleOption(evt, 'fetalPositionMethod')
                }
                classes="[&_.MuiSelect-select]:pl-[15px] [&_.MuiSelect-select]:py-[13.5px]"
              />
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        open={openConfirmation}
        title="Sign this encounter note?"
        description={`You're signing as ${profile && profile.firstName} ${
          profile && profile.lastName
        }`}
        confirm="Sign"
        dismiss="Cancel"
        handleClose={() => setOpenConfirmation(false)}
        handleConfirm={() => saveInfo(true)}
        handleCancel={() => setOpenConfirmation(false)}
      />
    </div>
  );
}
