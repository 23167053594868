import { Button } from '@aster/client/ui/Button/Button';
import { Typography } from '@aster/client/ui/Typography/Typography';
import {
  ToggleGroup,
  ToggleGroupItem,
} from '@aster/client/ui/ToggleGroup/ToggleGroup';
import { useEffect, useRef } from 'react';
import { useMessageThreadSearch } from '../hooks/use-search-message-threads';
import { Input } from '@aster/client/ui/Input/Input';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MessageStatusDTO } from '@aster/app/message/shared/dtos';
import { useMessagesStore } from '../store/message.store';
import { MessagesContent } from './MessagesContent';

export const Messages = () => {
  const selectMessageThreadID = useMessagesStore(
    (state) => state.selectMessageThreadID
  );
  const clearMessageThreadID = useMessagesStore(
    (state) => state.clearMessageThreadID
  );
  const openNewMessageThreadComposer = useMessagesStore(
    (state) => state.openNewMessageThreadComposer
  );
  const currentTabValue = useMessagesStore((state) => state.currentTabValue);
  const setCurrentTabValue = useMessagesStore(
    (state) => state.setCurrentTabValue
  );
  const {
    paginatedMessageThreads: paginatedActiveMessageThreads,
    areMessageThreadsLoading: areActiveMessageThreadsLoading,
    search: searchActive,
    currentSearchQueryKey: currentActiveSearchQueryKey,
  } = useMessageThreadSearch('active');
  const {
    paginatedMessageThreads: paginatedArchivedMessageThreads,
    areMessageThreadsLoading: areArchivedMessageThreadsLoading,
    search: searchArchived,
    currentSearchQueryKey: currentArchivedSearchQueryKey,
  } = useMessageThreadSearch('archived');
  const searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const activeClause =
      currentTabValue === 'active' &&
      !areActiveMessageThreadsLoading &&
      paginatedActiveMessageThreads &&
      paginatedActiveMessageThreads.items?.length > 0;
    const archivedClause =
      currentTabValue === 'archived' &&
      !areArchivedMessageThreadsLoading &&
      paginatedArchivedMessageThreads &&
      paginatedArchivedMessageThreads.items?.length > 0;

    if (activeClause) {
      selectMessageThreadID(paginatedActiveMessageThreads.items[0].id);
    } else if (archivedClause) {
      selectMessageThreadID(paginatedArchivedMessageThreads.items[0].id);
    }

    if (
      (areActiveMessageThreadsLoading && currentTabValue === 'active') ||
      (areArchivedMessageThreadsLoading && currentTabValue === 'archived')
    ) {
      clearMessageThreadID();
    }
    // The effect should run only based on the loading indicator otherwise you get double action
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    areActiveMessageThreadsLoading,
    areActiveMessageThreadsLoading,
    currentTabValue,
    clearMessageThreadID,
    selectMessageThreadID,
  ]);

  return (
    <section className="px-9 py-6 flex flex-col gap-6 h-full overflow-hidden">
      <header className="flex justify-between items-center">
        <Typography variant="h1">Messages</Typography>
        <Button onClick={openNewMessageThreadComposer}>New Message</Button>
      </header>
      <div className="flex items-center justify-between">
        <ToggleGroup
          defaultValue="active"
          value={currentTabValue}
          onValueChange={(value) => {
            if (value !== 'active' && value !== 'archived') return;
            if (searchRef.current) {
              searchRef.current.value = '';
              searchActive('');
              searchArchived('');
            }
            setCurrentTabValue(value as MessageStatusDTO);
          }}
          type="single"
        >
          <ToggleGroupItem className="gap-4" value="active" aria-label="Inbox">
            Inbox{' '}
            <span>
              {areActiveMessageThreadsLoading
                ? null
                : paginatedActiveMessageThreads?.queryMetadata.rowCount}
            </span>
          </ToggleGroupItem>
          <ToggleGroupItem
            className="gap-4"
            value="archived"
            aria-label="Archived"
          >
            Archived
            <span>
              {areArchivedMessageThreadsLoading
                ? null
                : paginatedArchivedMessageThreads?.queryMetadata.rowCount}
            </span>
          </ToggleGroupItem>
        </ToggleGroup>
        <Input
          ref={searchRef}
          onChange={(e) =>
            currentTabValue === 'active'
              ? searchActive(e.target.value)
              : searchArchived(e.target.value)
          }
          className="w-1/3"
          placeholder="Search"
          startAdornment={<FontAwesomeIcon icon={faMagnifyingGlass} />}
        />
      </div>
      <div className="grid grid-cols-3 gap-x-4 flex-1 bg-gray-100 overflow-hidden rounded-md p-5">
        <MessagesContent
          currentActiveSearchQueryKey={currentActiveSearchQueryKey}
          currentArchivedSearchQueryKey={currentArchivedSearchQueryKey}
          paginatedMessageThreads={
            currentTabValue === 'active'
              ? paginatedActiveMessageThreads
              : paginatedArchivedMessageThreads
          }
          areMessageThreadsLoading={
            currentTabValue === 'active'
              ? areActiveMessageThreadsLoading
              : areArchivedMessageThreadsLoading
          }
        />
      </div>
    </section>
  );
};
