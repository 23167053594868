import { Typography } from '@aster/client/ui/Typography/Typography';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const NoMessages = () => {
  return (
    <div className="flex flex-col items-center col-span-3 mt-[10%]">
      <FontAwesomeIcon
        size="4x"
        className="text-aster-main"
        icon={faEnvelope}
      />
      <Typography variant="p" className="text-2xl">
        No messages
      </Typography>
    </div>
  );
};
