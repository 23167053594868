import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from '../../../components/Snack';
import axios from '../../../../src/app/axiosConfig';
import {
  createMutationScopeID,
  MutationScope,
} from '../../../../src/mutations/utils/create-mutation-scope-id';
import { createId } from '@paralleldrive/cuid2';

const useInviteMembersMutation = (
  onSuccessCb?: (() => void) | null,
  onErrorCb?: (() => void) | null
) => {
  const queryClient = useQueryClient();
  const { showMessage } = useSnackbar();

  const sendInvite = async (email: string) => {
    await axios.post(`staffs/invite`, { email }).then(() => {
      void queryClient.invalidateQueries({ queryKey: ['pendingInvites'] });
    });
  };

  const inviteMembersMutation = useMutation({
    mutationKey: ['inviteStaff'],
    mutationFn: sendInvite,
    scope: {
      id: createMutationScopeID(MutationScope.MEMBER),
    },
    onMutate: async (newInvite) => {
      const emails = newInvite.split(',');
      await queryClient.cancelQueries({ queryKey: ['pendingInvites'] });

      const previousPendingInvites = queryClient.getQueryData([
        'pendingInvites',
      ]);

      queryClient.setQueryData(
        ['pendingInvites'],
        (old: string[] | undefined) => {
          const existingOldEmail = old ?? [];
          const optimisticInvites = emails.map((email: string) => ({
            token: createId(),
            metadata: {
              targetEmail: email,
            },
          }));
          return [...optimisticInvites, ...existingOldEmail];
        }
      );
      return { previousPendingInvites };
    },
    onError: (_err, _newTodo, context) => {
      queryClient.setQueryData(
        ['pendingInvites'],
        context?.previousPendingInvites
      );
      showMessage({
        message: 'An error occurred while inviting staff. Please try again.',
        type: 'error',
      });
      onErrorCb && onErrorCb();
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: ['pendingInvites'] });
      void queryClient.invalidateQueries({ queryKey: ['plan'] });
    },
    onSuccess: (data, newInvite) => {
      const emails = newInvite.split(',');
      const createSnackbarMessage = `${emails.length} invite${
        emails.length > 1 ? 's' : ''
      } sent via email`;
      showMessage({
        message: createSnackbarMessage,
        type: 'success',
      });
      onSuccessCb && onSuccessCb();
    },
  });
  return { inviteMembersMutation };
};

export default useInviteMembersMutation;
