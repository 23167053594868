import React, { useState, useMemo, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ButtonType from '../../../components/Button';
import StyledDataGrid from '../../../components/StyledDataGrid';
import BasicTextfield from '../../../components/Textfield';
import { useSnackbar } from '../../../components/Snack';
import { colors } from '../../../theme';
import dayjs from 'dayjs';
import CollapsibleComponent from '../../../components/CollapsibleComponent';
import { useParams } from 'react-router';
import { CreateVitalsDTO, VitalsDTO } from '@aster/app/core/shared/dtos/vitals';
import { usePatientVitals } from './queries/fetch-vitals.query';
import { useAddVitalsMutation } from './mutations/add-vital.mutation';
import SelectDropdown from '../../../components/SelectDropdown';
import { useForm, useStore } from '@tanstack/react-form';
import { usePatientInfoQuery } from '../../patients/queries/patient-info.query';

type VitalsProps = {
  enforceSaveMissing: boolean;
  disabled: boolean;
  handleUnsaved: (isDirty: boolean) => void;
  handleNote?: (note: string) => void;
};

// mui select component needs the initial value to be from the same
// object reference of the passed options

const tempUnitOptions = [
  { value: 'F', text: '°F' },
  { value: 'C', text: '°C' },
];

const weightUnitOptions = [
  { value: 'lbs', text: 'lbs' },
  { value: 'kgs', text: 'kgs' },
];

const heightUnitOptions = [
  { value: 'ft', text: 'ft' },
  { value: 'cm', text: 'cm' },
];

const Vitals = ({
  disabled,
  enforceSaveMissing,
  handleNote,
  handleUnsaved,
}: VitalsProps) => {
  const { showMessage } = useSnackbar();
  const [open, setOpen] = useState(false);
  const { patient, encounterId } = useParams();

  const { patientInfo } = usePatientInfoQuery(patient);

  const { isLoading, vitals } = usePatientVitals(patient as string);
  const { addVitalsMutation } = useAddVitalsMutation(
    patient as string,
    () => {
      const vitals = VitalsForm.state.values;
      createNote(vitals);
      VitalsForm.reset();
      handleUnsaved(false);
      showMessage({
        message: 'Vitals saved successfully',
        type: 'success',
      });
    },
    () => {
      showMessage({
        message: 'Failed to save vitals',
        type: 'error',
      });
    }
  );

  const defaultHeightValues = useMemo(() => {
    const existingHeight = vitals?.find((vital) => vital.heightUnit !== null);
    if (existingHeight && existingHeight.heightUnit) {
      return {
        heightUnit: existingHeight.heightUnit,
        feet: existingHeight.feet,
        inches: existingHeight.inches,
        height: existingHeight.height,
      };
    }
    return undefined;
  }, [vitals]);

  const vitalsRows = useMemo(() => {
    if (vitals) {
      return vitals.map((vital) => ({
        ...vital,
        bloodPressure: vital.bloodPressure
          ? vital.bloodPressure
          : vital.diastolic || vital.systolic
          ? `${vital.systolic ?? ''}/${vital.diastolic ?? ''}`
          : '',
        temperature: `${vital.temperature ?? ''} ${
          vital.temperatureUnit ? '˚' + vital.temperatureUnit : ''
        }`,
        weight: `${vital.weight ?? ''} ${vital.weightUnit ?? ''}`,
        height:
          vital.heightUnit === 'ft'
            ? `${vital.feet}' ${vital.inches}"`
            : vital.heightUnit === 'cm'
            ? `${vital.height} cm`
            : vital.height,
        spo2: `${vital.spo2 ? vital.spo2 + '%' : ''}`,
      }));
    }
    return [];
  }, [vitals]);

  const createNote = (vitals: Omit<CreateVitalsDTO, 'patientID'>) => {
    const note = `Vitals: 
          ${vitals.bloodPressure ? `BP: ${vitals.bloodPressure},` : ''}
          ${vitals.heartRate ? `HR: ${vitals.heartRate},` : ''}
          ${
            vitals.temperature
              ? `Temperature: ${vitals.temperature} ${
                  vitals.temperatureUnit ?? ''
                },`
              : ''
          }
          ${vitals.bloodGlucose ? `BG: ${vitals.bloodGlucose},` : ''}
          ${
            vitals.weight
              ? `Weight: ${vitals.weight} ${vitals.weightUnit ?? ''},`
              : ''
          }
          ${
            vitals.height || vitals.feet
              ? `Height: ${
                  vitals.heightUnit === 'ft'
                    ? `${vitals.feet}' ${vitals.inches}"`
                    : vitals.heightUnit === 'cm'
                    ? `${vitals.height} cm`
                    : vitals.height
                }`
              : ''
          }
          ${vitals.spo2 ? `SpO2: ${vitals.spo2},` : ''}
          ${vitals.bmi ? `BMI: ${vitals.bmi},` : ''}
         at ${dayjs(new Date()).format('MMMM D, YYYY h:mm A')}.`;
    handleNote && handleNote(note);
  };

  const saveVitals = async (vitals: Omit<CreateVitalsDTO, 'patientID'>) => {
    const newResult: CreateVitalsDTO = {
      diastolic:
        vitals.diastolic && vitals.diastolic > 0 ? vitals.diastolic : undefined,
      systolic:
        vitals.systolic && vitals.systolic > 0 ? vitals.systolic : undefined,
      heartRate:
        vitals.heartRate && vitals.heartRate > 0 ? vitals.heartRate : undefined,
      temperature: vitals.temperature === '' ? undefined : vitals.temperature,
      temperatureUnit:
        vitals.temperature && vitals.temperatureUnit
          ? vitals.temperatureUnit
          : undefined,
      bloodGlucose:
        vitals.bloodGlucose && vitals.bloodGlucose > 0
          ? vitals.bloodGlucose
          : undefined,
      weight: vitals.weight && vitals.weight > 0 ? vitals.weight : undefined,
      weightUnit:
        vitals.weightUnit && vitals.weight ? vitals.weightUnit : undefined,
      height: vitals.height && vitals.height > 0 ? vitals.height : undefined,
      feet: vitals.feet && vitals.feet > 0 ? vitals.feet : undefined,
      inches: vitals.feet && vitals.feet > 0 ? vitals.inches : undefined,
      heightUnit:
        vitals.heightUnit && vitals.feet ? vitals.heightUnit : undefined,
      spo2: vitals.spo2 && vitals.spo2 > 0 ? vitals.spo2 : undefined,
      bmi: vitals.bmi === '' ? undefined : vitals.bmi,
      patientID: patient as string,
      encounterID: encounterId,
    };
    if (canSubmitForm) {
      await addVitalsMutation.mutateAsync(newResult);
    } else {
      VitalsForm.reset();
    }
  };

  const handleClose = () => setOpen(false);

  const columns: GridColDef<VitalsDTO>[] = [
    {
      field: 'timeCreated',
      renderCell: (params) =>
        dayjs(params.row.timeCreated).format('MMMM D, YYYY h:mm A'),
      headerName: 'Date & Time',
      headerAlign: 'left',
      width: 200,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'bloodPressure',
      headerName: 'BP',
      headerAlign: 'left',
      width: 100,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'heartRate',
      headerName: 'HR',
      headerAlign: 'left',
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'temperature',
      headerName: 'Temp.',
      headerAlign: 'left',
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'bloodGlucose',
      headerName: 'BG',
      headerAlign: 'left',
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'weight',
      headerName: 'Weight',
      headerAlign: 'left',
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'height',
      headerName: 'Height',
      headerAlign: 'left',
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'spo2',
      headerName: 'SpO2',
      headerAlign: 'left',
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'bmi',
      headerName: 'BMI',
      headerAlign: 'left',
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
  ];

  const VitalsForm = useForm({
    defaultValues: {
      systolic: 0,
      diastolic: 0,
      heartRate: 0,
      temperature: '',
      temperatureUnit: 'F' as 'F' | 'C',
      bloodGlucose: 0,
      weight: 0,
      weightUnit: 'lbs' as 'lbs' | 'kgs',
      height:
        defaultHeightValues?.heightUnit === 'cm' && defaultHeightValues?.height
          ? defaultHeightValues.height
          : 0,
      heightUnit:
        (defaultHeightValues && defaultHeightValues?.heightUnit) ??
        ('ft' as 'ft' | 'cm'),
      feet:
        defaultHeightValues?.feet && defaultHeightValues?.heightUnit === 'ft'
          ? defaultHeightValues.feet
          : 0,
      inches:
        defaultHeightValues?.inches && defaultHeightValues?.heightUnit === 'ft'
          ? defaultHeightValues.inches
          : 0,
      spo2: 0,
      bmi: '',
      urineAnalysis: '',
      urineOutput: 0,
      fetalHeartRate: 0,
    } satisfies Omit<CreateVitalsDTO, 'patientID'>,
    onSubmit: async ({ value }) => {
      await saveVitals(value);
    },
  });

  const formIsDirty = useStore(VitalsForm.store, (state) => {
    return state.isDirty;
  });

  useEffect(() => {
    if (formIsDirty) {
      handleUnsaved(formIsDirty);
    }
    if (enforceSaveMissing && VitalsForm.state.isDirty) {
      void VitalsForm.handleSubmit();
    }
  }, [formIsDirty, enforceSaveMissing, VitalsForm, handleUnsaved]);

  const currentHeightUnit = useStore(
    VitalsForm.store,
    (state) => state.values.heightUnit
  );

  const canSubmitForm = useStore(VitalsForm.store, (state) => {
    return (
      state.values.systolic !== 0 ||
      state.values.diastolic !== 0 ||
      state.values.heartRate !== 0 ||
      state.values.temperature !== '' ||
      state.values.bloodGlucose !== 0 ||
      state.values.weight !== 0 ||
      state.values.height !== 0 ||
      state.values.feet !== 0 ||
      state.values.spo2 !== 0 ||
      state.values.bmi !== ''
    );
  });

  const handleWeightUnitChange = (value: 'lbs' | 'kgs') => {
    if (value === 'lbs' && VitalsForm.state.values.weightUnit === 'kgs') {
      VitalsForm.setFieldValue(
        'weight',
        Math.round(VitalsForm.state.values.weight * 2.20462)
      );
    }
    if (value === 'kgs' && VitalsForm.state.values.weightUnit === 'lbs') {
      VitalsForm.setFieldValue(
        'weight',
        Math.round(VitalsForm.state.values.weight / 2.20462)
      );
    }
  };

  const updateHeight = (value: 'ft' | 'cm') => {
    if (value === 'ft' && VitalsForm.state.values.heightUnit === 'cm') {
      const heightInInches = VitalsForm.state.values.height / 2.54;
      VitalsForm.setFieldValue('feet', Math.floor(heightInInches / 12));
      VitalsForm.setFieldValue('inches', Math.round(heightInInches % 12));
    }
    if (value === 'cm' && VitalsForm.state.values.heightUnit === 'ft') {
      const heightInCm =
        (VitalsForm.state.values.feet * 12 + VitalsForm.state.values.inches) *
        2.54;
      VitalsForm.setFieldValue('height', Math.round(heightInCm));
    }
  };

  // Calculate BMI on height and weight change
  useStore(VitalsForm.store, (state) => {
    const heightInMeters =
      state.values.heightUnit === 'ft'
        ? (state.values.feet * 12 + state.values.inches) / 39.37
        : state.values.height / 100;
    const weightInKgs =
      state.values.weightUnit === 'lbs'
        ? state.values.weight / 2.20462
        : state.values.weight;
    if (heightInMeters && weightInKgs) {
      const bmi = weightInKgs / Math.pow(heightInMeters, 2);
      state.values.bmi = bmi.toFixed(2);
    }
  });

  return (
    <>
      <CollapsibleComponent label="Vitals" containerClass="relative">
        <div className="grid w-full h-full rounded-b-lg bg-grayCard self-stretch p-2.5">
          {!disabled && (
            <form
              className="flex flex-col gap-2"
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                void VitalsForm.handleSubmit();
              }}
            >
              <div className="flex items-center gap-2">
                <VitalsForm.Field
                  name="systolic"
                  children={(field) => (
                    <BasicTextfield
                      disabled={patientInfo?.status === 'archived'}
                      id="systolic"
                      name={field.name}
                      variant="filled"
                      label="Systolic"
                      value={field.state.value === 0 ? '' : field.state.value}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const numberValue = Number(event.target.value);
                        if (
                          numberValue > -1 &&
                          numberValue < 1000 &&
                          Number.isInteger(numberValue)
                        ) {
                          field.handleChange(numberValue);
                        }
                      }}
                      inputProps={{
                        maxLength: 3,
                      }}
                      type={'number'}
                    />
                  )}
                />
                {'/'}
                <VitalsForm.Field
                  name="diastolic"
                  children={(field) => (
                    <BasicTextfield
                      disabled={patientInfo?.status === 'archived'}
                      id="diastolic"
                      name={field.name}
                      variant="filled"
                      label="Diastolic"
                      value={field.state.value === 0 ? '' : field.state.value}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const numberValue = Number(event.target.value);
                        if (
                          numberValue > -1 &&
                          numberValue < 1000 &&
                          Number.isInteger(numberValue)
                        ) {
                          field.handleChange(numberValue);
                        }
                      }}
                      inputProps={{
                        maxLength: 3,
                      }}
                      type={'number'}
                    />
                  )}
                />
              </div>
              <VitalsForm.Field
                name="heartRate"
                children={(field) => (
                  <BasicTextfield
                    disabled={patientInfo?.status === 'archived'}
                    id="heartRate"
                    name={field.name}
                    variant="filled"
                    label="HR (bpm)"
                    value={field.state.value === 0 ? '' : field.state.value}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const numberValue = Number(event.target.value);
                      if (
                        Number.isInteger(numberValue) &&
                        numberValue > -1 &&
                        numberValue < 1000
                      ) {
                        field.handleChange(Number(event.target.value));
                      }
                    }}
                    type={'number'}
                    inputProps={{
                      step: 1,
                    }}
                  />
                )}
              />
              <div className="flex flex-row flex-1 gap-2">
                <VitalsForm.Field
                  name="temperature"
                  children={(field) => (
                    <BasicTextfield
                      disabled={patientInfo?.status === 'archived'}
                      name={field.name}
                      containerClasses={'w-4/6 h-full'}
                      classes={'h-full'}
                      id="temperature"
                      variant="filled"
                      label="Temp"
                      value={field.state.value}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const numberValue = Number(event.target.value);
                        if (numberValue > -1 && numberValue < 1000) {
                          field.handleChange(event.target.value);
                        }
                      }}
                      type={'number'}
                      inputProps={{
                        maxLength: 4,
                      }}
                    />
                  )}
                />
                <VitalsForm.Field
                  name="temperatureUnit"
                  children={(field) => (
                    <SelectDropdown
                      disabled={patientInfo?.status === 'archived'}
                      id="temperatureUnit"
                      name={field.name}
                      classes={'w-2/6'}
                      handleChange={(
                        e: React.ChangeEvent<HTMLSelectElement>
                      ) => {
                        field.handleChange(e.target.value as 'F' | 'C');
                      }}
                      options={tempUnitOptions}
                      value={field.state.value}
                    />
                  )}
                />
              </div>
              <VitalsForm.Field
                name="bloodGlucose"
                children={(field) => (
                  <BasicTextfield
                    disabled={patientInfo?.status === 'archived'}
                    id="bloodGlucose"
                    name={field.name}
                    variant="filled"
                    label="BG (mg/dL)"
                    value={field.state.value === 0 ? '' : field.state.value}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const numberValue = Number(event.target.value);
                      if (numberValue > -1 && numberValue < 1000) {
                        field.handleChange(numberValue);
                      }
                    }}
                    type={'number'}
                  />
                )}
              />
              <VitalsForm.Field
                name="spo2"
                children={(field) => (
                  <BasicTextfield
                    disabled={patientInfo?.status === 'archived'}
                    id="spo2"
                    variant="filled"
                    label="SpO2 (%)"
                    value={field.state.value === 0 ? '' : field.state.value}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const numberValue = Number(event.target.value);
                      if (numberValue > -1 && numberValue < 101) {
                        field.handleChange(Number(event.target.value));
                      }
                    }}
                    type={'number'}
                  />
                )}
              />
              <div className="flex flex-row flex-1 gap-2">
                <VitalsForm.Field
                  name="weight"
                  children={(field) => (
                    <BasicTextfield
                      disabled={patientInfo?.status === 'archived'}
                      id="weight"
                      name={field.name}
                      containerClasses={'w-4/6 h-full'}
                      classes={'h-full'}
                      variant="filled"
                      label="Weight"
                      value={field.state.value === 0 ? '' : field.state.value}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const numberValue = Number(event.target.value);
                        if (numberValue > -1 && numberValue < 1000) {
                          field.handleChange(numberValue);
                        }
                      }}
                      type={'number'}
                    />
                  )}
                />
                <VitalsForm.Field
                  name="weightUnit"
                  children={(field) => (
                    <SelectDropdown
                      disabled={patientInfo?.status === 'archived'}
                      id="weightUnit"
                      name={field.name}
                      classes={'w-2/6'}
                      handleChange={(
                        e: React.ChangeEvent<HTMLSelectElement>
                      ) => {
                        const value = e.target.value as 'lbs' | 'kgs';
                        handleWeightUnitChange(value);
                        field.handleChange(value);
                      }}
                      options={weightUnitOptions}
                      value={field.state.value}
                    />
                  )}
                />
              </div>
              <div className="flex flex-row flex-1 gap-2">
                <div
                  className={`${
                    currentHeightUnit === 'ft' ? 'flex' : 'hidden'
                  } w-4/6 gap-2`}
                >
                  <VitalsForm.Field
                    name="feet"
                    children={(field) => (
                      <BasicTextfield
                        disabled={patientInfo?.status === 'archived'}
                        id="feet"
                        name={field.name}
                        classes={'h-full'}
                        containerClasses={'w-full h-full'}
                        variant="filled"
                        label="Feet"
                        value={field.state.value === 0 ? '' : field.state.value}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          if (
                            Number(event.target.value) > -1 &&
                            Number(event.target.value) < 10
                          ) {
                            field.handleChange(Number(event.target.value));
                          }
                        }}
                        type={'number'}
                      />
                    )}
                  />
                  <VitalsForm.Field
                    name="inches"
                    children={(field) => (
                      <BasicTextfield
                        disabled={patientInfo?.status === 'archived'}
                        id="inches"
                        classes={'h-full'}
                        name={field.name}
                        containerClasses={'w-full h-full'}
                        variant="filled"
                        label="Inches"
                        value={field.state.value}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const numberValue = Number(event.target.value);
                          if (numberValue > -1 && numberValue < 12) {
                            field.handleChange(numberValue);
                          }
                        }}
                        type={'text'}
                        inputMode="numeric"
                      />
                    )}
                  />
                </div>

                <VitalsForm.Field
                  name="height"
                  children={(field) => (
                    <BasicTextfield
                      disabled={patientInfo?.status === 'archived'}
                      id="height"
                      name={field.name}
                      containerClasses={`${
                        currentHeightUnit === 'cm' ? 'flex' : 'hidden'
                      } w-4/6`}
                      variant="filled"
                      label="Height (cm)"
                      value={field.state.value === 0 ? '' : field.state.value}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const numberValue = Number(event.target.value);
                        if (numberValue > -1 && numberValue < 1000) {
                          field.handleChange(numberValue);
                        }
                      }}
                      type={'number'}
                    />
                  )}
                />
                <VitalsForm.Field
                  name="heightUnit"
                  children={(field) => (
                    <SelectDropdown
                      disabled={patientInfo?.status === 'archived'}
                      id="heightUnit"
                      name={field.name}
                      classes={'w-2/6'}
                      handleChange={(
                        e: React.ChangeEvent<HTMLSelectElement>
                      ) => {
                        const value = e.target.value as 'ft' | 'cm';
                        updateHeight(value);
                        field.handleChange(value);
                      }}
                      options={heightUnitOptions}
                      value={field.state.value}
                    />
                  )}
                />
              </div>
              <VitalsForm.Field
                name="bmi"
                children={(field) => (
                  <BasicTextfield
                    id="bmi"
                    disabled
                    name={field.name}
                    variant="filled"
                    label="BMI (enter height & weight)"
                    value={field.state.value}
                    type={'number'}
                    inputProps={{
                      sx: {
                        WebkitTextFillColor: `${colors.asterGray} !important`,
                      },
                    }}
                  />
                )}
              />
              <VitalsForm.Subscribe
                selector={(state) => [state.isSubmitting]}
                children={([isSubmitting]) => (
                  <div className="flex w-full justify-center">
                    <ButtonType
                      loading={isSubmitting || addVitalsMutation.isPending}
                      text="Add Vitals"
                      variant="contained"
                      type="submit"
                      disabled={
                        patientInfo?.status === 'archived' ||
                        !canSubmitForm ||
                        addVitalsMutation.isPending
                      }
                      classes={'w-full max-h-[30px] mx-1 mt-2'}
                    />
                  </div>
                )}
              ></VitalsForm.Subscribe>
            </form>
          )}

          <StyledDataGrid
            rows={vitalsRows || []}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
            onCellClick={() => setOpen(true)}
            autoHeight
            slots={{
              noRowsOverlay: () => (
                <div className="flex justify-center items-center h-full">
                  No vitals yet.
                </div>
              ),
            }}
            sx={{
              backgroundColor: colors.white,
              overflow: 'auto',
              width: '100%',
              fontSize: '13px',
              textAlign: 'center',
              marginTop: 1,
            }}
          />
        </div>
      </CollapsibleComponent>

      <Dialog open={open} keepMounted onClose={handleClose} maxWidth="lg">
        <div className="flex flex-col items-center">
          <DialogTitle sx={{ m: 0, p: 2, height: 10, fontWeight: 600 }}>
            Vitals
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent className="flex flex-col items-center w-full">
            {isLoading ? (
              <div className="flex flex-col justify-center items-center h-full w-full">
                <CircularProgress />
              </div>
            ) : (
              <StyledDataGrid
                rows={vitalsRows || []}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
                autoHeight
                slots={{
                  noRowsOverlay: () => (
                    <div className="flex justify-center items-center h-full">
                      No vitals yet.
                    </div>
                  ),
                }}
                sx={{
                  backgroundColor: colors.white,
                  width: '100%',
                  overflow: 'auto',
                  fontSize: '13px',
                  textAlign: 'center',
                  marginTop: 1,
                }}
              />
            )}
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

export default Vitals;
