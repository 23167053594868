import { MessageThreadCard } from './MessageThreadCard';
import {
  ToggleGroup,
  ToggleGroupItem,
} from '@aster/client/ui/ToggleGroup/ToggleGroup';
import { PaginatedResult } from '@aster/app/core/shared/dtos/search';
import { MessageThreadListItemDTO } from '@aster/app/message/shared/dtos';
import { useMessagesStore } from '../store/message.store';

type MessageThreadSearchResults = {
  paginatedMessageThreads?: PaginatedResult<MessageThreadListItemDTO>;
};

export const MessageThreadSearchResults = ({
  paginatedMessageThreads,
}: MessageThreadSearchResults) => {
  const selectedMessageThreadID = useMessagesStore(
    (state) => state.selectedMessageThreadID
  );
  const selectMessageThreadID = useMessagesStore(
    (state) => state.selectMessageThreadID
  );

  if (!paginatedMessageThreads) {
    return null;
  }
  return (
    <ToggleGroup
      onValueChange={(value) => selectMessageThreadID(value)}
      value={selectedMessageThreadID ?? undefined}
      type="single"
      variant="wrapper"
      size="container"
      className="flex flex-col overflow-auto pr-2 pb-4"
    >
      {paginatedMessageThreads?.items?.map((messageThread) => (
        <ToggleGroupItem
          key={messageThread.id}
          className="w-full"
          value={messageThread.id}
        >
          <MessageThreadCard thread={messageThread} />
        </ToggleGroupItem>
      ))}
    </ToggleGroup>
  );
};
