import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../../../app/axiosConfig';
import { useSnackbar } from '../../../components/Snack';

export const unarchivePatient = async (patientID: string) => {
  const response = await axios.put(`/v2/patients/${patientID}/unarchive`);
  return response.data;
};

export function useUnarchivePatientMutation(onSuccess?: () => void) {
  const { showMessage } = useSnackbar();
  const queryClient = useQueryClient();

  const unarchivePatientMutation = useMutation({
    mutationKey: ['unarchivePatient'],
    mutationFn: unarchivePatient,
    onError: () => {
      showMessage({
        type: 'error',
        message: 'An error occurred while unarchiving the patient',
      });
    },
    onSuccess: () => {
      showMessage({
        type: 'success',
        message: `The patient was successfully unarchived`,
      });
      void queryClient.invalidateQueries({ queryKey: ['patients'] });
      onSuccess?.();
    },
  });

  return { unarchivePatientMutation };
}
