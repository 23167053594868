import { MessageStatusDTO } from '@aster/app/message/shared/dtos';
import useSearchQueryArgs from '../../../hooks/useSearchQueryArgs';
import { useSearchMessageThreadQuery } from '../queries/use-search-message-threads.query';
import { useMessageThreadFilters } from './use-message-thread-filters';

export function useMessageThreadSearch(
  messageThreadStatus: MessageStatusDTO,
  useStoredPreferences = false
) {
  const { filterState, updateFilter, serverFilters } = useMessageThreadFilters(
    { messageThreadStatus },
    useStoredPreferences
  );

  const {
    paginationModel,
    setPaginationModel,
    searchQuery,
    search,
    sortModel,
  } = useSearchQueryArgs('messageThread', {}, useStoredPreferences);

  const {
    areMessageThreadsFetching,
    paginatedMessageThreads,
    areMessageThreadsLoading,
    currentSearchQueryKey,
  } = useSearchMessageThreadQuery({
    search: searchQuery,
    page: paginationModel.page,
    pageSize: paginationModel.pageSize,
    statusFilter: serverFilters.statusFilter,
    sortDir: sortModel?.sortDir,
  });

  const patchedUpdateFilter: typeof updateFilter = (category, value) => {
    updateFilter(category, value);
    setPaginationModel({ page: 0 });
  };

  return {
    areMessageThreadsFetching,
    paginatedMessageThreads,
    areMessageThreadsLoading,
    paginationModel,
    search,
    searchQuery,
    filterState,
    currentSearchQueryKey,
    updateFilter: patchedUpdateFilter,
    setPaginationModel,
  };
}
