import { LabOrderDetailsDialog } from './order-details/LabOrderDetails';
import { CreateLabsOrderDialog } from './create-order/CreateLabsOrder';
import { LabOrderList } from './order-list/LabOrderList';
import { usePatientInfoQuery } from '../patients/queries/patient-info.query';
import { useParams } from 'react-router';
import { useLabsStore } from './store/labs.store';
import { ManuallyAddLabResultsDialog } from './manually-add-results/ManuallyAddLabResults';
import { isSubscriptionStarterPlan } from '../../integrations/stripe/util/is-subscription-starter-plan';
import { useAuth } from '../../authentication/AuthProvider';
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
} from '@aster/client/ui/Tooltip/Tooltip';
import { Button } from '@aster/client/ui/Button/Button';
import { UpgradeRequiredTooltipContent } from '../../integrations/stripe/components/UpgradeRequiredTooltipContent';

type EncounterLabsProps = {
  patientID?: string;
  isEncounterSigned: boolean;
};

const EncounterLabs = ({
  isEncounterSigned,
  patientID,
}: EncounterLabsProps) => {
  const selectedLabOrderID = useLabsStore((state) => state.selectedLabOrderID);
  const closeLabOrderDetails = useLabsStore(
    (state) => state.closeLabOrderDetails
  );
  const openCreateOrderDialog = useLabsStore(
    (state) => state.openCreateOrderDialog
  );
  const closeCreateOrderDialog = useLabsStore(
    (state) => state.closeCreateOrderDialog
  );
  const isCreateOrderDialogOpened = useLabsStore(
    (state) => state.isCreateOrderDialogOpened
  );
  const manualUploadResultsDialogState = useLabsStore(
    (state) => state.manualUploadResultsDialogState
  );
  const closeManualUploadResultsDialog = useLabsStore(
    (state) => state.closeManualUploadResultsDialog
  );

  const { patient } = useParams();

  const { patientInfo } = usePatientInfoQuery(patient);

  const profile = useAuth().profile;

  const isStarterPlan = isSubscriptionStarterPlan(profile?.stripeProductID);

  return (
    <section className="flex flex-col gap-4">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <Button
              disabled={
                isEncounterSigned ||
                patientInfo?.status === 'archived' ||
                isStarterPlan
              }
              className="w-full mt-4"
              variant={'default'}
              onClick={openCreateOrderDialog}
            >
              Order Labs
            </Button>
          </TooltipTrigger>
          {isStarterPlan && <UpgradeRequiredTooltipContent />}
        </Tooltip>
      </TooltipProvider>
      <LabOrderList />
      {Boolean(selectedLabOrderID) && (
        <LabOrderDetailsDialog
          fullWidth
          open={Boolean(selectedLabOrderID)}
          onClose={() => closeLabOrderDetails()}
          handleClose={() => closeLabOrderDetails()}
          labOrderID={selectedLabOrderID ?? undefined}
          patientID={patientID}
        />
      )}

      <CreateLabsOrderDialog
        fullWidth
        open={isCreateOrderDialogOpened}
        onClose={closeCreateOrderDialog}
        handleClose={closeCreateOrderDialog}
      />
      {manualUploadResultsDialogState !== 'closed' && (
        <ManuallyAddLabResultsDialog
          fullWidth
          open={true}
          handleClose={closeManualUploadResultsDialog}
        />
      )}
    </section>
  );
};

export default EncounterLabs;
