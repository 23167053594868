import { cn } from '@aster/client/utils/cn';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
} from '@aster/client/ui/Tooltip/Tooltip';
import { Typography } from '@aster/client/ui/Typography/Typography';

type TagProps = {
  tagText: string;
  rightText?: string;
  color?: string;
  tooltip?: string;
  className?: string;
  tooltipClassName?: string;
  tagTextClassName?: string;
  rightTextClassName?: string;
  textColor?: string;
};
const Tag = ({
  tagText,
  rightText,
  color,
  tooltip,
  className,
  tooltipClassName,
  tagTextClassName,
  rightTextClassName,
  textColor,
}: TagProps) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <div
            className={cn(
              `flex items-center w-fit px-[5px] h-5 bg-${
                color ?? 'grayBackground'
              } rounded-[5px]`,
              rightText &&
                `border border-${
                  color ?? 'grayBackground'
                } bg-transparent pl-0`,
              className
            )}
          >
            <Typography
              variant="p-xs"
              className={cn(
                'text-center font-semibold rounded-s-[5px] text-primary',
                rightText && `bg-${color ?? 'grayBackground'} px-[5px]`,
                textColor && `text-${textColor}`,
                tagTextClassName
              )}
            >
              {tagText}
            </Typography>

            {rightText && (
              <Typography
                variant="p-xs"
                className={cn('mx-1', rightTextClassName)}
              >
                {rightText}
              </Typography>
            )}
          </div>
        </TooltipTrigger>
        <TooltipContent side="bottom" className={tooltipClassName}>
          {tooltip}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default Tag;
