import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../../../app/axiosConfig';
import {
  createMutationScopeID,
  MutationScope,
} from '../../../mutations/utils/create-mutation-scope-id';

export function useUpdatePatientByTokenMutation(
  token?: string,
  onSuccessCb?: (() => void) | null,
  onErrorCb?: (() => void) | null
) {
  const queryClient = useQueryClient();

  const updatePatientByToken = async (newForm: any) =>
    axios.put(`/v2/i/patients/by-token/${token}`, newForm);

  const updatePatientMutation = useMutation({
    mutationKey: ['updatePatient'],
    mutationFn: updatePatientByToken,
    scope: {
      id: createMutationScopeID(MutationScope.PATIENT),
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ['guest-patient-info', token],
      });
      onSuccessCb && onSuccessCb();
    },
    onError: () => {
      onErrorCb && onErrorCb();
    },
  });

  return { updatePatientMutation };
}
