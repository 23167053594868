import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@aster/client/ui/Card/Card';
import { Typography } from '@aster/client/ui/Typography/Typography';
import { MessageThreadListItemDTO } from '@aster/app/message/shared/dtos';
import dayjs from 'dayjs';
import { cn } from '@aster/client/utils/cn';
import { sanitizeHTML } from '@aster/shared/shared/client/utils';
import { useMessagesStore } from '../store/message.store';

function formatDate(date: string | null) {
  const dateToFormat = dayjs(date);

  if (!date || !dateToFormat.isValid()) {
    return 'Invalid date';
  }

  const now = dayjs();

  if (dateToFormat.isSame(now, 'day')) {
    return dateToFormat.format('h:mm A');
  } else if (dateToFormat.isSame(now, 'year')) {
    return dateToFormat.format('MMM D');
  } else {
    return dateToFormat.format('MMM D, YYYY');
  }
}

export type MessageThreadCardProps = {
  thread: MessageThreadListItemDTO;
  className?: string;
};

export const MessageThreadCard = ({
  thread,
  className,
}: MessageThreadCardProps) => {
  const selectedMessageThreadID = useMessagesStore(
    (state) => state.selectedMessageThreadID
  );
  const isSelected = thread.id === selectedMessageThreadID;
  return (
    <Card
      className={cn(
        'bg-gray-50 border-gray-200 text-start text-base',
        { 'bg-white shadow-md': isSelected },
        className
      )}
    >
      <CardHeader>
        <CardTitle className="flex items-center justify-between gap-4">
          <Typography
            variant="p"
            className="font-semibold text-gray-900 overflow-hidden text-ellipsis whitespace-nowrap"
          >
            {thread.patientName}
          </Typography>
          <Typography variant="p-sm" className="font-normal text-gray-900">
            {formatDate(thread.lastMessageAt)}
          </Typography>
        </CardTitle>
        <CardDescription>
          <Typography variant="p-sm" className="font-semibold text-gray-700">
            {thread.subject}
          </Typography>
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div
          dangerouslySetInnerHTML={sanitizeHTML(
            thread.lastMessageContent ?? ''
          )}
          className="text-sm text-gray-500 text-ellipsis overflow-hidden line-clamp-3"
        />
      </CardContent>
    </Card>
  );
};
