import { FormControl } from '@mui/material';
import { z } from 'zod';
import { useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import Typography from '../../components/Typography';
import CircleInfo from '../../assets/icons/CircleInfo';
import DoseSpot from '../../assets/dosespot.webp';
import { useDosespot } from './DosespotProvider';
import {
  ContactInfo,
  DosespotPatient,
  PersonalInfo,
} from '../patients/types/patient-profile';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { formatPhone } from './util/formatPhone';
import useAddDosespotPatientForm from './hooks/useAddDosespotPatientForm';
import { usePatientVitals } from '../notes/sections/queries/fetch-vitals.query';
import { FormError } from '@aster/client/ui/FormControl/FormError';
import { isPatientReadonly } from '../patients/utils/is-patient-readonly';
import { Label } from '@aster/client/ui/Label/Label';
import { Input } from '@aster/client/ui/Input/Input';
import { GoogleAutoComplete } from '../patients/forms/GoogleAutoComplete';
import { getAddressFieldsFromGoogle } from '../patients/utils/get-address-fields-from-google';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@aster/client/ui/Select/Select';
import { InputMask } from '@react-input/mask';
import { Button } from '@aster/client/ui/Button/Button';
import { useUpdatePatientMutation } from '../patients/mutations/update-patient.mutation';
import {
  LegalSex,
  PatientInfoDTO,
  PatientListItemDTO,
} from '@aster/app/core/shared/dtos/patient';
import { useParams } from 'react-router';
import { useStore } from '@tanstack/react-form';

type AddPatientFormProps = {
  patientInfo: PatientInfoDTO;
  refetchPatient: () => void;
};

const AddDosespotPatient = ({
  patientInfo,
  refetchPatient,
}: AddPatientFormProps) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const dosespotContext = useDosespot();

  const { patient: patientID } = useParams<{ patient: string }>();

  const { vitals } = usePatientVitals(patientID as string);

  const { updatePatientMutation } = useUpdatePatientMutation(
    patientID as string
  );

  const readonly = isPatientReadonly(patientInfo);

  const updateContactAddress = (
    place: google.maps.places.PlaceResult,
    ref: React.RefObject<HTMLInputElement>
  ) => {
    const addressFields = getAddressFieldsFromGoogle(place);
    if (!addressFields) return;
    const { streetAddress1, city, state, zipCode } = addressFields;
    addDosespotPatientForm.state.values.streetAddress1 = streetAddress1;
    addDosespotPatientForm.state.values.city = city;
    addDosespotPatientForm.state.values.state = state;
    addDosespotPatientForm.state.values.zipCode = zipCode;
  };

  const streetAddrees1Ref = useRef<HTMLInputElement>(null);

  const defaultHeightValues = useMemo(() => {
    const existingHeight = vitals?.find((vital) => vital.heightUnit !== null);
    if (existingHeight && existingHeight.heightUnit) {
      return {
        heightUnit: existingHeight.heightUnit,
        feet: existingHeight.feet ?? null,
        inches: existingHeight.inches ?? null,
        height: existingHeight.height ?? null,
      };
    }
    return undefined;
  }, [vitals]);

  const defaultWeightValues = useMemo(() => {
    const existingWeight = vitals?.find((vital) => vital.weightUnit !== null);
    if (existingWeight && existingWeight.weightUnit) {
      return {
        weightUnit: existingWeight.weightUnit,
        weight: existingWeight.weight ?? null,
      };
    }
    return undefined;
  }, [vitals]);

  const addDosespotPatientForm = useAddDosespotPatientForm({
    defaultValues: {
      firstName: patientInfo.firstName as PersonalInfo['firstName'],
      lastName: patientInfo.lastName as PersonalInfo['lastName'],
      dateOfBirth: patientInfo.dateOfBirth
        ? dayjs(patientInfo.dateOfBirth)
        : (null as dayjs.Dayjs | null as PersonalInfo['dateOfBirth']),
      streetAddress1:
        patientInfo.streetAddress1 as ContactInfo['streetAddress1'],
      city: patientInfo.city as ContactInfo['city'],
      state: patientInfo.state as ContactInfo['state'],
      zipCode: patientInfo.zipCode as ContactInfo['zipCode'],
      phoneNumber: patientInfo.phoneNumber as ContactInfo['phoneNumber'],
      legalSex: patientInfo.legalSex as PatientListItemDTO['legalSex'],
      weight: defaultWeightValues?.weight ?? 0,
      weightUnit:
        defaultWeightValues?.weightUnit ?? ('lbs' as 'lbs' | 'kgs' | null),
      height:
        defaultHeightValues?.heightUnit === 'cm' && defaultHeightValues?.height
          ? defaultHeightValues.height
          : 0,
      heightUnit:
        (defaultHeightValues && defaultHeightValues?.heightUnit) ??
        ('ft' as 'ft' | 'cm'),
      feet:
        defaultHeightValues?.feet && defaultHeightValues?.heightUnit === 'ft'
          ? defaultHeightValues.feet
          : 0,
      inches:
        defaultHeightValues?.inches && defaultHeightValues?.heightUnit === 'ft'
          ? defaultHeightValues.inches
          : 0,
    },
    onSubmit: async (values) => {
      setErrorMessage(null);

      await updatePatientMutation.mutateAsync({
        ...values,
        dateOfBirth: values.dateOfBirth
          ? dayjs(values.dateOfBirth).toISOString()
          : null,
      });

      const { client_id, client_secret, username, password } =
        dosespotContext.dosespotData;

      if (!client_id || !client_secret) {
        setErrorMessage('Could not find dosespot clinic');
        return;
      }

      if (!username || !password) {
        setErrorMessage('Could not find dosespot clinician');
        return;
      }
      await createDosespotPatient(values).then((res) => {
        if (res && res.data.Result.ResultCode === 'ERROR') {
          const errors = res.data.Result.ResultDescription;
          setErrorMessage(errors);
          return;
        }
        refetchPatient();
      });
      return;
    },
  });

  const createDosespotPatient = async (values: Partial<DosespotPatient>) => {
    const getHeight = () => {
      if (
        values.heightUnit === 'ft' &&
        values.feet &&
        values.inches !== undefined
      ) {
        return values.feet * 12 + values.inches;
      }
      return values.height;
    };

    const getWeightUnit = () => {
      if (values.weightUnit === 'lbs') {
        return 'Lb';
      }
      if (values.weightUnit === 'kgs') {
        return 'Kg';
      }
      return undefined;
    };

    const getHeightUnit = () => {
      if (values.heightUnit === 'ft') {
        return 'inch';
      }
      if (values.heightUnit === 'cm') {
        return 'Cm';
      }
      return undefined;
    };

    const dosespotPatient = await dosespotContext.createDosespotPatient({
      patientId: patientID as string,
      // values capitalized for dosespot
      FirstName: values.firstName ?? '',
      LastName: values.lastName ?? '',
      DateOfBirth: values.dateOfBirth
        ? dayjs(values.dateOfBirth).format('YYYY-MM-DD')
        : '',
      Address1: values.streetAddress1 ?? '',
      Gender: values.legalSex ?? '',
      City: values.city ?? '',
      State: values.state ?? '',
      ZipCode: values.zipCode ?? '',
      PrimaryPhone: formatPhone(values.phoneNumber ?? ''),
      PrimaryPhoneType: '1',
      Active: 'true',
      WeightMetric: getWeightUnit(),
      Weight: values.weight ?? 0,
      HeightMetric: getHeightUnit(),
      Height: getHeight(),
    });

    return dosespotPatient;
  };

  const currentHeightUnit = useStore(
    addDosespotPatientForm.store,
    (state) => state.values.heightUnit
  );
  const weightUnit = useStore(
    addDosespotPatientForm.store,
    (state) => state.values.weightUnit
  );

  return (
    <div className="flex flex-col items-center w-full justify-center rounded-[20px] bg-white px-[35px] mt-4 mb-10 relative">
      <img
        src={DoseSpot}
        className="top-0 right-10 absolute max-w-[110px]"
        alt="Dosespot"
      />

      <Typography variant="h3" text="Add patient to Dosespot" />
      <Typography
        variant={'body'}
        text="In order to add a patient to DoseSpot, information must be up to date. "
        customClass="text-asterGray text-center mt-5"
      />

      <form
        className="max-w-[500px]"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void addDosespotPatientForm.handleSubmit();
        }}
      >
        <div className="flex items-center gap-4 bg-pastelOrange p-3 mt-8 rounded-2xl bg-opacity-[25%]">
          <CircleInfo className="flex h-[24px] w-[24px]" />
          <Typography
            customClass="text-asterGray leading-1"
            variant="bodyMedium"
            text="Please review the missing fields and update the information in the patient profile."
          />
        </div>
        <FormControl fullWidth className="bg-gray-100 rounded-[10px] p-4 mt-4">
          {errorMessage && (
            <Typography
              variant="bodySmall"
              text={errorMessage}
              customClass="text-red-500"
            />
          )}
          <div className="grid grid-cols-2 gap-4 w-full my-5">
            <addDosespotPatientForm.Field
              name="firstName"
              validators={{
                onBlur: z.string().min(1, {
                  message: 'Please enter a first name',
                }),
              }}
              children={(field) => (
                <FormControl>
                  <Label>First Name</Label>
                  <Input
                    className="bg-white"
                    id="firstName"
                    name="firstName"
                    value={field.state.value}
                    onChange={(evt) => {
                      field.handleChange(evt.target.value);
                    }}
                    onBlur={field.handleBlur}
                    disabled={readonly}
                    errorMessageId={`err-${field.name}`}
                  />
                  <FormError id={`err-${field.name}`}>
                    {field.state.meta.errors?.join('\r')}
                  </FormError>
                </FormControl>
              )}
            />
            <addDosespotPatientForm.Field
              name="lastName"
              validators={{
                onBlur: z.string().min(1, {
                  message: 'Please enter a last name',
                }),
              }}
              children={(field) => (
                <FormControl>
                  <Label>Last Name</Label>
                  <Input
                    className="bg-white"
                    id="lastName"
                    name="lastName"
                    value={field.state.value}
                    onChange={(evt) => {
                      field.handleChange(evt.target.value);
                    }}
                    onBlur={field.handleBlur}
                    hasError={field.state.meta.errors.length > 0}
                    disabled={readonly}
                    errorMessageId={`err-${field.name}`}
                  />
                  <FormError id={`err-${field.name}`}>
                    {field.state.meta.errors?.join('\r')}
                  </FormError>
                </FormControl>
              )}
            />
            <addDosespotPatientForm.Field
              name="dateOfBirth"
              validators={{
                onBlur: ({ value }: { value: string }) => {
                  if (!dayjs(value).isValid()) {
                    return 'Invalid date';
                  }
                  return null;
                },
              }}
              children={(field) => (
                <FormControl>
                  <Label>Date of Birth</Label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <InputMask
                      className="bg-white"
                      component={Input}
                      name={field.name}
                      mask="MM/DD/YYYY"
                      separate
                      showMask
                      replacement={{ D: /\d/, M: /\d/, Y: /\d/ }}
                      value={field.state.value as string}
                      onChange={(event) => {
                        const value =
                          event.target.value === 'MM/DD/YYYY'
                            ? ''
                            : event.target.value;
                        field.handleChange(value ?? undefined);
                      }}
                      onBlur={field.handleBlur}
                      disabled={readonly}
                    />
                  </LocalizationProvider>
                  <FormError id={`err-${field.name}`}>
                    {field.state.meta.errors?.join('\r')}
                  </FormError>
                </FormControl>
              )}
            />
            <addDosespotPatientForm.Field
              name="streetAddress1"
              validators={{
                onBlur: z.string().min(1, {
                  message: 'Please enter a valid address',
                }),
              }}
              children={(field) => (
                <FormControl>
                  <Label>Address Line 1</Label>
                  <GoogleAutoComplete
                    inputProps={{
                      className: 'bg-white',
                      value: field.state.value ?? '',
                      onChange: (evt) => field.handleChange(evt.target.value),
                      disabled: readonly,
                      onBlur: field.handleBlur,
                    }}
                    selectedAutocomplete={(place) => {
                      updateContactAddress(place, streetAddrees1Ref);
                      void addDosespotPatientForm.handleSubmit();
                    }}
                    ref={streetAddrees1Ref}
                  />
                  <FormError id={`err-${field.name}`}>
                    {field.state.meta.errors?.join('\r')}
                  </FormError>
                </FormControl>
              )}
            />
            <addDosespotPatientForm.Field
              name="city"
              validators={{
                onBlur: z.string().min(1, { message: 'Please enter a city' }),
              }}
              children={(field) => (
                <FormControl>
                  <Label>City</Label>
                  <Input
                    className="bg-white"
                    id="city"
                    placeholder="Enter city"
                    name="city"
                    value={field.state.value}
                    onChange={(evt) => field.handleChange(evt.target.value)}
                    onBlur={field.handleBlur}
                    disabled={readonly}
                  />
                  <FormError id={`err-${field.name}`}>
                    {field.state.meta.errors?.join('\r')}
                  </FormError>
                </FormControl>
              )}
            />
            <addDosespotPatientForm.Field
              name="state"
              validators={{
                onBlur: z.string().min(1, {
                  message: 'Please select a state',
                }),
              }}
              children={(field) => (
                <FormControl>
                  <Label>State</Label>
                  <Input
                    className="bg-white"
                    id="state"
                    placeholder="Enter state"
                    name="state"
                    value={field.state.value}
                    onChange={(evt) => field.handleChange(evt.target.value)}
                    onBlur={field.handleBlur}
                    disabled={readonly}
                  />
                  <FormError id={`err-${field.name}`}>
                    {field.state.meta.errors?.join('\r')}
                  </FormError>
                </FormControl>
              )}
            />
            <addDosespotPatientForm.Field
              name="zipCode"
              validators={{
                onBlur: z.string().min(1, {
                  message: 'Please enter a valid zip code',
                }),
              }}
              children={(field) => (
                <FormControl>
                  <Label>Zip Code</Label>
                  <Input
                    className="bg-white"
                    id="zipCode"
                    placeholder="Enter zip code"
                    name="zipCode"
                    value={field.state.value}
                    onChange={(evt) => field.handleChange(evt.target.value)}
                    onBlur={field.handleBlur}
                    disabled={readonly}
                  />
                  <FormError id={`err-${field.name}`}>
                    {field.state.meta.errors?.join('\r')}
                  </FormError>
                </FormControl>
              )}
            />
            <addDosespotPatientForm.Field
              name="phoneNumber"
              validators={{
                onBlur: z.string().min(1, {
                  message: 'Please enter a valid phone number',
                }),
              }}
              children={(field) => (
                <FormControl>
                  <Label>Phone</Label>
                  <Input
                    name={field.name}
                    className="bg-white"
                    id="phoneNumber"
                    placeholder="(xxx) xxx-xxxx"
                    value={field.state.value}
                    onChange={(evt) => field.handleChange(evt.target.value)}
                    onBlur={field.handleBlur}
                    disabled={readonly}
                  />
                  <FormError id={`err-${field.name}`}>
                    {field.state.meta.errors?.join('\r')}
                  </FormError>
                </FormControl>
              )}
            />
            <addDosespotPatientForm.Field
              name="legalSex"
              validators={{
                onChange: z.string().min(1, {
                  message: 'Please select a legal sex',
                }),
              }}
              children={(field) => (
                <FormControl className="relative">
                  <Label>Sex Assigned at Birth</Label>
                  <Select
                    name={field.name}
                    value={field.state.value ?? ''}
                    defaultValue={field.state.value as string}
                    onValueChange={(value) => {
                      field.handleChange(value as LegalSex);
                    }}
                    disabled={readonly}
                  >
                    <SelectTrigger className="bg-white">
                      <SelectValue placeholder="Select an option" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value="female">Female</SelectItem>
                        <SelectItem value="male">Male</SelectItem>
                        <SelectItem value="other">Other</SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  <FormError id={`err-${field.name}`}>
                    {field.state.meta.errors?.join('\r')}
                  </FormError>
                </FormControl>
              )}
            />
            <br />
            <div className="flex flex-row flex-1 gap-2 col-span-2">
              <addDosespotPatientForm.Field
                name="heightUnit"
                children={(field) => (
                  <FormControl className="relative">
                    <Label>Height Unit</Label>
                    <Select
                      value={field.state.value ?? 'ft'}
                      defaultValue={field.state.value as string}
                      onValueChange={(value) => {
                        field.handleChange(value as 'ft' | 'cm');
                      }}
                      disabled={readonly}
                    >
                      <SelectTrigger className="bg-white">
                        <SelectValue placeholder="Select an option" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value="ft">ft</SelectItem>
                          <SelectItem value="cm">cm</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    <FormError id={`err-${field.name}`}>
                      {field.state.meta.errors?.join('\r')}
                    </FormError>
                  </FormControl>
                )}
              />
              <div
                className={`${
                  currentHeightUnit === 'ft' ? 'flex' : 'hidden'
                } w-4/6 gap-2`}
              >
                <addDosespotPatientForm.Field
                  name="feet"
                  validators={{
                    onChangeListenTo: ['dateOfBirth', 'heightUnit'],
                    onChange: ({ value, fieldApi }) => {
                      if (
                        fieldApi.form.getFieldValue('heightUnit') === 'ft' &&
                        !value &&
                        fieldApi.form.getFieldValue('dateOfBirth') &&
                        dayjs(
                          fieldApi.form.getFieldValue('dateOfBirth')
                        ).isValid() &&
                        dayjs(
                          fieldApi.form.getFieldValue('dateOfBirth')
                        ).isAfter(dayjs().subtract(18, 'years').toDate())
                      ) {
                        return 'Height required to for patients under 18';
                      }
                      return undefined;
                    },
                  }}
                  children={(field) => (
                    <FormControl>
                      <Label>Feet</Label>
                      <Input
                        type="text"
                        inputMode="numeric"
                        className="bg-white"
                        placeholder="Feet"
                        name={field.name}
                        value={field.state.value === 0 ? '' : field.state.value}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          if (
                            Number(event.target.value) > -1 &&
                            Number(event.target.value) < 10
                          ) {
                            field.handleChange(Number(event.target.value));
                          }
                        }}
                        onBlur={field.handleBlur}
                        disabled={readonly}
                      />
                      <FormError id={`err-${field.name}`}>
                        {field.state.meta.errors?.join('\r')}
                      </FormError>
                    </FormControl>
                  )}
                />
                <addDosespotPatientForm.Field
                  name="inches"
                  children={(field) => (
                    <FormControl>
                      <Label>Inches</Label>
                      <Input
                        type="text"
                        inputMode="numeric"
                        className="bg-white"
                        placeholder="Inches"
                        name={field.name}
                        value={field.state.value}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const numberValue = Number(event.target.value);
                          if (numberValue > -1 && numberValue < 12) {
                            field.handleChange(numberValue);
                          }
                        }}
                        onBlur={field.handleBlur}
                        disabled={readonly}
                      />
                      <FormError id={`err-${field.name}`}>
                        {field.state.meta.errors?.join('\r')}
                      </FormError>
                    </FormControl>
                  )}
                />
              </div>

              <addDosespotPatientForm.Field
                name="height"
                validators={{
                  onChangeListenTo: ['dateOfBirth', 'heightUnit'],
                  onChange: ({ value, fieldApi }) => {
                    if (
                      fieldApi.form.getFieldValue('heightUnit') === 'cm' &&
                      !value &&
                      fieldApi.form.getFieldValue('dateOfBirth') &&
                      dayjs(
                        fieldApi.form.getFieldValue('dateOfBirth')
                      ).isValid() &&
                      dayjs(fieldApi.form.getFieldValue('dateOfBirth')).isAfter(
                        dayjs().subtract(18, 'years').toDate()
                      )
                    ) {
                      return 'Height required for patients under 18';
                    }
                    return undefined;
                  },
                }}
                children={(field) => (
                  <FormControl
                    className={currentHeightUnit === 'cm' ? 'flex' : 'hidden'}
                  >
                    <Label>cm</Label>
                    <Input
                      type="text"
                      inputMode="numeric"
                      className="bg-white"
                      placeholder="cm"
                      name={field.name}
                      value={field.state.value === 0 ? '' : field.state.value}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const numberValue = Number(event.target.value);
                        if (numberValue > -1 && numberValue < 1000) {
                          field.handleChange(numberValue);
                        }
                      }}
                      onBlur={field.handleBlur}
                      disabled={readonly}
                    />
                    <FormError id={`err-${field.name}`}>
                      {field.state.meta.errors?.join('\r')}
                    </FormError>
                  </FormControl>
                )}
              />
            </div>
            <div className="flex flex-row flex-1 col-span-2 gap-2">
              <addDosespotPatientForm.Field
                name="weightUnit"
                children={(field) => (
                  <FormControl className="relative">
                    <Label>Weight Unit</Label>
                    <Select
                      value={field.state.value ?? 'ft'}
                      defaultValue={field.state.value as string}
                      onValueChange={(value) => {
                        field.handleChange(value as 'lbs' | 'kgs');
                      }}
                      disabled={readonly}
                    >
                      <SelectTrigger className="bg-white">
                        <SelectValue placeholder="Select an option" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value="lbs">lbs</SelectItem>
                          <SelectItem value="kgs">kgs</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    <FormError id={`err-${field.name}`}>
                      {field.state.meta.errors?.join('\r')}
                    </FormError>
                  </FormControl>
                )}
              />
              <addDosespotPatientForm.Field
                name="weight"
                validators={{
                  onChangeListenTo: ['dateOfBirth'],
                  onChange: ({ value, fieldApi }) => {
                    if (
                      !value &&
                      fieldApi.form.getFieldValue('dateOfBirth') &&
                      dayjs(
                        fieldApi.form.getFieldValue('dateOfBirth')
                      ).isValid() &&
                      dayjs(fieldApi.form.getFieldValue('dateOfBirth')).isAfter(
                        dayjs().subtract(18, 'years').toDate()
                      )
                    ) {
                      return 'Weight is required for patients under 18';
                    }
                    return undefined;
                  },
                }}
                children={(field) => (
                  <FormControl>
                    <Label>Weight</Label>
                    <Input
                      type="text"
                      inputMode="numeric"
                      className="bg-white"
                      placeholder={weightUnit === 'lbs' ? 'lbs' : 'kgs'}
                      name={field.name}
                      value={field.state.value === 0 ? '' : field.state.value}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const numberValue = Number(event.target.value);
                        if (numberValue > -1 && numberValue < 1000) {
                          field.handleChange(numberValue);
                        }
                      }}
                      onBlur={field.handleBlur}
                      disabled={readonly}
                    />
                    <FormError id={`err-${field.name}`}>
                      {field.state.meta.errors?.join('\r')}
                    </FormError>
                  </FormControl>
                )}
              />
            </div>
          </div>

          <addDosespotPatientForm.Subscribe
            selector={(state) => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting]) => (
              <div className="flex w-full justify-center">
                <Button
                  isLoading={
                    isSubmitting ||
                    dosespotContext.loading ||
                    updatePatientMutation.isPending
                  }
                  variant={'default'}
                  type="submit"
                  disabled={
                    !canSubmit || isSubmitting || dosespotContext.loading
                  }
                  className="mt-1"
                >
                  Update patient profile
                </Button>
              </div>
            )}
          ></addDosespotPatientForm.Subscribe>
        </FormControl>
      </form>
    </div>
  );
};

export default AddDosespotPatient;
