import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@aster/client/ui/Popover/Popover';
import { Button } from '@aster/client/ui/Button/Button';
import { Typography } from '@aster/client/ui/Typography/Typography';
import { MailX, ClipboardList, Check, Ellipsis, User } from 'lucide-react';
import { cn } from '@aster/client/utils';
import { names } from '@aster/shared/utils';
import { useSendIntakeFormMutation } from './mutations/send-intake-form.mutation';
import { usePatientProfileQuery } from '../notes/queries/patient-profile.query';
import { useParams } from 'react-router';
import { useEnablePatientPortalAccessMutation } from './mutations/enable-patient-to-access-portal.mutation';
import { PatientProfileForEncounterDTO } from '@aster/app/core/shared/dtos/patient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faTrashUndo } from '@fortawesome/pro-light-svg-icons';

interface PatientOptionsMenuProps {
  openDeletePatientModal: (value: boolean | undefined) => void;
  openUnarchivePatientModal: (value: boolean | undefined) => void;
}

const PatientOptionsMenu = ({
  openDeletePatientModal,
  openUnarchivePatientModal,
}: PatientOptionsMenuProps) => {
  const { patient } = useParams();
  const { patientProfile } = usePatientProfileQuery(patient as string, {
    offline: true,
  });
  console.log(patientProfile);
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          disabled={!patientProfile}
          className="h-10 w-10 bg-gray-100 text-aster-gray-900 hover:bg-gray-200 data-[state=open]:bg-gray-300 disabled:bg-gray-100/50 disabled:text-gray-400"
        >
          <Ellipsis size={16} />
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end" className="py-6 flex flex-col gap-4">
        {patientProfile?.status !== 'archived' && (
          <>
            <IntakeFormSection
              patientEmail={patientProfile?.email}
              patientID={patientProfile?.patientID as string}
              status={patientProfile?.intakeFormSentStatus ?? 'not-sent'}
            />
            <PatientPortalInvitationSection
              patientID={patientProfile?.patientID as string}
              status={
                patientProfile?.patientPortalInvitationSentStatus ?? 'not-sent'
              }
            />{' '}
          </>
        )}

        {patientProfile?.status === 'active' && (
          <Button
            onClick={() => openDeletePatientModal(true)}
            variant="text"
            size="text"
            className="text-red-600 hover:text-red-800"
          >
            <FontAwesomeIcon icon={faTrash} /> Archive patient
          </Button>
        )}

        {patientProfile?.status === 'archived' && (
          <Button
            onClick={() => openUnarchivePatientModal(true)}
            variant="text"
            size="text"
            className="text-green-600 hover:text-green-800"
          >
            <FontAwesomeIcon icon={faTrashUndo} /> Unarchive patient
          </Button>
        )}
      </PopoverContent>
    </Popover>
  );
};

const IntakeFormSection = ({
  status,
  patientEmail,
  patientID,
}: {
  status: PatientProfileForEncounterDTO['intakeFormSentStatus'];
  patientEmail?: string;
  patientID: string;
}) => {
  const { sendIntakeFormMutation } = useSendIntakeFormMutation({
    patientEmail,
    patientID,
  });
  const isSending = sendIntakeFormMutation.isPending;
  return (
    <section className="flex flex-col gap-1">
      <div className="flex items-center gap-1">
        <ClipboardList size={16} />
        <Typography className="font-medium text-gray-900" variant="p-sm">
          Intake Form
        </Typography>
      </div>
      <div className="flex items-center justify-between">
        <div
          className={cn('flex items-center gap-2 py-2', {
            'text-orange-500': status === 'expired' || status === 'not-sent',
            'text-green-700': status === 'sent',
          })}
        >
          {status === 'not-sent' ? <MailX size={16} /> : <Check size={16} />}
          <Typography
            className="font-medium text-current leading-5"
            variant="p-sm"
          >
            {names(status).sentenceCase}
          </Typography>
        </div>

        <Button
          onClick={() => sendIntakeFormMutation.mutate()}
          isLoading={isSending}
          disabled={isSending}
          size="sm"
          variant="outline"
        >
          {isSending ? 'Sending' : status === 'not-sent' ? 'Send' : 'Resend'}
        </Button>
      </div>
    </section>
  );
};

const PatientPortalInvitationSection = ({
  status,
  patientID,
}: {
  status: PatientProfileForEncounterDTO['patientPortalInvitationSentStatus'];
  patientID: string;
}) => {
  const { enablePatientPortalAccessMutation } =
    useEnablePatientPortalAccessMutation(patientID);
  const isSending = enablePatientPortalAccessMutation.isPending;
  return (
    <section className="flex flex-col gap-1">
      <div className="flex items-center gap-1">
        <User size={16} />
        <Typography className="font-medium text-gray-900" variant="p-sm">
          Patient Portal Invitation
        </Typography>
      </div>
      <div className="flex items-center justify-between">
        <div
          className={cn('flex items-center gap-2 py-2', {
            'text-orange-500': status === 'not-sent',
            'text-green-700': status === 'sent' || status === 'account-created',
          })}
        >
          {status === 'not-sent' ? <MailX size={16} /> : <Check size={16} />}
          <Typography
            className="font-medium text-current leading-5"
            variant="p-sm"
          >
            {status === 'account-created'
              ? 'Patient Created Account'
              : names(status).sentenceCase}
          </Typography>
        </div>

        {status !== 'account-created' && (
          <Button
            onClick={() => enablePatientPortalAccessMutation.mutate()}
            size="sm"
            variant="outline"
            isLoading={isSending}
            disabled={isSending}
          >
            {isSending ? 'Sending' : status === 'not-sent' ? 'Send' : 'Resend'}
          </Button>
        )}
      </div>
    </section>
  );
};

export default PatientOptionsMenu;
