import { PaginatedResult } from '@aster/app/core/shared/dtos/search';
import { MessageThreadListItemDTO } from '@aster/app/message/shared/dtos';
import { CircularSpinner } from '@aster/client/ui/CircularSpinner/CircularSpinner';
import { MessageThreadSearchResults } from '../MesageThreads/MessageThreadSearchResults';
import { MessageThreadComposer } from '../MessageThreadComposer/MessageThreadComposer';
import { MessageThreadDetails } from '../MessageThreadDetails/MessageThreadDetails';
import { useMessagesStore } from '../store/message.store';
import { NoMessages } from './NoMessages';

type MessagesContentProps = {
  paginatedMessageThreads?: PaginatedResult<MessageThreadListItemDTO>;
  areMessageThreadsLoading: boolean;
  currentActiveSearchQueryKey: (string | number)[];
  currentArchivedSearchQueryKey: (string | number)[];
};

export const MessagesContent = ({
  currentActiveSearchQueryKey,
  currentArchivedSearchQueryKey,
  paginatedMessageThreads,
  areMessageThreadsLoading,
}: MessagesContentProps) => {
  const selectedMessageThreadID = useMessagesStore(
    (state) => state.selectedMessageThreadID
  );
  const isNewMessageThreadComposerOpen = useMessagesStore(
    (state) => state.isNewMessageThreadComposerOpen
  );
  if (isNewMessageThreadComposerOpen) {
    return <MessageThreadComposer />;
  }
  if (areMessageThreadsLoading) {
    return (
      <CircularSpinner size="2xl" className="mt-[10%] mx-auto col-span-3" />
    );
  }

  if (paginatedMessageThreads?.items.length === 0) {
    return <NoMessages />;
  }
  return (
    <>
      <section className="flex flex-col col-span-1 overflow-hidden">
        <MessageThreadSearchResults
          paginatedMessageThreads={paginatedMessageThreads}
        />
      </section>
      <div className="flex flex-col col-span-2 h-full overflow-hidden">
        {selectedMessageThreadID && (
          <MessageThreadDetails
            currentActiveSearchQueryKey={currentActiveSearchQueryKey}
            currentArchivedSearchQueryKey={currentArchivedSearchQueryKey}
          />
        )}
      </div>
    </>
  );
};
