import Typography from '../../../../components/Typography';
import dayjs from 'dayjs';
import {
  defaultOtherPregnancyOutcomes,
  defaultPregnancyInfo,
} from '../../defaults';
import { useObstetricHistoryInfoForm } from '../../hooks/useObstetricHistoryInfoForm';
import { Label } from '@aster/client/ui/Label/Label';
import { CircularProgress } from '@mui/material';
import { Button } from '@aster/client/ui/Button/Button';
import { useParams } from 'react-router';
import { isPatientReadonly } from '../../utils/is-patient-readonly';
import { useDebouncedCallback } from 'use-debounce';
import { LAST_SAVED_OBSTETRIC_INFORMATION } from '../../constants';
import { useUserUsagePreferences } from '../../../../hooks/useUserUsagePreference';
import { ProfileSaveButton } from '../../profileTabs/components/ProfileSaveButton';
import { forwardRef, Fragment, useImperativeHandle, useState } from 'react';
import timeSinceLastSaved from '../../utils/timeSinceLastSaved';
import Pregnancy from './Pregnancy';
import { PatientInfoDTO } from '@aster/app/core/shared/dtos/patient';
import { Token } from '@aster/app/core/shared/dtos/token';

const ObstetricInformation = forwardRef(
  (
    {
      updatePatientMutation,
      patientInfo,
      isPatientLoading,
      token,
    }: {
      patientInfo: PatientInfoDTO | undefined;
      updatePatientMutation: any;
      isPatientLoading: boolean;
      token?: Token;
    },
    ref
  ) => {
    const [debounceValue, setDebounceValue] = useState<number>(1000);
    useImperativeHandle(ref, () => ({
      submit: () => {
        setDebounceValue(0);
        void obstetricHistoryInfoForm.handleSubmit();
      },
    }));

    const { storePreference, readPreference } = useUserUsagePreferences();

    const lastSaved = readPreference<string>(LAST_SAVED_OBSTETRIC_INFORMATION);
    const lastSavedString = lastSaved ? timeSinceLastSaved(lastSaved) : '';

    const obstetricHistoryInfoForm = useObstetricHistoryInfoForm({
      defaultValues: patientInfo as PatientInfoDTO,
      onSubmit: async (value) => {
        const pregnancies = value?.map((item) => {
          return {
            ...item,
            pregnancy: {
              ...item.pregnancy,
              dateOfBirthOrEndOfPregnancy: item.pregnancy
                .dateOfBirthOrEndOfPregnancy
                ? dayjs(
                    item.pregnancy.dateOfBirthOrEndOfPregnancy
                  ).toISOString()
                : null,
            },
          };
        });
        await save({
          pregnancies,
        })?.then(() => {
          storePreference(
            LAST_SAVED_OBSTETRIC_INFORMATION,
            dayjs().toISOString()
          );
          setDebounceValue(1000);
        });
      },
    });

    const save = useDebouncedCallback(async (value) => {
      await updatePatientMutation.mutateAsync(value);
    }, debounceValue);

    const readonly = isPatientReadonly(patientInfo);

    const { patient: patientID } = useParams<{ patient: string }>();

    return (
      <>
        <ProfileSaveButton
          lastSavedString={lastSavedString}
          updatePatientMutation={updatePatientMutation}
          form={obstetricHistoryInfoForm}
        />
        <div className="container w-full pb-20">
          {isPatientLoading ? (
            <CircularProgress />
          ) : (
            <form
              onSubmit={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                void obstetricHistoryInfoForm.handleSubmit();
              }}
            >
              <div className="flex h-fit">
                <div className="flex flex-col gap-y-5">
                  <Typography
                    text="Obstetric History"
                    variant="h5"
                    customClass="font-semibold mb-5"
                  ></Typography>
                  {obstetricHistoryInfoForm.state.values.pregnancies.length ===
                    0 && (
                    <Label className="m-0">
                      Have you been pregnant before (not including current
                      pregnancy)?
                    </Label>
                  )}
                  <obstetricHistoryInfoForm.Field
                    name={'pregnancies'}
                    mode="array"
                  >
                    {(field) => {
                      return (
                        <Fragment key={field.state.value.length}>
                          {field.state.value.map((pregnancy, index) => (
                            <Fragment key={index}>
                              <Pregnancy
                                index={index}
                                field={field}
                                fieldValue={pregnancy}
                                obstetricHistoryInfoForm={
                                  obstetricHistoryInfoForm
                                }
                                readonly={readonly}
                              />
                            </Fragment>
                          ))}
                          <div>
                            <Button
                              size="default"
                              type="button"
                              variant="secondary"
                              onClick={() => {
                                const newPregnancy = {
                                  pregnancy: {
                                    ...defaultPregnancyInfo.pregnancy,
                                    dateOfBirthOrEndOfPregnancy: undefined,
                                    patientID: patientID
                                      ? patientID
                                      : token?.metadata
                                      ? (token.metadata.patientID as string)
                                      : '',
                                    otherPregnancyOutcomes:
                                      defaultOtherPregnancyOutcomes,
                                  },
                                  children: defaultPregnancyInfo.children,
                                };
                                field.pushValue(newPregnancy);
                                void obstetricHistoryInfoForm.handleSubmit();
                              }}
                            >
                              + Add Pregnancy
                            </Button>
                          </div>
                        </Fragment>
                      );
                    }}
                  </obstetricHistoryInfoForm.Field>
                </div>
              </div>
            </form>
          )}
        </div>
      </>
    );
  }
);

export default ObstetricInformation;
