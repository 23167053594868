import { useParams } from 'react-router';

import { datadogRum } from '@datadog/browser-rum';
import { createId } from '@paralleldrive/cuid2';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  CreateLedgerRecordInput,
  LedgerRecordDTO,
} from '@aster/app/core/shared/dtos/billing';

import axios from '../../../app/axiosConfig';

import { ExtendedError } from '../../../components/ErrorBoundary';
import { useSnackbar } from '../../../components/Snack';

import {
  createMutationScopeID,
  MutationScope,
} from '../../../mutations/utils/create-mutation-scope-id';

const addRecord = async (record: CreateLedgerRecordInput) => {
  const { data: newRecord } = await axios.post('/v2/billing', record);

  return newRecord;
};

export const useAddRecordMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}) => {
  const { patient } = useParams();
  const { showMessage } = useSnackbar();

  const queryClient = useQueryClient();

  const ledgerQueryKey = ['ledger', ...(patient ? [patient] : [])];

  const addRecordMutation = useMutation({
    mutationKey: ['addRecord'],
    mutationFn: addRecord,
    scope: {
      id: createMutationScopeID(MutationScope.BILLING),
    },
    onMutate: async (record: CreateLedgerRecordInput) => {
      const previousFetchedRecords = queryClient.getQueryData(ledgerQueryKey);

      queryClient.setQueryData<LedgerRecordDTO[]>(ledgerQueryKey, (old) => {
        const newRecord: LedgerRecordDTO = {
          id: createId(), // temporary ID
          date: new Date().toISOString(),
          ...record,
        };

        if (!old) return [newRecord];

        return [...old, newRecord];
      });

      return { previousFetchedRecords };
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ledgerQueryKey,
      });

      showMessage({
        type: 'success',
        message: 'Record added successfully!',
      });

      onSuccess?.();
    },
    onError: (error, _, context) => {
      queryClient.setQueryData<LedgerRecordDTO[]>(
        ledgerQueryKey,
        context?.previousFetchedRecords as LedgerRecordDTO[]
      );

      const ledgerRecordCreationError: ExtendedError = new Error(error.message);

      ledgerRecordCreationError.name = `LedgerRecordCreationError`;
      ledgerRecordCreationError.stack = error.stack as string | undefined;
      ledgerRecordCreationError.cause = error;

      datadogRum.addError(ledgerRecordCreationError);

      showMessage({
        type: 'error',
        message: 'An error occurred while adding the record. Please try again!',
      });

      onError?.(error);
    },
  });

  return addRecordMutation;
};
