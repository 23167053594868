import { PatientProfileForEncounterDTO } from '@aster/app/core/shared/dtos/patient';
import { Button } from '@aster/client/ui/Button/Button';
import { useSendMessageMutation } from '../mutations/send-message.mutation';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUpFromBracket,
  faFile,
  faPaperclip,
  faVial,
} from '@fortawesome/pro-light-svg-icons';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@aster/client/ui/Tooltip/Tooltip';
import { Typography } from '@aster/client/ui/Typography/Typography';
import { useMessagesStore } from '../store/message.store';

type MessageComposerProps = {
  patient: PatientProfileForEncounterDTO;
};
export type MessageComposerRef = {
  focus: () => void;
};

export const MessageComposer = forwardRef<
  MessageComposerRef,
  MessageComposerProps
>(({ patient }: MessageComposerProps, ref) => {
  const selectedMessageThreadID = useMessagesStore(
    (state) => state.selectedMessageThreadID
  );
  const composerRef = useRef<ReactQuill>(null);
  const containerRef = useRef<HTMLElement>(null);
  const [message, setMessage] = useState('');

  useImperativeHandle(ref, () => ({
    focus: () => {
      containerRef.current?.scrollIntoView({ behavior: 'smooth' });
      composerRef.current?.focus();
    },
  }));

  const { mutate: sendMessage } = useSendMessageMutation({
    messageThreadID: selectedMessageThreadID as string,
  });

  const send = () => {
    sendMessage({
      content: message,
      threadID: selectedMessageThreadID as string,
      attachedDocumentIDs: [],
      linkedEncounterIDs: [],
      linkedLabOrderIDs: [],
    });
    setMessage('');
  };

  return (
    <section ref={containerRef} className="flex flex-col mt-4 mx-4 gap-3">
      <ReactQuill
        ref={composerRef}
        className="message-composer"
        theme="snow"
        style={{
          backgroundColor: 'white',
          width: 'auto',
          height: 'auto',
          fontSize: 14,
        }}
        value={message ?? null}
        placeholder={`Reply to ${patient.name}`}
        onChange={(value, _delta) => {
          setMessage(value);
        }}
      />

      <div className="flex justify-between items-center">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="flex rounded-md bg-gray-200">
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      disabled
                      variant="icon"
                      aria-label="Attach Document"
                    >
                      <FontAwesomeIcon icon={faPaperclip} />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <Typography variant="p-sm">
                      Attach Aster Document
                    </Typography>
                  </TooltipContent>
                </Tooltip>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button disabled variant="icon">
                      <FontAwesomeIcon icon={faArrowUpFromBracket} />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <Typography variant="p-sm">Upload a file</Typography>
                  </TooltipContent>
                </Tooltip>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button disabled variant="icon">
                      <FontAwesomeIcon icon={faFile} />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <Typography variant="p-sm">Attach Encounter</Typography>
                  </TooltipContent>
                </Tooltip>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button disabled variant="icon">
                      <FontAwesomeIcon icon={faVial} />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <Typography variant="p-sm">Attach Lab Results</Typography>
                  </TooltipContent>
                </Tooltip>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <Typography variant="p-sm">Attachments coming soon!</Typography>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <Button className="w-fit" onClick={send}>
          Send
        </Button>
      </div>
    </section>
  );
});
