import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useMemo, useState } from 'react';
import ButtonType from '../../components/Button';
import Typography from '../../components/Typography';
import { colors } from '../../theme';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Combobox from '../../components/Autocomplete';
import { DocumentIcon } from './DocumentIcon';
import { Search } from '@mui/icons-material';
import { AxiosResponse } from 'axios';
import useSearchQueryArgs from '../..//hooks/useSearchQueryArgs';
import { useSearchPatientsQuery } from '../patients/queries/search-patients.query';
import { PatientSortBy } from '@aster/app/core/shared/dtos/patient';

type ModalProps = {
  title: string;
  confirm: string;
  dismiss: string;
  open: boolean;
  handleConfirm: (
    patientId: string,
    email: string
  ) => Promise<AxiosResponse<any, any>>;
  isUploading: boolean;
  handleClose: () => void;
  selectedDocuments: RowData[];
};

const ShareDocumentsModal = (props: ModalProps) => {
  const {
    title,
    confirm,
    dismiss,
    open,
    handleConfirm,
    isUploading,
    handleClose,
    selectedDocuments,
  } = props;

  const { paginationModel, searchQuery, search, sortModel } =
    useSearchQueryArgs('documents');

  const { arePatientsLoading, arePatientsFetching, paginatedPatients } =
    useSearchPatientsQuery({
      search: searchQuery,
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      sortBy: sortModel?.sortBy as PatientSortBy,
      sortDir: sortModel?.sortDir,
    });

  const patients: PatientData[] = useMemo(() => {
    if (
      paginatedPatients?.items &&
      !arePatientsLoading &&
      !arePatientsFetching
    ) {
      return paginatedPatients.items.map((patient) => ({
        id: patient.id,
        label: `${patient.firstName} ${patient.lastName}`,
        email: patient.email,
      }));
    }
    return [];
  }, [arePatientsFetching, arePatientsLoading, paginatedPatients?.items]);

  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [patient, setPatient] = useState('');
  const [patientId, setPatientId] = useState('');
  const [email, setEmail] = useState('');

  const closeUploadModal = () => {
    if (!isUploading && uploadComplete) {
      setUploadComplete(false);
    }
    setError(null);
    setSuccessMessage(null);
    handleClose();
  };

  const sendDocuments = async () => {
    if (patientId) {
      const { status } = await handleConfirm(patientId, email);
      if (status === 200) {
        setUploadComplete(true);
        setSuccessMessage(`Files sent to ${email}`);
        setPatient('');
        setPatientId('');
        setEmail('');
        return;
      }
    }
    setError('Failed to send documents');
    return;
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={closeUploadModal}
      aria-describedby="alert-dialog-slide-description"
    >
      <>
        <div className="flex flex-col">
          <DialogContent className="flex flex-col items-center p-0">
            <Typography
              variant={'h4'}
              customClass="font-semibold my-5"
              text={title}
            />
            <div className="bg-grayBackground p-5 flex flex-col rounded-[20px] items-center justify-center mx-[10px]">
              {!isUploading && !uploadComplete && (
                <Combobox
                  autoCompleteStyles={{
                    width: '100%',
                    '& .MuiAutocomplete-popupIndicator': {
                      transform: 'none',
                    },
                  }}
                  icon={<Search />}
                  label="Patient"
                  value={patient}
                  isServerSide={true}
                  loading={arePatientsLoading || arePatientsFetching}
                  onChange={(_event: any, newValue: any) => {
                    setPatient(newValue?.label || '');
                    setPatientId(newValue?.id || '');
                    setEmail(newValue?.email || '');
                  }}
                  onInputChange={(_event: any, newInputValue: string) => {
                    search(newInputValue);
                  }}
                  options={patients}
                />
              )}
              <div className="mt-5">
                <Typography
                  variant={'bodyMedium'}
                  text={
                    'These files will be securely sent as email attachments:'
                  }
                  customClass="text-asterGray"
                />

                <ul className="mt-2">
                  {selectedDocuments.map((file) => (
                    <li key={file.id} className="flex mb-1">
                      <div className="flex items-center mr-2">
                        {DocumentIcon(file.name, 'text-asterGray fill-current')}
                      </div>
                      <Typography
                        variant={'bodyMedium'}
                        text={file.name}
                        customClass="text-asterGray"
                      />
                    </li>
                  ))}
                </ul>
              </div>
              {!isUploading && !uploadComplete && error && error && (
                <Typography
                  variant="bodySmall"
                  text={error}
                  customClass="mt-8 text-red-500"
                />
              )}
              {!isUploading && uploadComplete && successMessage && (
                <div className="flex flex-col items-center mt-4">
                  <div className="flex items-center">
                    <CheckCircleIcon
                      className="mr-1"
                      sx={{ color: colors.green }}
                    />
                    <Typography
                      variant="h4"
                      text={successMessage}
                      customClass="text-green-500"
                    />
                  </div>
                </div>
              )}
            </div>
          </DialogContent>
        </div>
        <DialogActions>
          <ButtonType
            variant="text"
            onClick={() => {
              closeUploadModal();
            }}
            text={isUploading || uploadComplete ? 'Close' : dismiss}
            className="w-[150px] h-[44px]"
          />
          {!uploadComplete && (
            <ButtonType
              loading={isUploading}
              disabled={!email}
              variant="contained"
              onClick={() => sendDocuments()}
              text={confirm}
              className="w-[150px] h-[44px]"
            />
          )}
        </DialogActions>
      </>
    </Dialog>
  );
};

export default ShareDocumentsModal;
