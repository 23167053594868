import { Button } from '@aster/client/ui/Button/Button';
import { Typography } from '@aster/client/ui/Typography/Typography';
import {
  faArrowUpFromBracket,
  faFile,
  faPaperclip,
  faVial,
  faX,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMessagesStore } from '../store/message.store';
import { useAuth } from '../../../authentication/AuthProvider';
import ReactQuill from 'react-quill';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@aster/client/ui/Tooltip/Tooltip';
import { Input } from '@aster/client/ui/Input/Input';
import { z } from 'zod';
import { Label } from '@aster/client/ui/Label/Label';
import { FormControl } from '@aster/client/ui/FormControl/FormControl';
import { FormError } from '@aster/client/ui/FormControl/FormError';
import { Combobox } from '@aster/client/ui/Combobox/Combobox';
import useSearchQueryArgs from '../../../hooks/useSearchQueryArgs';
import { useSearchPatientsQuery } from '../../patients/queries/search-patients.query';
import {
  PatientActiveStatuses,
  PatientSortBy,
} from '@aster/app/core/shared/dtos/patient';
import { useMemo, useRef } from 'react';
import { CreateMessageThreadDTO } from '@aster/app/message/shared/dtos';
import { useGetStaffs } from '../../calendar/queries/use-get-staffs.query';
import { useCreateMessageThreadMutation } from '../mutations/create-thread.mutation';

export const MessageThreadComposer = () => {
  const { profile } = useAuth();
  const quillRef = useRef<ReactQuill>(null);
  const selectMessageThreadID = useMessagesStore(
    (s) => s.selectMessageThreadID
  );
  const closeNewMessageThreadComposer = useMessagesStore(
    (state) => state.closeNewMessageThreadComposer
  );
  const { staffMembers, areStaffLoading } = useGetStaffs();
  const staffMemberIDs = useMemo(
    () => staffMembers?.map((s) => s.id),
    [staffMembers]
  );
  const { mutateAsync } = useCreateMessageThreadMutation({
    onSuccess: (newThread) => {
      selectMessageThreadID(newThread.id);
    },
  });

  const form = useForm({
    defaultValues: {
      patientID: '',
      subject: '',
      message: '',
    },
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      const newMessageThread: CreateMessageThreadDTO = {
        patientIDs: [value.patientID],
        staffMemberIDs: staffMemberIDs ?? [],
        subject: value.subject,
        initialMessage: {
          attachedDocumentIDs: [],
          content: value.message,
          linkedEncounterIDs: [],
          linkedLabOrderIDs: [],
        },
      };
      return mutateAsync(newMessageThread);
    },
  });
  const { paginationModel, searchQuery, search, sortModel } =
    useSearchQueryArgs('patient');
  const { paginatedPatients, arePatientsLoading, arePatientsFetching } =
    useSearchPatientsQuery({
      search: searchQuery,
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      sortBy: sortModel?.sortBy as PatientSortBy,
      sortDir: sortModel?.sortDir,
      statusFilter: PatientActiveStatuses,
    });

  const patientOptions = useMemo(() => {
    if (
      paginatedPatients?.items &&
      !arePatientsLoading &&
      !arePatientsFetching
    ) {
      return paginatedPatients.items.map((r) => ({
        value: r.id,
        label: `${r.firstName} ${r.lastName}`,
      }));
    }
    return [];
  }, [paginatedPatients?.items, arePatientsLoading, arePatientsFetching]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        void form.handleSubmit();
      }}
      className="flex flex-col col-span-3 mx-4"
    >
      <div className="flex items-center justify-between w-full mb-4">
        <Typography variant="p" className="font-semibold">
          New Message
        </Typography>
        <Button variant="icon" onClick={closeNewMessageThreadComposer}>
          <FontAwesomeIcon icon={faX} />
        </Button>
      </div>
      <div className="grid grid-rows-3 grid-cols-fill-last items-center gap-x-10 gap-y-3">
        <Typography variant="p-sm" className="text-zinc-900 font-medium">
          From:
        </Typography>
        <Typography variant="p-sm" className="text-gray-500 font-medium">
          {profile?.firstName} {profile?.lastName}
        </Typography>
        <Label htmlFor="patientID" className="text-zinc-900 font-medium">
          To:
        </Label>
        <form.Field
          name="patientID"
          validators={{
            onChange: z.string().cuid2('Please provide a valid id.'),
          }}
          children={(field) => (
            <FormControl>
              <Combobox
                shouldUseClientSideFilter={false}
                items={patientOptions}
                onSearch={(value) => search(value)}
                placeholder="Search a patient"
                emptyMessage="There are no patients to select"
                onSelect={(value) => field.handleChange(value)}
                hasError={field.state.meta.errors.length > 0}
                errorMessageId={`err-${field.name}`}
                loading={arePatientsLoading || arePatientsFetching}
                popoverAlign="start"
              />

              <FormError id={`err-${field.name}`}>
                {field.state.meta.errors?.join('\r')}
              </FormError>
            </FormControl>
          )}
        />
        <Label htmlFor="subject" className="text-zinc-900 font-medium">
          Subject:
        </Label>
        <form.Field
          name="subject"
          validators={{
            onBlur: z.string().min(1, {
              message: 'The subject must have at least 1 character',
            }),
          }}
          children={(field) => (
            <FormControl>
              <Input
                className="bg-white"
                aria-describedby={`hint-${field.name}`}
                name={field.name}
                placeholder="Add a subject"
                id={field.name}
                value={field.state.value}
                onBlur={field.handleBlur}
                hasError={field.state.meta.errors.length > 0}
                errorMessageId={`err-${field.name}`}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  field.handleChange(event.target.value)
                }
              />
              <FormError id={`err-${field.name}`}>
                {field.state.meta.errors?.join('\r')}
              </FormError>
            </FormControl>
          )}
        ></form.Field>
      </div>
      <div className="flex flex-col mt-4 gap-3">
        <form.Field
          name="message"
          validators={{
            onBlur: z.string().min(1, {
              message: 'The message must have at least 1 character',
            }),
          }}
          children={(field) => (
            <FormControl>
              <ReactQuill
                ref={quillRef}
                aria-invalid={field.state.meta.errors.length > 0}
                aria-errormessage={`err-${field.name}`}
                id={field.name}
                className="message-composer-xl"
                onBlur={field.handleBlur}
                theme="snow"
                style={{
                  backgroundColor: 'white',
                  width: 'auto',
                  height: 'auto',
                  fontSize: 14,
                }}
                value={field.state.value ?? null}
                placeholder={`Compose a message`}
                onChange={(value, _delta) => {
                  if (value === '<p><br></p>') {
                    field.handleChange('');
                  } else {
                    field.handleChange(value);
                  }
                }}
              />
              <FormError id={`err-${field.name}`}>
                {field.state.meta.errors?.join('\r')}
              </FormError>
            </FormControl>
          )}
        ></form.Field>

        <div className="flex justify-between items-center">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex rounded-md bg-gray-200">
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        disabled
                        variant="icon"
                        aria-label="Attach Document"
                      >
                        <FontAwesomeIcon icon={faPaperclip} />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <Typography variant="p-sm">
                        Attach Aster Document
                      </Typography>
                    </TooltipContent>
                  </Tooltip>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button disabled variant="icon">
                        <FontAwesomeIcon icon={faArrowUpFromBracket} />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <Typography variant="p-sm">Upload a file</Typography>
                    </TooltipContent>
                  </Tooltip>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button disabled variant="icon">
                        <FontAwesomeIcon icon={faFile} />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <Typography variant="p-sm">Attach Encounter</Typography>
                    </TooltipContent>
                  </Tooltip>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button disabled variant="icon">
                        <FontAwesomeIcon icon={faVial} />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <Typography variant="p-sm">Attach Lab Results</Typography>
                    </TooltipContent>
                  </Tooltip>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <Typography variant="p-sm">Coming up next</Typography>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <form.Subscribe
            selector={(state) => [
              state.canSubmit,
              state.isSubmitting,
              state.isFieldsValidating,
            ]}
            children={([canSubmit, isSubmitting, isValidating]) => (
              <Button
                isLoading={isSubmitting || isValidating || areStaffLoading}
                disabled={!canSubmit || areStaffLoading}
                className="w-fit"
                type="submit"
              >
                Send
              </Button>
            )}
          />
        </div>
      </div>
    </form>
  );
};
