import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useState } from 'react';
import { Link } from 'react-router';

import {
  faCheck,
  faClockFive,
  faInfoCircle,
  faPencil,
  faTrash,
  faUserDoctor,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@mui/material';

import {
  AppointmentTypeDisplayNames,
  InviteeDTO,
} from '@aster/app/core/shared/dtos/appointment';
import { EncounterType } from '@aster/app/core/shared/dtos/encounter';

import ButtonType from '../../../../../components/Button';
import SelectDropdown from '../../../../../components/SelectDropdown';
import Typography from '../../../../../components/Typography';
import { cn } from '@aster/client/utils/cn';

dayjs.extend(isBetween);

export type AppointmentCardProps = {
  id: string;
  type: string;
  startTime: string | Date;
  endTime: string | Date;
  invitedStaff: InviteeDTO[];
  patientID: string;
  encounterID?: string;
  onEditClick?: (id: string) => void;
  onDeleteClick?: (id: string) => void;
  onCreateEncounterClick?: (id: string, type: string) => void;
  loading?: boolean;
  disabled?: boolean;
};

const AppointmentTimeStatus = {
  inProgress: 'in-progress',
  passed: 'passed',
};

const AppointmentTimeStatusDisplayNames = {
  [AppointmentTimeStatus.inProgress]: 'In Progress',
  [AppointmentTimeStatus.passed]: 'Passed',
};

export const AppointmentCard = ({
  id,
  type,
  startTime,
  endTime,
  invitedStaff,
  patientID,
  encounterID,
  onEditClick,
  onDeleteClick,
  onCreateEncounterClick,
  loading = false,
  disabled = false,
}: AppointmentCardProps) => {
  const [selectedEncounterType, setSelectedEncounterType] =
    useState<string>('');

  const status = dayjs().isBetween(startTime, endTime)
    ? AppointmentTimeStatus.inProgress
    : dayjs().isAfter(endTime)
    ? AppointmentTimeStatus.passed
    : '';

  const statusClassNames = cn({
    'text-green-700': status === AppointmentTimeStatus.inProgress,
    'text-yellow-700': status === AppointmentTimeStatus.passed,
  });

  const encounterTypes = [
    { value: EncounterType.basic, text: 'Basic' },
    { value: EncounterType.birthflow, text: 'Labor & Birth' },
    { value: EncounterType.prenatal, text: 'Prenatal' },
    { value: EncounterType.soap, text: 'SOAP' },
  ];

  return (
    <div className="border border-grayBorder bg-white p-4 rounded-[16px] space-y-3">
      <div className="space-y-2">
        <div className="flex justify-between">
          <Typography variant="body" customClass="font-semibold text-[16px]">
            {dayjs(startTime).format('dddd, MMMM D, YYYY')}{' '}
            {loading ? <CircularProgress size={14} className="ml-2" /> : ''}
          </Typography>
          <div className="space-x-[10px]">
            <button
              className="text-aster-main disabled:opacity-50 disabled:grayscale w-[16px]"
              onClick={() => onEditClick?.(id)}
              disabled={loading || disabled}
            >
              <FontAwesomeIcon icon={faPencil} />
            </button>
            <button
              className="text-aster-main disabled:opacity-50 disabled:grayscale w-[16px]"
              onClick={() => onDeleteClick?.(id)}
              disabled={loading || disabled}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        </div>
        <div className="text-gray-500 text-body">
          <FontAwesomeIcon icon={faClockFive} className="mr-2" />
          {dayjs(startTime).format('h:mm A')} -{' '}
          {dayjs(endTime).format('h:mm A')}{' '}
          {status ? (
            <span className={statusClassNames}>
              ({AppointmentTimeStatusDisplayNames[status]})
            </span>
          ) : (
            ''
          )}
        </div>
        <div className="text-gray-500 text-body">
          <FontAwesomeIcon icon={faUserDoctor} className="mr-2" />
          {invitedStaff?.[0]?.firstName ?? '...'}{' '}
          {invitedStaff?.[0]?.lastName ?? ''}{' '}
        </div>
        <div className="text-gray-500 text-body">
          <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
          {AppointmentTypeDisplayNames[type]}
        </div>
      </div>
      <hr className="border-[0.5px] border-grayBorder" />
      <div className="flex gap-3 items-center">
        {encounterID ? (
          <>
            <div className="w-full content-center text-success-700">
              <FontAwesomeIcon
                icon={faCheck}
                className="align-middle mb-0.5 mr-2 text-[20px]"
              />{' '}
              Encounter Created
            </div>
            <Link
              to={`/encounter/${patientID}/${encounterID}`}
              className="w-full"
            >
              <ButtonType
                variant="contained"
                className="w-full py-3 bg-aster-main hover:bg-main hover:bg-opacity-80"
                text="Open Encounter"
              />
            </Link>
          </>
        ) : (
          <>
            <SelectDropdown
              placeholder="Encounter Type"
              options={encounterTypes}
              value={selectedEncounterType}
              handleChange={(evt: any) =>
                setSelectedEncounterType(evt.target.value)
              }
              classes="[&_.MuiSelect-select]:pl-[12px] [&_.MuiSelect-select]:py-[8.5px]"
              disabled={loading || disabled}
            />
            <ButtonType
              variant="outlined"
              className="w-full py-3"
              text="Create Encounter"
              onClick={() =>
                onCreateEncounterClick?.(id, selectedEncounterType)
              }
              disabled={loading || disabled || !selectedEncounterType}
            />
          </>
        )}
      </div>
    </div>
  );
};
