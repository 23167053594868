import { useMemo, useState } from 'react';
import {
  CircularProgress,
  Divider,
  IconButton,
  Popper,
  SelectChangeEvent,
} from '@mui/material';
import { PopperOwnProps } from '@mui/material/Popper/BasePopper.types';
import Typography from '../../components/Typography';
import ButtonType from '../../components/Button';
import SelectDropdown from '../../components/SelectDropdown';
import { EventClickArg } from '@fullcalendar/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendar,
  faClipboard,
  faClock,
  faEllipsisVertical,
  faNote,
  faPencil,
  faTrash,
  faUpRightFromSquare,
  faUserDoctor,
} from '@fortawesome/pro-light-svg-icons';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@aster/client/ui/DropdownMenu/DropdownMenu';
import dayjs from 'dayjs';
import { InviteeDTO } from '@aster/app/core/shared/dtos/appointment';
import { TemplateType } from '@aster/app/core/shared/dtos/encounter';
import {
  encounterOptions,
  encounterTypeDisplayNames,
} from '../notes/constants';
import { useGetEncounters } from '../patients/queries/use-get-encounters.query';
import { useNavigate } from 'react-router';
import { usePatientProfileQuery } from '../notes/queries/patient-profile.query';
import GPTAL from '../notes/sections/GTPAL';
import { useCreateEncounterMutation } from '../patients/profileTabs/OverviewTab/mutations/use-create-encounter-mutation';
import { useRemindAppointmentMutation } from './mutations/use-remind-appointment-mutation';
import { CircularSpinner } from '@aster/client/ui/CircularSpinner/CircularSpinner';

type CalendarEventDetailsProps = {
  eventClickArg: EventClickArg | null;
  handleEdit: () => void;
  handleDelete: () => void;
  popperProps: PopperOwnProps;
};

const CalendarEventDetails = ({
  eventClickArg,
  handleEdit,
  handleDelete,
  popperProps,
}: CalendarEventDetailsProps) => {
  const eventProps = eventClickArg?.event._def.extendedProps;
  const navigate = useNavigate();
  const [encounterType, setEncounterType] = useState<TemplateType>(
    encounterOptions[0].value as TemplateType
  );
  const { createEncounter, status: encounterMutationStatus } =
    useCreateEncounterMutation({
      onSuccess: () => {
        void refetch();
      },
    });

  const { patientProfile, isPatientProfileLoading } = usePatientProfileQuery(
    eventProps?.invitedPatients[0]?.id as string
  );

  const {
    data: encounters,
    isLoading: encountersLoading,
    refetch,
  } = useGetEncounters(eventProps?.invitedPatients[0]?.id as string);

  const createEncounterHandler = () => {
    const info = {
      patientID: eventProps?.invitedPatients[0].id as string,
      templateType: encounterType,
      appointmentID: eventProps?.originalId as string,
      startTime: (eventClickArg?.event.start as Date).toISOString(),
    };
    createEncounter(info);
  };

  const appointmentEncounter = useMemo(
    () =>
      encounters?.find(
        (encounter) => encounter.appointmentID === eventProps?.originalId
      ),
    [encounters, eventProps]
  );

  const eventTitle = eventProps?.invitedPatients[0]
    ? eventProps.invitedPatients[0].firstName +
      ' ' +
      eventProps.invitedPatients[0].lastName
    : null;

  const { remindAppointment, isRemindingAppointment } =
    useRemindAppointmentMutation({});

  if (!eventProps) return null;

  return (
    <Popper
      className={'z-10 bg-white p-4 shadow-2xl rounded-2xl w-[280px]'}
      {...popperProps}
    >
      <div className="flex flex-col">
        {eventTitle && (
          <div className="flex flex-col">
            <div className="flex items-center mb-2">
              <Typography
                variant={'h5'}
                customClass="break-all"
                text={
                  eventProps.invitedPatients[0].firstName +
                  ' ' +
                  eventProps.invitedPatients[0].lastName
                }
              />
              <IconButton
                className={'ml-[10px] text-body'}
                onClick={() =>
                  navigate(
                    `/patientProfile/${eventProps.invitedPatients[0].id}`
                  )
                }
              >
                <FontAwesomeIcon icon={faUpRightFromSquare} />
              </IconButton>
            </div>
            {isPatientProfileLoading && <CircularProgress size={12} />}
            {patientProfile && (
              <GPTAL
                mrn={patientProfile.mrn}
                calculatedDueDate={patientProfile.calculatedDueDate}
                gestationalAge={patientProfile.gestationalAge}
                gtpal={patientProfile.gtpal}
                dateOfBirth={patientProfile.dateOfBirth}
                problemList={patientProfile.problemList}
                isPregnant={patientProfile.isPregnant}
              />
            )}
            <Divider className="text-aster-500 my-4" />
          </div>
        )}
        <div className="flex justify-between items-center mb-2">
          <Typography
            variant={'bodyMedium'}
            text={'Appointment Details'}
            customClass="font-semibold"
          />
          <div className="flex items-center">
            <IconButton className="text-body w-[28px]" onClick={handleEdit}>
              <FontAwesomeIcon
                icon={faPencil}
                className="text-asterGreen-900"
              />
            </IconButton>
            <IconButton className={'text-body w-[28px]'} onClick={handleDelete}>
              <FontAwesomeIcon icon={faTrash} className="text-asterGreen-900" />
            </IconButton>

            <DropdownMenu>
              <DropdownMenuTrigger className="focus-visible:outline-none">
                <IconButton
                  className={'text-body w-[28px]'}
                  onClick={() => null}
                >
                  <FontAwesomeIcon
                    icon={faEllipsisVertical}
                    className="text-asterGreen-900"
                  />
                </IconButton>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="bg-white">
                <DropdownMenuItem
                  onSelect={() => {
                    remindAppointment(eventProps.originalId);
                  }}
                  className="hover:bg-gray-200"
                  disabled={isRemindingAppointment}
                >
                  {isRemindingAppointment && <CircularSpinner />}
                  Send Appointment Reminder
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex items-center">
            <FontAwesomeIcon icon={faCalendar} className="text-gray-500 mr-2" />
            <Typography
              variant={'bodyMedium'}
              text={dayjs(eventClickArg.event.start).format('dddd, MMMM DD')}
              customClass="text-gray-500"
            />
          </div>
          <div className="flex items-center">
            <FontAwesomeIcon icon={faClock} className="text-gray-500 mr-2" />
            <Typography
              variant={'bodyMedium'}
              text={`${dayjs(eventClickArg.event.start).format(
                'h:mm A'
              )}-${dayjs(eventClickArg.event?.end).format('h:mm A')}`}
              customClass="text-gray-500"
            />
          </div>
          {eventProps?.appointment && (
            <div className="flex items-center">
              <FontAwesomeIcon
                icon={faClipboard}
                className="text-gray-500 mr-2"
              />
              <Typography
                variant={'bodyMedium'}
                text={eventProps?.appointment}
                customClass="text-gray-500"
              />
            </div>
          )}
          {eventProps?.invitedStaffs.length > 0 &&
            eventProps?.invitedStaffs.map((staff: InviteeDTO) => (
              <div className="flex items-center">
                <FontAwesomeIcon
                  icon={faUserDoctor}
                  className="text-gray-500 mr-2"
                />
                <Typography
                  variant={'bodyMedium'}
                  text={staff.firstName + ' ' + staff.lastName}
                  customClass="text-gray-500"
                />
              </div>
            ))}
          {eventProps?.note && (
            <div className="flex max-h-[200px] pr-2 items-center">
              <FontAwesomeIcon icon={faNote} className="text-gray-500 mr-2" />
              <Typography
                variant={'bodyMedium'}
                text={`${eventProps?.note}`}
                customClass="text-gray-500 break-words overflow-auto"
              />
            </div>
          )}
        </div>
        {eventProps?.invitedPatients.length === 1 && !appointmentEncounter && (
          <>
            <Divider className="text-aster-500 my-4" />
            <Typography
              variant={'meta'}
              customClass={'text-gray-500'}
              text={'Encounter Type'}
            />
            <SelectDropdown
              handleChange={(e: SelectChangeEvent) => {
                setEncounterType(e.target.value as TemplateType);
              }}
              classes="[&_.MuiOutlinedInput-notchedOutline]:rounded-[5px] [&_.MuiSelect-select]:text-bodySmall [&_.MuiSelect-select]:font-semibold [&_.MuiSelect-select]:!py-[5px] [&_.MuiSelect-select]:!pl-[10px] [&_.MuiSelect-select]:mb-[0.075rem]"
              label=""
              options={encounterOptions}
              value={encounterType}
            />
            <ButtonType
              disabled={
                encounterMutationStatus === 'pending' || encountersLoading
              }
              className="w-full rounded-[5px] h-[32px] bg-asterGreen-900 mt-4 hover:bg-asterGreen-700 text-bodySmall"
              loading={
                encounterMutationStatus === 'pending' || encountersLoading
              }
              onClick={() => createEncounterHandler()}
              variant={'contained'}
              text={'Create Encounter'}
            />
          </>
        )}
        {appointmentEncounter && (
          <div
            onClick={() =>
              navigate(
                `/encounter/${appointmentEncounter.patientID}/${appointmentEncounter.id}`
              )
            }
            className="flex justify-between items-center w-full rounded-[5px] h-[32px] bg-gray-100 p-2 mt-4 cursor-pointer"
          >
            <Typography
              variant={'bodyMedium'}
              text={`${
                encounterTypeDisplayNames[appointmentEncounter.templateType]
              } Encounter`}
              customClass="text-asterGreen-900"
            />
            <Typography
              variant={'bodyMedium'}
              text={'Open'}
              customClass="text-gray-700"
            />
          </div>
        )}
      </div>
    </Popper>
  );
};

export default CalendarEventDetails;
