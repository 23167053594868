import { useMemo, useState } from 'react';
import { useUserUsagePreferences } from '../../../hooks/useUserUsagePreference';
import { MessageStatusDTO } from '@aster/app/message/shared/dtos';

export interface FilterState {
  messageThreadStatus: MessageStatusDTO;
}

export interface ServerFilters {
  statusFilter?: MessageStatusDTO;
}

export const defaultFilterState: FilterState = {
  messageThreadStatus: 'active',
};

const MESSAGE_THREAD_FILTER_PREFERENCE_KEY =
  '__message-thread-search-filters__';

export function useMessageThreadFilters(
  initialFilterState = defaultFilterState,
  useStoredPreferences = false
) {
  const { storePreference, readPreference } = useUserUsagePreferences();
  const [filterState, setFilterState] = useState<FilterState>(
    useStoredPreferences
      ? readPreference<FilterState>(MESSAGE_THREAD_FILTER_PREFERENCE_KEY) ??
          initialFilterState
      : initialFilterState
  );

  /**
   * Updates the filter state based on the provided category and value.
   *
   * @template K - The key of the filter state.
   * @param {K} category - The category of the filter to update.
   * @param {FilterState[K] extends (infer U)[] ? U : FilterState[K]} value - The value to update the filter with.
   */
  const updateFilter = <K extends keyof FilterState>(
    category: K,
    value: FilterState[K] extends (infer U)[] ? U : FilterState[K]
  ) => {
    setFilterState((prevState) => {
      const newState: FilterState = { ...prevState };

      newState[category] = value as FilterState[K];

      if (useStoredPreferences) {
        storePreference(MESSAGE_THREAD_FILTER_PREFERENCE_KEY, newState);
      }

      return newState;
    });
  };

  const clearFilters = () => {
    setFilterState(defaultFilterState);
    if (useStoredPreferences) {
      storePreference(MESSAGE_THREAD_FILTER_PREFERENCE_KEY, defaultFilterState);
    }
  };

  const serverFilters = useMemo(
    () => transformFilters(filterState),
    [filterState]
  );

  return { filterState, updateFilter, serverFilters, clearFilters };
}

function transformFilters(filters: FilterState): ServerFilters {
  return {
    statusFilter: filters.messageThreadStatus,
  };
}
