import { datadogRum } from '@datadog/browser-rum';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import axios from '../../../app/axiosConfig';

import { ExtendedError } from '../../../components/ErrorBoundary';
import { useSnackbar } from '../../../components/Snack';

import {
  createMutationScopeID,
  MutationScope,
} from '../../../mutations/utils/create-mutation-scope-id';
import {
  CreateMessageThreadDTO,
  MessageThreadInfoDTO,
} from '@aster/app/message/shared/dtos';

const createMessageThread = async (threadToCreate: CreateMessageThreadDTO) => {
  const { data: newThread } = await axios.post<MessageThreadInfoDTO>(
    `/v2/message/thread`,
    threadToCreate
  );

  return newThread;
};

export const useCreateMessageThreadMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (newThread: MessageThreadInfoDTO) => void;
  onError?: (error: Error) => void;
}) => {
  const { showMessage } = useSnackbar();

  const messageThreadQueryKey = ['messageThreads'];
  const queryClient = useQueryClient();

  const sendMessageMutation = useMutation({
    mutationKey: ['createMessageThread'],
    mutationFn: createMessageThread,
    scope: {
      id: createMutationScopeID(MutationScope.MESSAGE),
    },
    onSuccess: (newThread) => {
      void queryClient.invalidateQueries({
        queryKey: messageThreadQueryKey,
      });

      showMessage({
        type: 'success',
        message: 'Message thread successfully created!',
      });

      onSuccess?.(newThread);
    },
    onError: (error, _, context) => {
      const messageSendingError: ExtendedError = new Error(error.message);

      messageSendingError.name = `MessageSendingErrorError`;
      messageSendingError.stack = error.stack as string | undefined;
      messageSendingError.cause = error;

      datadogRum.addError(messageSendingError);

      showMessage({
        type: 'error',
        message:
          'An error occurred while creating the message thread. Please try again!',
      });

      onError?.(error);
    },
  });

  return sendMessageMutation;
};
