import { useQuery } from '@tanstack/react-query';
import axios from '../../../app/axiosConfig';
import { MessageThreadDTO } from '@aster/app/message/shared/dtos';

const fetchMessageThread = async (messageThreadID: string) => {
  const response = await axios.get<MessageThreadDTO>(
    `/v2/message/thread/${messageThreadID}`
  );
  return response.data;
};

export function useMessageThreadDetailsQuery(messageThreadID: string) {
  const { data: messageThread, isLoading } = useQuery({
    queryKey: ['messageThread', messageThreadID].filter((x) => x != null),
    queryFn: () => fetchMessageThread(messageThreadID),
    refetchInterval: 5000,
  });

  return {
    messageThread,
    isMessageThreadLoading: isLoading,
  };
}
