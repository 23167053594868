import { LabTestDTO } from '@aster/app/core/shared/dtos/labs';
import axios from '../../../app/axiosConfig';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useLabsStore } from '../store/labs.store';

import Fuse from 'fuse.js';

const fuseOptions = {
  keys: ['name', 'labName', 'description', 'sampleType', 'price'],
};

const fetchLabTests = async () => {
  const response = await axios.get<LabTestDTO[]>(`/v2/labs/tests`);
  return response.data;
};
export function useAvailableLabTests() {
  const { data: availableLabTests, isLoading: areLabTestsLoading } = useQuery({
    queryKey: ['labTests'],
    queryFn: fetchLabTests,
  });

  const fuse = useMemo(() => {
    return availableLabTests ? new Fuse(availableLabTests, fuseOptions) : null;
  }, [availableLabTests]);

  const searchQuery = useLabsStore((state) => state.searchQuery);

  const filteredLabTests = useMemo(() => {
    if (!searchQuery || !fuse) return availableLabTests;

    return fuse.search(searchQuery).map((result) => result.item);
  }, [availableLabTests, fuse, searchQuery]);

  return { availableLabTests, filteredLabTests, areLabTestsLoading };
}
