import { PatientProfileForEncounterDTO } from '@aster/app/core/shared/dtos/patient';
import { Typography } from '@aster/client/ui/Typography/Typography';
import { faUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatMRN } from '../../../../src/utils';
import { Link } from 'react-router';
import Tag from '../../../../src/components/Tag';
import dayjs from 'dayjs';
import { cn } from '@aster/client/utils/cn';

type PatientProfileHeaderProps = {
  patientProfile: PatientProfileForEncounterDTO;
};

export const PatientProfileHeader = ({
  patientProfile,
}: PatientProfileHeaderProps) => {
  const gtpalInfoFull =
    patientProfile.gtpal &&
    `G${patientProfile.gtpal.gravida} T${patientProfile.gtpal.termBirths} P${patientProfile.gtpal.pretermBirths} A${patientProfile.gtpal.abortionsAndMiscarriages} L${patientProfile.gtpal.livingChildren}`;
  const hasAdvancedMaternalAge = Boolean(
    patientProfile.problemList?.find((problem) => problem.abbr === 'AMA')
  );
  const gaValues = patientProfile.gestationalAge?.match(/\d+/g);
  const ga = gaValues && {
    gestationalWeeks: Number.parseInt(gaValues[0]),
    gestationalDays: Number.parseInt(gaValues[1]),
  };
  let gaColor: string | null = 'bg-primary';

  if (ga) {
    //  color based on gestational age
    if (ga.gestationalWeeks >= 28) {
      gaColor = 'bg-pink-600';
    } else if (ga.gestationalWeeks >= 14) {
      gaColor = 'bg-indigo-500';
    } else if (ga.gestationalWeeks > 0) {
      gaColor = 'bg-sky-400';
    }
  }
  return (
    <header className="flex flex-col">
      <Link
        className="flex items-center gap-3"
        to={`/patientProfile/${patientProfile.patientID}`}
      >
        <Typography className="text-aster-dark" variant="h4">
          {patientProfile.name}
        </Typography>
        <FontAwesomeIcon
          className="text-aster-dark"
          icon={faUpRightFromSquare}
        />
      </Link>
      <div className="flex items-center gap-3">
        {patientProfile.mrn && (
          <Tag
            tooltip="Medical Record Number"
            tagText={formatMRN(patientProfile.mrn)}
            tagTextClassName="font-normal text-gray-600"
          />
        )}
        {patientProfile.dateOfBirth && (
          <Tag
            tooltip="Date of Birth"
            tagText="DOB"
            rightTextClassName="text-gray-600"
            rightText={dayjs(patientProfile.dateOfBirth).format('MM/DD/YYYY')}
          />
        )}
        {gtpalInfoFull && (
          <Tag tooltip={gtpalInfoFull} tagText={gtpalInfoFull} />
        )}
        {patientProfile.isPregnant && patientProfile.calculatedDueDate && (
          <Tag
            tooltip="Estimated Due Date"
            tagText="EDD"
            rightTextClassName="text-gray-600"
            rightText={dayjs(patientProfile.calculatedDueDate).format(
              'MM/DD/YYYY'
            )}
          />
        )}
        {patientProfile.isPregnant && patientProfile.gestationalAge && (
          <Tag
            tooltip="Gestational Age"
            tagText={patientProfile.gestationalAge ?? ''}
            className={cn('font-semibold', gaColor)}
            tagTextClassName="text-white"
          />
        )}
        {hasAdvancedMaternalAge ||
          (true && (
            <Tag
              tooltip="Advanced Maternal Age"
              tagText="AMA"
              className="font-semibold bg-red-400"
              tagTextClassName="text-white"
            />
          ))}
      </div>
    </header>
  );
};
