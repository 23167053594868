import { useCallback, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useUserUsagePreferences } from './useUserUsagePreference';

type PaginationModel = {
  page: number;
  pageSize: number;
};

type SortModel = {
  sortBy?: string;
  sortDir?: 'asc' | 'desc' | null;
};

export type UseQueryArgsProps = {
  searchDelay?: number;
  initialPaginationModel?: PaginationModel;
  initialSortModel?: SortModel;
};

const DEFAULT_PAGINATION_MODEL: PaginationModel = {
  page: 0,
  pageSize: 10,
};

const DEFAULT_SORT_MODEL: SortModel = {
  sortBy: 'timeUpdated',
  sortDir: 'desc',
};

const SEARCH_PAGINATION_PREFERENCE_KEY = (entity: string) =>
  `__${entity}-search-pagination__`;
const SEARCH_SORT_PREFERENCE_KEY = (entity: string) =>
  `__${entity}-search-sort__`;

function useSearchQueryArgs(
  entity: string,
  {
    searchDelay = 300,
    initialPaginationModel = DEFAULT_PAGINATION_MODEL,
    initialSortModel = DEFAULT_SORT_MODEL,
  }: UseQueryArgsProps = {},
  useStoredPreferences = false
) {
  const { storePreference, readPreference } = useUserUsagePreferences();
  const [paginationModel, setPaginationModel] = useState(
    useStoredPreferences
      ? readPreference<PaginationModel>(
          SEARCH_PAGINATION_PREFERENCE_KEY(entity)
        ) ?? initialPaginationModel
      : initialPaginationModel
  );

  const [sortModel, setSortModel] = useState<SortModel>(
    useStoredPreferences
      ? readPreference<SortModel>(SEARCH_SORT_PREFERENCE_KEY(entity)) ??
          initialSortModel
      : initialSortModel
  );
  const [searchQuery, setSearchQuery] = useState<string>('');
  const search = useDebouncedCallback((value: string) => {
    setSearchQuery(value);
    setPaginationModel((prev) => ({ ...prev, page: 0 }));
  }, searchDelay);

  const patchedSetPaginationModel = useCallback(
    (newModel: Partial<PaginationModel>) => {
      setPaginationModel((prev) => {
        if (useStoredPreferences) {
          storePreference(SEARCH_PAGINATION_PREFERENCE_KEY(entity), {
            ...prev,
            ...newModel,
          });
        }
        return {
          ...prev,
          ...newModel,
          page: prev.pageSize !== newModel.pageSize ? 0 : newModel.page ?? 0,
        };
      });
    },
    [entity, storePreference, useStoredPreferences]
  );

  const patchedSetSortModel = useCallback(
    (newModel: Partial<SortModel>) => {
      setSortModel((prev) => {
        if (useStoredPreferences) {
          storePreference(SEARCH_SORT_PREFERENCE_KEY(entity), {
            ...prev,
            ...newModel,
          });
        }
        return { ...prev, ...newModel };
      });
    },
    [entity, storePreference, useStoredPreferences]
  );

  return {
    paginationModel,
    setPaginationModel: patchedSetPaginationModel,
    searchQuery,
    search,
    sortModel,
    setSortModel: patchedSetSortModel,
  };
}

export default useSearchQueryArgs;
