import { useState, useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import { GridColDef, GridEventListener } from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router';
import axios from '../../../app/axiosConfig';
import dayjs from 'dayjs';
import { useMutation } from '@tanstack/react-query';
import Typography from '../../../components/Typography';
import ConfirmationModal from '../../../components/ConfirmationModal';
import CodingModal from '../../notes/components/CodingModal';
import { Codes } from '../../notes/types/encounter';
import StyledDataGrid from '../../../components/StyledDataGrid';
import { names } from '@aster/shared/utils';
import { PatientProfileForEncounterDTO } from '@aster/app/core/shared/dtos/patient';
import { EncounterDTO } from '@aster/app/core/shared/dtos/encounter';
import { usePatientVitals } from '../../notes/sections/queries/fetch-vitals.query';
import { downloadEncountersPdf } from '../../notes/sections/utils';
import {
  createMutationScopeID,
  MutationScope,
} from '../../../mutations/utils/create-mutation-scope-id';
import { isPatientReadonly } from '../utils/is-patient-readonly';

type EncounterListProps = {
  encounters: EncounterDTO[] | undefined;
  areEncountersLoading: boolean;
  refetchEncounters: () => void;
  isSelectingEncounters: boolean;
  setSelectedEncounterIds: (encounters: string[]) => void;
  patientProfile: PatientProfileForEncounterDTO | undefined;
};

const EncountersList = ({
  encounters,
  areEncountersLoading,
  refetchEncounters,
  isSelectingEncounters,
  setSelectedEncounterIds,
  patientProfile,
}: EncounterListProps) => {
  const navigate = useNavigate();
  const { patient } = useParams();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [selectedEncounterID, setSelectedEncounterID] = useState<string | null>(
    null
  );
  const [selectedCodes, setSelectedCodes] = useState<Codes>({
    cptCodes: [],
    icdCodes: [],
  });

  const [codeModalOpen, setCodeModalOpen] = useState(false);

  const selectedEncounter = useMemo(() => {
    if (selectedEncounterID) {
      return encounters?.find((r) => r.id === selectedEncounterID);
    }
    return undefined;
  }, [selectedEncounterID, encounters]);

  const handleChangeCodes = (codes: Codes) => {
    setSelectedCodes(codes);
  };

  const { vitals } = usePatientVitals(patient as string);

  const rows = useMemo(() => {
    if (encounters) {
      return encounters.map((r) => {
        const newRow = {
          id: r.id,
          start: dayjs(r.startTime).format('MMMM D, YYYY h:mm A'),
          byName: r.signedByName ? r.signedByName : r.lastModifiedByName,
          type:
            r.templateType === 'birthflow'
              ? 'Labor & Birth'
              : names(r.templateType).sentenceCase,
          status: r.signedBy ? 'Signed ' : 'Draft',
          codes: { cptCodes: r.cptCodes, icdCodes: r.icdCodes } as Codes,
        };
        return newRow;
      });
    }
    return [];
  }, [encounters]);

  type EncounterRow = (typeof rows)[0];

  const deleteNote = async () => axios.delete(`encounters/${deleteId}`);

  const deleteNoteMutation = useMutation({
    mutationKey: ['deleteNote'],
    mutationFn: deleteNote,
    scope: {
      id: createMutationScopeID(MutationScope.ENCOUNTER, selectedEncounterID),
    },
    onSuccess: () => {
      setOpenConfirmation(false);
      refetchEncounters();
    },
  });

  const handleDelete = () => {
    deleteNoteMutation.mutate();
  };

  const columns: GridColDef[] = [
    {
      field: 'type',
      headerName: 'Encounter Type',
      headerAlign: 'left',
      flex: 0.8,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'left',
      flex: 0.5,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'start',
      headerName: 'Start',
      headerAlign: 'left',
      flex: 1.2,
      headerClassName: 'bg-grayBackground',
    },

    {
      field: 'byName',
      headerName: 'Last Saved By',
      headerAlign: 'left',
      flex: 1.2,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'codes',
      headerName: 'Codes',
      headerAlign: 'left',
      flex: 1,
      headerClassName: 'bg-grayBackground',
      renderCell: (params: { row: EncounterRow }) => (
        <div className="flex flex-col overflow-x-scroll">
          <div className="flex flex-row">
            {params.row.codes.icdCodes.map((code: string) => (
              <div key={code} className="bg-grayLight rounded-[5px] px-1 mr-1">
                <Typography variant="bodySmall" text={code} />
              </div>
            ))}
          </div>
          <div className="flex flex-row mt-1">
            {params.row.codes.cptCodes.map((code: string) => (
              <div key={code} className="bg-pastelBlue rounded-[5px] px-1 mr-1">
                <Typography variant="bodySmall" text={code} />
              </div>
            ))}
          </div>
        </div>
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'left',
      flex: 2,
      headerClassName: 'bg-grayBackground',
      renderCell: (params: { row: EncounterRow }) => (
        <div className="flex gap-3">
          {vitals &&
          encounters &&
          patientProfile &&
          ![
            names('postpartum').sentenceCase,
            names('newborn').sentenceCase,
          ].includes(params.row.type) ? (
            <div
              onClick={async () => {
                const encounter = encounters?.filter(
                  (e) => e.id === params.row.id
                );
                await downloadEncountersPdf({
                  encounters: encounter,
                  patientProfile,
                  vitals,
                });
              }}
            >
              <Typography
                variant="body"
                text="Download"
                customClass="cursor-pointer underline"
              />
            </div>
          ) : (
            <div className="w-[24px] h-[24px]"></div>
          )}
          <div
            onClick={() => {
              setSelectedEncounterID(params.row.id);
              setSelectedCodes(params.row.codes);
              setCodeModalOpen(true);
            }}
          >
            <Typography
              variant="body"
              text="Codes"
              customClass="cursor-pointer underline"
            />
          </div>
          {patientProfile?.status !== 'archived' && (
            <div
              onClick={() => {
                setDeleteId(params.row.id);
                setOpenConfirmation(true);
              }}
            >
              <Typography
                variant="body"
                text="Delete"
                customClass="cursor-pointer underline text-red-500"
              />
            </div>
          )}
        </div>
      ),
    },
  ];

  const handleCellClick: GridEventListener<'cellClick'> = async (params) => {
    const { field, id } = params;
    if (field !== columns[columns.length - 1].field) {
      const found = rows.find((ele) => ele.id === id) || { id: '' };

      if (found.id) {
        await navigate(`/encounter/${patient}/${found.id}`);
      }
    }
  };

  if (areEncountersLoading) {
    return (
      <div className="flex flex-col justify-center items-center h-full w-full">
        <CircularProgress />
      </div>
    );
  }

  if (encounters && encounters?.length > 0) {
    return (
      <div className="flex flex-col h-full w-full overflow-auto pb-10">
        <div className="grid">
          <StyledDataGrid
            checkboxSelection={isSelectingEncounters}
            isRowSelectable={(param) =>
              ![
                names('postpartum').sentenceCase,
                names('newborn').sentenceCase,
              ].includes(param.row.type)
            }
            onRowSelectionModelChange={(newSelection) => {
              setSelectedEncounterIds(newSelection as string[]);
            }}
            rows={rows}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 25, 50]}
            columns={columns}
            autoHeight
            onCellClick={isSelectingEncounters ? undefined : handleCellClick}
            getRowClassName={() => 'cursor-pointer'}
            sx={{ overflow: 'auto', width: '100%' }}
          />
        </div>
        <ConfirmationModal
          open={openConfirmation}
          title="Delete Encounter"
          description={`Are you sure you want to delete this encounter?`}
          confirm="Delete"
          dismiss="Cancel"
          handleClose={() => setOpenConfirmation(false)}
          handleConfirm={handleDelete}
          handleCancel={() => setOpenConfirmation(false)}
        />
        <CodingModal
          readonly={isPatientReadonly(patientProfile)}
          encounterInfo={selectedEncounter}
          selectedCodes={selectedCodes}
          setSelectedCodes={handleChangeCodes}
          open={codeModalOpen}
          confirm={'Save'}
          dismiss={'Cancel'}
          handleClose={() => {
            setCodeModalOpen(false);
          }}
        />
      </div>
    );
  }

  if (encounters && encounters?.length === 0) {
    return (
      <div className="flex flex-col h-full justify-center items-center">
        <Typography
          variant="h3"
          text="You will find a list of your encounters here"
        />
        <Typography
          variant="h4"
          text="Generate notes for encounters, appointments and more."
          customClass="text-gray-500"
        />
      </div>
    );
  }
};

export default EncountersList;
