import { CircularSpinner } from '@aster/client/ui/CircularSpinner/CircularSpinner';
import { useMessageThreadDetailsQuery } from '../queries/use-message-thread-details.query';
import { PatientProfileHeader } from './PatientProfileHeader';
import { Typography } from '@aster/client/ui/Typography/Typography';
import { MessageList } from './MessageList';
import { MessageComposer, MessageComposerRef } from './MessageComposer';
import { PatientProfileForEncounterDTO } from '@aster/app/core/shared/dtos/patient';
import { useMessagesStore } from '../store/message.store';
import { useEffect, useRef } from 'react';
import { MessageThreadActions } from './MessageThreadActions';
import { MessageThreadDTO } from '@aster/app/message/shared/dtos';

type MessageThreadDetailsProps = {
  currentActiveSearchQueryKey: (string | number)[];
  currentArchivedSearchQueryKey: (string | number)[];
};
export const MessageThreadDetails = ({
  currentActiveSearchQueryKey,
  currentArchivedSearchQueryKey,
}: MessageThreadDetailsProps) => {
  const composerRef = useRef<MessageComposerRef>(null);
  const selectedMessageThreadID = useMessagesStore(
    (state) => state.selectedMessageThreadID
  );
  const { messageThread, isMessageThreadLoading } =
    useMessageThreadDetailsQuery(selectedMessageThreadID as string);
  const listRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    listRef.current?.scrollTo(0, listRef.current.scrollHeight);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messageThread?.messages]);

  if (isMessageThreadLoading) {
    return <CircularSpinner size="lg" className="mx-auto mt-12" />;
  }

  if (!messageThread) {
    return null;
  }

  return (
    <div className="flex flex-col overflow-hidden">
      <div className="flex flex-col lg:flex-row border-b-2 border-gray-200 px-4 pb-4">
        {messageThread.patientProfile && (
          <PatientProfileHeader
            patientProfile={messageThread?.patientProfile}
          />
        )}
        <MessageThreadActions
          currentActiveSearchQueryKey={currentActiveSearchQueryKey}
          currentArchivedSearchQueryKey={currentArchivedSearchQueryKey}
          messageThread={messageThread as MessageThreadDTO}
          onReply={() => composerRef.current?.focus()}
        />
      </div>
      <div ref={listRef} className="overflow-auto">
        <Typography variant="p-lg" className="mt-4 px-4">
          {messageThread?.subject}
        </Typography>
        <MessageList messages={messageThread.messages} />
        {messageThread.status === 'active' && (
          <MessageComposer
            ref={composerRef}
            patient={
              messageThread.patientProfile as PatientProfileForEncounterDTO
            }
          />
        )}
      </div>
    </div>
  );
};
