import { z } from 'zod';

import { FormControlLabel, InputLabel, Radio } from '@mui/material';
import { useForm, useStore } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';

import {
  PracticeDTO,
  PracticeType,
  PracticeTypeDisplayNames,
  UpdatePracticeInput,
} from '@aster/app/core/shared/dtos/practice';

import ButtonType from '../../components/Button';
import { PhoneNumberField } from '../../components/PhoneNumberField';
import { PictureField } from '../../components/PictureField';
import { StateDropdownField } from '../../components/StateDropdownField';
import BasicTextfield from '../../components/Textfield';

export type PracticeSettingsFormProps = {
  practiceSettings: PracticeDTO;
  isLoading?: boolean;
  isLogoLoading?: boolean;
  onFormSubmit: (practiceSettings: UpdatePracticeInput) => void;
  onLogoSubmit: (logo: File) => void;
};

export const PracticeSettingsForm = ({
  practiceSettings,
  isLoading = false,
  isLogoLoading = false,
  onFormSubmit,
  onLogoSubmit,
}: PracticeSettingsFormProps) => {
  const form = useForm({
    defaultValues: {
      id: practiceSettings.id,
      name: practiceSettings?.name ?? '',
      email: practiceSettings?.email ?? '',
      type: practiceSettings?.type ?? '',
      extraType: practiceSettings?.extraType ?? '',
      streetAddress: practiceSettings?.streetAddress ?? '',
      city: practiceSettings?.city ?? '',
      state: practiceSettings?.state ?? '',
      zipCode: practiceSettings?.zipCode ?? '',
      phoneNumber: practiceSettings?.phoneNumber ?? '',
    },
    validatorAdapter: zodValidator,
    onSubmit: ({ value }) => {
      if (!value.type) {
        return form.setErrorMap({
          onBlur: 'A valid practice type must be selected',
        });
      }

      if (!value.state) {
        return form.setErrorMap({
          onBlur: 'A state must be selected',
        });
      }

      const practiceSettings = {
        id: value.id,
        name: value.name,
        email: value.email,
        type: value.type,
        extraType: value.type === PracticeType.other ? value.extraType : null,
        streetAddress: value.streetAddress,
        city: value.city,
        state: value.state,
        zipCode: value.zipCode,
        phoneNumber: value.phoneNumber,
      };

      onFormSubmit(practiceSettings);
    },
  });
  const type = useStore(form.store, (store) => store.values.type);

  return (
    <form
      className="max-w-[400px] mx-auto text-left space-y-5"
      onSubmit={(evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        void form.handleSubmit();
      }}
    >
      <PictureField
        label="Logo"
        variant="squared"
        onDrop={onLogoSubmit}
        displayPictureUrl={practiceSettings.logoUrl}
        displayPictureAlt={practiceSettings.name}
        isLoading={isLogoLoading}
      />
      <form.Field
        name="name"
        validators={{
          onBlur: z.string().min(1, 'Practice name is required'),
        }}
        children={(field) => (
          <BasicTextfield
            variant="filled"
            label="Practice Name"
            onBlur={field.handleBlur}
            onChange={(evt: any) => field.handleChange(evt.target.value)}
            value={field.state.value}
            disabled={isLoading}
            error={field.state.meta.errors.length > 0}
            helperText={field.state.meta.errors.join('\r')}
            required
          />
        )}
      />
      <form.Field
        name="email"
        validators={{
          onBlur: z.string().email('A valid practice email is required'),
        }}
        children={(field) => (
          <BasicTextfield
            type="email"
            variant="filled"
            label="Practice Email"
            onBlur={field.handleBlur}
            onChange={(evt: any) => field.handleChange(evt.target.value)}
            value={field.state.value}
            disabled={isLoading}
            error={field.state.meta.errors.length > 0}
            helperText={field.state.meta.errors.join('\r')}
            required
          />
        )}
      />
      <div>
        <InputLabel className="text-gray-500 text-bodySmall w-full !block !mb-2">
          Practice Type
        </InputLabel>
        <form.Field
          name="type"
          validators={{
            onBlur: z
              .string()
              .refine(
                (arg) => Object.values(PracticeType).includes(arg),
                'A valid practice type must be selected'
              ),
          }}
          children={(field) => (
            <div onBlur={field.handleBlur}>
              {Object.entries(PracticeTypeDisplayNames).map(([key, value]) => (
                <div>
                  <FormControlLabel
                    className="h-8"
                    label={value}
                    control={
                      <Radio
                        checked={field.state.value === key}
                        onChange={() =>
                          field.handleChange(key as keyof typeof PracticeType)
                        }
                        disableRipple
                      />
                    }
                    disabled={isLoading}
                  />
                </div>
              ))}
              {field.state.meta.errors.length > 0 && (
                <p className="mx-[14px] mt-3px text-red-500 text-bodySmall">
                  {field.state.meta.errors.join('\r')}
                </p>
              )}
            </div>
          )}
        />
        {type === PracticeType.other && (
          <div className="pl-8 !mt-[4px]">
            <form.Field
              name="extraType"
              children={(field) => (
                <BasicTextfield
                  variant="filled"
                  label="Enter Practice Type"
                  value={field.state.value}
                  onChange={(evt) => field.handleChange(evt.target.value)}
                  disabled={isLoading}
                  required
                />
              )}
            />
          </div>
        )}
      </div>
      <form.Field
        name="streetAddress"
        validators={{
          onBlur: z.string().min(1, 'A valid address is required'),
        }}
        children={(field) => (
          <BasicTextfield
            variant="filled"
            label="Street Address"
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={(evt) => field.handleChange(evt.target.value)}
            disabled={isLoading}
            error={field.state.meta.errors.length > 0}
            helperText={field.state.meta.errors.join('\r')}
            required
          />
        )}
      />
      <form.Field
        name="city"
        validators={{
          onBlur: z.string().min(1, 'A valid city is required'),
        }}
        children={(field) => (
          <BasicTextfield
            variant="filled"
            label="City"
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={(evt) => field.handleChange(evt.target.value)}
            disabled={isLoading}
            error={field.state.meta.errors.length > 0}
            helperText={field.state.meta.errors.join('\r')}
            required
          />
        )}
      />
      <div className="flex gap-4">
        <form.Field
          name="state"
          validators={{
            onBlur: z.string().min(1, 'A valid state must be selected'),
          }}
          children={(field) => (
            <StateDropdownField
              label="State"
              classes="[&_.MuiSelect-select]:pb-[15px] [&_.MuiSelect-select]:pt-[20px]"
              value={field.state.value}
              onBlur={field.handleBlur}
              handleChange={(evt: any) => field.handleChange(evt.target.value)}
              disabled={isLoading}
              error={field.state.meta.errors.length > 0}
              helperText={field.state.meta.errors.join('\r')}
              required
            />
          )}
        />
        <form.Field
          name="zipCode"
          validators={{
            onBlur: z
              .string()
              .refine(
                (arg) => arg.match(/^[0-9]{5}(?:-[0-9]{4})?$/),
                'A valid zip code is required'
              ),
          }}
          children={(field) => (
            <BasicTextfield
              variant="filled"
              label="Zip Code"
              onBlur={field.handleBlur}
              value={field.state.value}
              onChange={(evt) => field.handleChange(evt.target.value)}
              disabled={isLoading}
              error={field.state.meta.errors.length > 0}
              helperText={field.state.meta.errors.join('\r')}
              required
            />
          )}
        />
      </div>
      <form.Field
        name="phoneNumber"
        validators={{
          onBlur: z.string().min(1, 'A valid phone number is required'),
        }}
        children={(field) => (
          <PhoneNumberField
            variant="filled"
            label="Phone Number"
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={(evt: any) => field.handleChange(evt.target.value)}
            placeholder="(xxx) xxx-xxxx"
            disabled={isLoading}
            error={field.state.meta.errors.length > 0}
            helperText={field.state.meta.errors.join('\r')}
            required
          />
        )}
      />
      <div className="text-right">
        <form.Subscribe
          children={(form) => (
            <ButtonType
              variant="contained"
              color="aster"
              text="Save"
              type="submit"
              className="px-5 py-3 bg-aster-main hover:bg-aster-main hover:bg-opacity-80"
              disabled={!form.canSubmit || isLoading}
              loading={isLoading}
            />
          )}
        />
      </div>
    </form>
  );
};
