import { useCallback, useEffect, useMemo, useState } from 'react';
import prescriptionsImage from '../../assets/prescription.webp';
import BasicCheckbox from '../../components/Checkbox';
import Typography from '../../components/Typography';
import ButtonType from '../../components/Button';
import DosespotWizard from './modals/DosespotWizard';
import { CircularProgress } from '@mui/material';
import { useDosespot } from './DosespotProvider';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { colors } from '../../theme';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { useAuth } from '../../authentication/AuthProvider';
import { isSubscriptionStarterPlan } from '../../integrations/stripe/util/is-subscription-starter-plan';
import {
  TooltipProvider,
  TooltipTrigger,
  Tooltip,
} from '@aster/client/ui/Tooltip/Tooltip';
import { Button } from '@aster/client/ui/Button/Button';
import { UpgradeRequiredTooltipContent } from '../../integrations/stripe/components/UpgradeRequiredTooltipContent';

const Prescriptions = ({
  dosespotPatientId,
}: {
  dosespotPatientId?: string;
}) => {
  const dosespotContext = useDosespot();

  const [checked, setChecked] = useState(false);
  const [openDosespotWizard, setOpenDosespotWizard] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const incrementStep = () => {
    setCurrentStep((prev) => {
      if (prev === 0) {
        if (
          dosespotContext.dosespotData.client_id &&
          dosespotContext.dosespotData.client_secret
        ) {
          return 2;
        }
      }
      if (prev === 3) {
        setOpenDosespotWizard(false);
      }
      return prev + 1;
    });
  };

  const waitingConfirmation = useMemo(() => {
    const { dosespotData } = dosespotContext;
    const isWaitingConfirmation = Boolean(
      dosespotData.client_id &&
        dosespotData.client_secret &&
        dosespotData.username &&
        dosespotData.password &&
        !dosespotData.clinicianConfirmed
    );
    return isWaitingConfirmation;
  }, [dosespotContext]);

  const checkSSO = useCallback(async () => {
    await dosespotContext.getDosespotSSO(
      dosespotContext.dosespotData,
      dosespotPatientId
    );
  }, [dosespotContext, dosespotPatientId]);

  useEffect(() => {
    void checkSSO();
  }, [dosespotPatientId, checkSSO]);

  const profile = useAuth().profile;

  const isStarterPlan = isSubscriptionStarterPlan(profile?.stripeProductID);

  return (
    <div className="full-w-container pt-0 flex-col h-full justify-center items-center">
      {dosespotContext.loading ? (
        <CircularProgress className="justify-center" />
      ) : (
        <>
          {dosespotContext.iframe.length > 0 ? (
            <>
              <div className="flex items-center my-4 self-end gap-x-2">
                {dosespotPatientId ? (
                  <div className="flex gap-2">
                    <ButtonType
                      icon={true}
                      specificIcon={
                        <FontAwesomeIcon icon={faUser} className="mr-1" />
                      }
                      variant={'outlined'}
                      className="rounded-[5px] text-bodySmall text-asterGray h-auto py-0 px-2 border-beige"
                      text={'Current Patient'}
                      onClick={checkSSO}
                    />
                    <ButtonType
                      icon={true}
                      specificIcon={
                        <FontAwesomeIcon
                          icon={faArrowUpRightFromSquare}
                          className="mr-1"
                        />
                      }
                      variant={'outlined'}
                      className="rounded-[5px] text-bodySmall text-asterGray h-auto py-0 px-2 border-beige"
                      text={'Open in new tab'}
                      onClick={() => {
                        window.open(dosespotContext.iframe, '_blank');
                      }}
                    />
                  </div>
                ) : (
                  <>
                    <Typography
                      variant="body"
                      customClass="font-semibold"
                      text="Manage Dosespot"
                    />
                    <ButtonType
                      icon={true}
                      specificIcon={
                        <FontAwesomeIcon icon={faPlus} className="mr-1" />
                      }
                      variant={'outlined'}
                      className="rounded-[5px] text-bodySmall text-asterGray h-auto py-0 px-2 border-beige"
                      text={'Sync Patients'}
                      onClick={() => {
                        setCurrentStep(3);
                        setOpenDosespotWizard(true);
                      }}
                    />
                    <ButtonType
                      icon={true}
                      specificIcon={
                        <FontAwesomeIcon
                          icon={faBell as FontAwesomeIconProps['icon']}
                          className="mr-1"
                        />
                      }
                      variant={'outlined'}
                      className="rounded-[5px] text-bodySmall text-asterGray h-auto py-0 px-2 border-beige"
                      text={'Notifications'}
                      onClick={() => checkSSO()}
                    />
                  </>
                )}
              </div>
              <iframe
                title="DoseSpot"
                name="dosepotIframe"
                id="dosespotIframe"
                src={dosespotContext.iframe}
                width="100%"
                height="100%"
              ></iframe>
            </>
          ) : (
            <div className="flex flex-col items-center text-center max-w-[550px]">
              <Typography variant="h1" text="Prescriptions" />
              <Typography
                customClass="mt-3 text-asterGray"
                variant="bodyMedium"
                text="Order medication and refill scripts powered by DoseSpot."
              />
              <img src={prescriptionsImage} alt="" className="my-8" />
              {waitingConfirmation ? (
                <>
                  <Typography
                    customClass="leading-1"
                    variant="h3"
                    text="Thank you"
                  />
                  <Typography
                    customClass="text-asterGray leading-1"
                    variant="bodyMedium"
                    text={`Your credentials are being confirmed by Dosespot, this may take 1-2 business days. \n If that time has elapsed please contact Aster support.`}
                  />
                </>
              ) : (
                <>
                  <div className="flex bg-pastelOrange py-2 px-12 rounded-lg bg-opacity-[25%]">
                    <Typography
                      customClass="text-asterGray leading-1"
                      variant="bodyMedium"
                      text="DoseSpot is only available to providers who are licensed to prescribe. Please do not proceed if this is not you."
                    />
                  </div>
                  <div
                    className="flex my-5 cursor-pointer"
                    onClick={() => setChecked(!checked)}
                  >
                    <BasicCheckbox
                      checked={checked}
                      onChange={() => undefined}
                      classes="mr-[-5px]"
                      sx={{
                        color: colors.grayLight,
                        '&.Mui-checked': {
                          color: colors.green,
                        },
                      }}
                    />
                    <Typography
                      customClass="text-asterGray leading-1"
                      variant="bodyMedium"
                      text="By proceeding you confirm you have the available credentials to prescribe medication to patients in the State you are operating in."
                    />
                  </div>
                </>
              )}
            </div>
          )}
          {dosespotContext.iframe.length === 0 && !waitingConfirmation && (
            <>
              <div className="flex gap-2 mt-4">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Button
                        className="w-max"
                        variant={'default'}
                        onClick={() => setOpenDosespotWizard(true)}
                        disabled={!checked || isStarterPlan}
                      >
                        Get started with Dosespot
                      </Button>
                    </TooltipTrigger>
                    {isStarterPlan && <UpgradeRequiredTooltipContent />}
                  </Tooltip>
                </TooltipProvider>
              </div>
            </>
          )}
          <DosespotWizard
            open={openDosespotWizard}
            step={currentStep}
            incrementStep={incrementStep}
            handleClose={() => setOpenDosespotWizard(false)}
          />
        </>
      )}
    </div>
  );
};

export default Prescriptions;
