'use client';

import * as React from 'react';
import * as TogglePrimitive from '@radix-ui/react-toggle';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@aster/client/utils';

const toggleVariants = cva(
  'text-sm font-medium text-gray-500 transition-colors focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none disabled:opacity-50  [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 dark:hover:bg-neutral-800 dark:hover:text-neutral-400 dark:focus-visible:ring-neutral-300 dark:data-[state=on]:bg-neutral-800 dark:data-[state=on]:text-neutral-50',
  {
    variants: {
      variant: {
        default:
          'inline-flex items-center justify-center gap-2 bg-transparent rounded-sm hover:bg-neutral-100 hover:text-neutral-500 focus-visible:ring-neutral-950 data-[state=on]:bg-gray-900 data-[state=on]:text-white',
        wrapper: 'data-[state=on]:bg-gray-100',
        outline:
          'inline-flex items-center justify-center gap-2 border border-neutral-200 bg-transparent shadow-sm hover:bg-neutral-100 hover:text-neutral-900 focus-visible:ring-neutral-950 data-[state=on]:bg-gray-900 data-[state=on]:text-white dark:border-neutral-800 dark:hover:bg-neutral-800 dark:hover:text-neutral-50',
      },
      size: {
        default: 'h-8 px-3',
        container: 'h-auto w-auto p-0',
        sm: 'h-8 px-1.5 min-w-8',
        lg: 'h-10 px-2.5 min-w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

const Toggle = React.forwardRef<
  React.ElementRef<typeof TogglePrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root> &
    VariantProps<typeof toggleVariants>
>(({ className, variant, size, ...props }, ref) => (
  <TogglePrimitive.Root
    ref={ref}
    className={cn(toggleVariants({ variant, size, className }))}
    {...props}
  />
));

Toggle.displayName = TogglePrimitive.Root.displayName;

export { Toggle, toggleVariants };
