import { datadogRum } from '@datadog/browser-rum';
import { createId } from '@paralleldrive/cuid2';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import axios from '../../../app/axiosConfig';

import { ExtendedError } from '../../../components/ErrorBoundary';
import { useSnackbar } from '../../../components/Snack';

import {
  createMutationScopeID,
  MutationScope,
} from '../../../mutations/utils/create-mutation-scope-id';
import {
  MessageDTO,
  MessageThreadDTO,
  SendMessageDTO,
} from '@aster/app/message/shared/dtos';
import { useAuth } from '../../../authentication/AuthProvider';
import dayjs from 'dayjs';

const sendMessage = async (messageToSend: SendMessageDTO) => {
  const { data: newMessage } = await axios.post<void>(
    `/v2/message/thread/${messageToSend.threadID}/message`,
    messageToSend
  );

  return newMessage;
};

export const useSendMessageMutation = ({
  messageThreadID,
  onSuccess,
  onError,
}: {
  messageThreadID: string;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}) => {
  const { showMessage } = useSnackbar();

  const messageQueryKey = ['messageThread', messageThreadID];
  const queryClient = useQueryClient();
  const { profile } = useAuth();

  const sendMessageMutation = useMutation({
    mutationKey: ['sendMessage'],
    mutationFn: sendMessage,
    scope: {
      id: createMutationScopeID(MutationScope.MESSAGE),
    },
    onMutate: async (message: SendMessageDTO) => {
      const previousMessageThread =
        queryClient.getQueryData<MessageThreadDTO>(messageQueryKey);

      queryClient.setQueryData<MessageThreadDTO>(messageQueryKey, (old) => {
        if (!old) {
          throw new Error('Message thread not found');
        }

        const newMessage: MessageDTO = {
          ...message,
          practiceID: old.practiceID,
          id: createId(), // temporary ID,
          senderName: `${profile?.firstName} ${profile?.lastName}`,
          senderID: profile?.id ?? '',
          sentAt: dayjs().toISOString(),
        };
        const updatedThread: MessageThreadDTO = {
          ...old,
          messages: [...(old?.messages ?? []), newMessage],
        };

        return updatedThread;
      });

      return { previousMessageThread };
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: messageQueryKey,
      });

      showMessage({
        type: 'success',
        message: 'Message successfully sent!',
      });

      onSuccess?.();
    },
    onError: (error, _, context) => {
      queryClient.setQueryData<MessageThreadDTO>(
        messageQueryKey,
        context?.previousMessageThread
      );

      const messageSendingError: ExtendedError = new Error(error.message);

      messageSendingError.name = `MessageSendingErrorError`;
      messageSendingError.stack = error.stack as string | undefined;
      messageSendingError.cause = error;

      datadogRum.addError(messageSendingError);

      showMessage({
        type: 'error',
        message:
          'An error occurred while sending the message. Please try again!',
      });

      onError?.(error);
    },
  });

  return sendMessageMutation;
};
