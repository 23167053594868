import { PatientSortBy } from '@aster/app/core/shared/dtos/patient';
import { useSearchPatientsQuery } from '../queries/search-patients.query';
import { usePatientFilters } from './usePatientFilters';
import useDataGridSearchQueryArgs from '../../../hooks/useDataGridSearchQueryArgs';

export function usePatientSearch(useStoredPreferences = false) {
  const { filterState, updateFilter, serverFilters, clearFilters } =
    usePatientFilters(undefined, useStoredPreferences);

  const {
    paginationModel,
    setPaginationModel,
    searchQuery,
    search,
    dataGridSortModel,
    sortModel,
    setDataGridSortModel,
  } = useDataGridSearchQueryArgs('patient', {}, useStoredPreferences);

  const { arePatientsLoading, paginatedPatients, arePatientsFetching } =
    useSearchPatientsQuery({
      search: searchQuery,
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      sortBy: sortModel?.sortBy as PatientSortBy,
      sortDir: sortModel?.sortDir,
      statusFilter: serverFilters.statusFilter,
      startDueDate: serverFilters.dueDateRange?.start,
      endDueDate: serverFilters.dueDateRange?.end,
      gestationalAgeFilter: serverFilters.gestationalAgeFilter,
    });

  const patchedUpdateFilter: typeof updateFilter = (
    category,
    value,
    isChecked
  ) => {
    updateFilter(category, value, isChecked);
    setPaginationModel({ page: 0 });
  };

  const patchedClearFilters = () => {
    clearFilters();
    setPaginationModel({ page: 0 });
  };

  return {
    paginatedPatients,
    arePatientsLoading,
    arePatientsFetching,
    paginationModel,
    dataGridSortModel,
    search,
    searchQuery,
    filterState,
    updateFilter: patchedUpdateFilter,
    clearFilters: patchedClearFilters,
    setPaginationModel,
    setDataGridSortModel,
  };
}
