import { datadogRum } from '@datadog/browser-rum';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import axios from '../../../app/axiosConfig';

import { ExtendedError } from '../../../components/ErrorBoundary';
import { useSnackbar } from '../../../components/Snack';

import {
  createMutationScopeID,
  MutationScope,
} from '../../../mutations/utils/create-mutation-scope-id';
import {
  MessageThreadInfoDTO,
  MessageThreadDTO,
  MessageThreadListItemDTO,
} from '@aster/app/message/shared/dtos';
import { PaginatedResult } from '@aster/app/core/shared/dtos/search';

const unarchiveMessageThread = async (threadTounarchiveID: string) => {
  const { data: newThread } = await axios.patch<MessageThreadInfoDTO>(
    `/v2/message/thread/${threadTounarchiveID}/unarchive`
  );

  return newThread;
};

export const useUnarchiveMessageThreadMutation = ({
  messageThreadID,
  currentActiveSearchQueryKey,
  currentArchivedSearchQueryKey,
  onSuccess,
  onError,
}: {
  messageThreadID: string;
  currentActiveSearchQueryKey: (string | number)[];
  currentArchivedSearchQueryKey: (string | number)[];
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}) => {
  const { showMessage } = useSnackbar();
  const queryClient = useQueryClient();

  const messageThreadQueryKey = ['messageThread', messageThreadID];

  const unarchiveMessageThreadMutation = useMutation({
    mutationKey: ['unarchiveMessageThread'],
    mutationFn: unarchiveMessageThread,
    scope: {
      id: createMutationScopeID(MutationScope.MESSAGE),
    },
    onMutate: async (messageThreadID: string) => {
      const previousMessageThread = queryClient.getQueryData<MessageThreadDTO>(
        messageThreadQueryKey
      );
      const previousActiveMessageThreads = queryClient.getQueryData<
        PaginatedResult<MessageThreadListItemDTO>
      >(currentActiveSearchQueryKey);
      const previousArchivedMessageThreads = queryClient.getQueryData<
        PaginatedResult<MessageThreadListItemDTO>
      >(currentArchivedSearchQueryKey);

      if (previousMessageThread) {
        queryClient.setQueryData<MessageThreadDTO>(
          messageThreadQueryKey,
          (old) => old && { ...old, status: 'active' }
        );
      }

      // move the item on the cache from one list to the other
      const itemToUnarchive = previousArchivedMessageThreads?.items.find(
        (thread) => thread.id === messageThreadID
      );
      if (
        previousActiveMessageThreads &&
        previousArchivedMessageThreads &&
        itemToUnarchive
      ) {
        queryClient.setQueryData<PaginatedResult<MessageThreadListItemDTO>>(
          currentArchivedSearchQueryKey,
          (old) =>
            old && {
              ...old,
              queryMetadata: {
                ...old.queryMetadata,
                rowCount: old.queryMetadata.rowCount - 1,
              },
              items: old.items.filter(
                (thread) => thread.id !== messageThreadID
              ),
            }
        );
        queryClient.setQueryData<PaginatedResult<MessageThreadListItemDTO>>(
          currentActiveSearchQueryKey,
          (old) =>
            old && {
              ...old,
              queryMetadata: {
                ...old.queryMetadata,
                rowCount: old.queryMetadata.rowCount + 1,
              },
              items: old.items.concat([
                {
                  ...itemToUnarchive,
                  status: 'active',
                },
              ]),
            }
        );
      }

      return {
        previousMessageThread,
        previousArchivedMessageThreads,
        previousActiveMessageThreads,
      };
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: messageThreadQueryKey,
      });
      void queryClient.invalidateQueries({
        queryKey: currentActiveSearchQueryKey,
      });
      void queryClient.invalidateQueries({
        queryKey: currentArchivedSearchQueryKey,
      });

      showMessage({
        type: 'success',
        message: 'Message thread successfully unarchived!',
      });

      onSuccess?.();
    },
    onError: (error, _, context) => {
      queryClient.setQueryData<MessageThreadDTO>(
        messageThreadQueryKey,
        context?.previousMessageThread
      );
      queryClient.setQueryData<PaginatedResult<MessageThreadListItemDTO>>(
        currentActiveSearchQueryKey,
        context?.previousActiveMessageThreads
      );
      queryClient.setQueryData<PaginatedResult<MessageThreadListItemDTO>>(
        currentArchivedSearchQueryKey,
        context?.previousArchivedMessageThreads
      );

      const messageUnarchivingError: ExtendedError = new Error(error.message);
      messageUnarchivingError.name = `MessageUnarchivingError`;
      messageUnarchivingError.stack = error.stack as string | undefined;
      messageUnarchivingError.cause = error;

      datadogRum.addError(messageUnarchivingError);

      showMessage({
        type: 'error',
        message:
          'An error occurred while unarchiving the message. Please try again!',
      });

      onError?.(error);
    },
  });

  return unarchiveMessageThreadMutation;
};
