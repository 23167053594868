import { MessageDTO } from '@aster/app/message/shared/dtos';
import { Typography } from '@aster/client/ui/Typography/Typography';
import { cn } from '@aster/client/utils/cn';
import { sanitizeHTML } from '@aster/shared/shared/client/utils';
import CircleWithInitials from '../../../components/CircleWIthInitials';
import dayjs from 'dayjs';

type MessageCardProps = {
  message: MessageDTO;
  messageDirection: 'sent' | 'received';
};

function formatDate(date: string | null) {
  const dateToFormat = dayjs(date);

  if (!date || !dateToFormat.isValid()) {
    return 'Invalid date';
  }

  const now = dayjs();

  if (dateToFormat.isSame(now, 'day')) {
    return `Today at ${dateToFormat.format('h:mm A')}`;
  } else if (dateToFormat.isSame(now, 'year')) {
    return `${dateToFormat.format('MMM D')} at ${dateToFormat.format(
      'h:mm A'
    )}`;
  } else {
    return `${dateToFormat.format('MMM D, YYYY')} at ${dateToFormat.format(
      'h:mm A'
    )}`;
  }
}

export function MessageCard({ message, messageDirection }: MessageCardProps) {
  return (
    <li className="flex flex-col border-b border-gray-200 mt-4">
      <div
        className={cn('flex gap-4', {
          'flex-row-reverse': messageDirection === 'sent',
        })}
      >
        <div className="flex flex-col flex-1">
          <article
            dangerouslySetInnerHTML={sanitizeHTML(message.content)}
            className={cn('bg-white p-4 py-4 rounded-2xl html-renderer', {
              'rounded-tl-none': messageDirection === 'sent',
              'rounded-tr-none': messageDirection === 'received',
            })}
          ></article>
          <Typography
            variant="p-sm"
            className={cn('text-gray-500 py-4', {
              'ml-auto': messageDirection === 'sent',
            })}
          >
            {formatDate(message.sentAt)}
          </Typography>
        </div>
        <CircleWithInitials
          name={message.senderName}
          height={50}
          width={50}
          className="mix-blend-normal"
        />
      </div>
    </li>
  );
}
