import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { Link, useParams } from 'react-router';

import { Edit } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import {
  LedgerRecordDTO,
  PaymentType,
  PaymentTypeDisplayNames,
  RecordType,
} from '@aster/app/core/shared/dtos/billing';

import { Amount } from './Amount';

import ButtonType from '../../components/Button';
import StyledDataGrid from '../../components/StyledDataGrid';
import { usePatientProfileQuery } from '../notes/queries/patient-profile.query';
import { isPatientReadonly } from '../patients/utils/is-patient-readonly';

export type LedgerRecordsProps = {
  records: LedgerRecordDTO[];
  showPatientColumn?: boolean;
  onAdjustmentClick?: (recordId: LedgerRecordDTO['id']) => void;
};

const isNumber = (value: unknown): value is number => typeof value === 'number';

export const LedgerRecords = ({
  records,
  showPatientColumn = false,
  onAdjustmentClick,
}: LedgerRecordsProps) => {
  const { patient } = useParams();

  const { patientProfile } = usePatientProfileQuery(patient);

  const renderAdjustment = useCallback(
    (id: LedgerRecordDTO['id'], adjustment: number) => {
      if (adjustment === 0) {
        return (
          <ButtonType
            variant="outlined"
            text={`Add adjustment`}
            onClick={() => onAdjustmentClick?.(id)}
            className="relative z-10"
            notRounded
            disabled={isPatientReadonly(patientProfile)}
          />
        );
      }
      return (
        <div className="flex align-middle justify-between">
          <Amount value={adjustment} type="adjustment" />
          <IconButton
            onClick={() => onAdjustmentClick?.(id)}
            aria-label="Edit adjustment"
            disabled={isPatientReadonly(patientProfile)}
          >
            <Edit />
          </IconButton>
        </div>
      );
    },
    [onAdjustmentClick, patientProfile]
  );

  const renderPaymentType = useCallback((record: LedgerRecordDTO) => {
    if (record.recordType === RecordType.charge) return '';

    const type = PaymentTypeDisplayNames[record.paymentType];

    if (record.paymentType === PaymentType.other) {
      return `${type} (${record.extra ? record.extra : 'unspecified'})`;
    }

    return type;
  }, []);

  const renderPatientName = (firstName = '', lastName = '') =>
    [firstName, lastName].join(' ');

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'date',
        headerName: 'Date',
        flex: 0.6,
        headerClassName: 'bg-grayBackground',
        valueFormatter: (params) => dayjs(params.value).format('MM/DD/YYYY'),
      },
      ...(showPatientColumn
        ? [
            {
              field: 'patient',
              headerName: 'Patient',
              flex: 0.6,
              headerClassName: 'bg-grayBackground',
              valueGetter: (params: any) =>
                renderPatientName(
                  params?.row?.patientMetadata?.firstName,
                  params?.row?.patientMetadata?.lastName
                ),
              renderCell: (params: any) => (
                <Link to={`/patientProfile/${params.row.patientMetadata.id}`}>
                  {params.value}
                </Link>
              ),
              sortable: false,
            },
          ]
        : []),
      {
        field: 'paymentType',
        headerName: 'Payment Type',
        flex: 0.6,
        headerClassName: 'bg-grayBackground',
        valueGetter: (params) => renderPaymentType(params.row),
        sortable: false,
      },
      {
        field: 'note',
        headerName: 'Note',
        flex: 0.6,
        headerClassName: 'bg-grayBackground',
        sortable: false,
      },
      {
        field: 'charge',
        headerName: 'Charge',
        flex: 0.75,
        align: 'left',
        headerAlign: 'left',
        headerClassName: 'bg-grayBackground',
        sortable: false,
        valueGetter: (params) =>
          params.row.recordType === 'charge' ? params.row.amount : '',
        renderCell: (params) =>
          params.value ? <Amount value={params.value} type="charge" /> : '',
      },
      {
        field: 'payment',
        headerName: 'Payment',
        flex: 0.75,
        align: 'left',
        headerAlign: 'left',
        headerClassName: 'bg-grayBackground',
        sortable: false,
        valueGetter: (params) =>
          params.row.recordType === 'payment' ? params.row.amount : '',
        renderCell: (params) =>
          params.value ? <Amount value={params.value} type="payment" /> : '',
      },
      {
        field: 'adjustment',
        headerName: 'Adjustment',
        flex: 0.75,
        align: 'left',
        headerAlign: 'left',
        headerClassName: 'bg-grayBackground',
        sortable: false,
        renderCell: (params) =>
          renderAdjustment(params.id as string, params.value),
      },
    ],
    [renderAdjustment, renderPaymentType, showPatientColumn]
  );

  // @ts-expect-error this table is for display-only purposes
  const resultColumns: GridColDef[] = useMemo(
    () => [
      { flex: 0.6 },
      { flex: 0.6 },
      ...(showPatientColumn ? [{ flex: 0.6 }] : []),
      {
        field: 'name',
        flex: 0.6,
        renderCell: (params) => (
          <Typography className="font-bold">{params.value}</Typography>
        ),
      },
      {
        field: 'charge',
        flex: 0.75,
        renderCell: (params) => {
          const isOwed = isNumber(params.row.owed);

          if (isOwed) {
            return <Amount value={params.row.owed} type="owed" />;
          }

          return isNumber(params.value) ? (
            <Amount value={params.value} type="charge" />
          ) : (
            ''
          );
        },
      },
      {
        field: 'payment',
        flex: 0.75,
        renderCell: (params) =>
          isNumber(params.value) ? (
            <Amount value={params.value} type="payment" />
          ) : (
            ''
          ),
      },
      {
        field: 'adjustment',
        flex: 0.75,
        align: 'left',
        renderCell: (params) =>
          isNumber(params.value) ? (
            <Amount value={params.value} type="adjustment" />
          ) : (
            ''
          ),
      },
    ],
    [showPatientColumn]
  );

  // TODO: Should we make these calculations server-side?
  const totalCharges = useMemo(
    () =>
      records
        .filter((record) => record.recordType === 'charge')
        .reduce((acc, row) => acc + row.amount, 0),
    [records]
  );
  const totalPayments = useMemo(
    () =>
      records
        .filter((record) => record.recordType === 'payment')
        .reduce((acc, row) => acc + row.amount, 0),
    [records]
  );
  const totalAdjustments = useMemo(
    () => records.reduce((acc, row) => acc + row.adjustment, 0),
    [records]
  );

  const balanceOwed = useMemo(
    () =>
      records
        .filter((record) => record.recordType === 'charge')
        .reduce((acc, row) => acc + row.amount + row.adjustment, 0) -
      records
        .filter((record) => record.recordType === 'payment')
        .reduce((acc, row) => acc + row.amount + row.adjustment, 0),
    [records]
  );

  const resultRecords = useMemo(
    () => [
      { id: 1, name: 'Total', charge: totalCharges, payment: totalPayments },
      { id: 2, name: 'Adjustments', adjustment: totalAdjustments },
      { id: 3, name: 'Total balance owed', owed: balanceOwed },
    ],
    [balanceOwed, totalAdjustments, totalCharges, totalPayments]
  );

  return (
    <div className="w-full overflow-x-scroll">
      <div className="min-w-[1200px]">
        <StyledDataGrid
          rows={records}
          columns={columns}
          rowCount={records.length - 1}
          getRowId={(row) => row.id}
          className="[&_.MuiDataGrid-columnHeaderDraggableContainer]:bg-grayBackground [&_.MuiDataGrid-footerContainer]:hidden mb-8"
          autoHeight
          checkboxSelection
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          showCellVerticalBorder
        />
        <StyledDataGrid
          rows={resultRecords}
          columns={resultColumns}
          rowCount={resultRecords.length - 1}
          className="[&_.MuiDataGrid-footerContainer]:hidden pl-[50px] [&_.MuiDataGrid-columnHeaders]:hidden [&_.MuiDataGrid-row]:!bg-transparent"
          autoHeight
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          showCellVerticalBorder
        />
      </div>
    </div>
  );
};
