import { StateCreator } from 'zustand';

type RootMessagesState = {
  selectedMessageThreadID: string | null;
  currentTabValue: 'active' | 'archived';
  isNewMessageThreadComposerOpen: boolean;
};

type RootMessagesAction = {
  selectMessageThreadID: (messageThreadID: string) => void;
  clearMessageThreadID: () => void;
  openNewMessageThreadComposer: () => void;
  closeNewMessageThreadComposer: () => void;
  setCurrentTabValue: (tabValue: 'active' | 'archived') => void;
};

export type RootMessagesSlice = RootMessagesState & RootMessagesAction;

export const createRootMessagesSlice: StateCreator<
  RootMessagesSlice,
  [],
  [],
  RootMessagesSlice
> = (set) => ({
  selectedMessageThreadID: null,
  isNewMessageThreadComposerOpen: false,
  currentTabValue: 'active',
  selectMessageThreadID: (messageThreadID) => {
    set({
      selectedMessageThreadID: messageThreadID,
      isNewMessageThreadComposerOpen: false,
    });
  },
  clearMessageThreadID: () => {
    set({ selectedMessageThreadID: null });
  },
  openNewMessageThreadComposer: () => {
    set({
      isNewMessageThreadComposerOpen: true,
    });
  },
  closeNewMessageThreadComposer: () => {
    set({
      isNewMessageThreadComposerOpen: false,
    });
  },
  setCurrentTabValue: (newStatus) => {
    set({
      currentTabValue: newStatus,
      selectedMessageThreadID: null,
      isNewMessageThreadComposerOpen: false,
    });
  },
});
