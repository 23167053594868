import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import dayjs from 'dayjs';
import { DosespotPatient } from '../../patients/types/patient-profile';
import { PatientInfoDTO } from '@aster/app/core/shared/dtos/patient';

export type PersonalInfoFields = Pick<
  PatientInfoDTO,
  | 'firstName'
  | 'lastName'
  | 'dateOfBirth'
  | 'legalSex'
  | 'streetAddress1'
  | 'city'
  | 'state'
  | 'zipCode'
  | 'phoneNumber'
>;

export const useAddDosespotPatientForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues: DosespotPatient | undefined;
  onSubmit: (value: Partial<DosespotPatient>) => void;
}) => {
  const personalInfoForm = useForm({
    defaultValues: {
      firstName: defaultValues?.firstName ?? undefined,
      lastName: defaultValues?.lastName ?? undefined,
      dateOfBirth: defaultValues?.dateOfBirth
        ? dayjs(defaultValues.dateOfBirth).format('MM/DD/YYYY')
        : '',
      legalSex: defaultValues?.legalSex ?? undefined,
      streetAddress1: defaultValues?.streetAddress1 ?? undefined,
      city: defaultValues?.city ?? undefined,
      state: defaultValues?.state ?? undefined,
      zipCode: defaultValues?.zipCode ?? undefined,
      phoneNumber: defaultValues?.phoneNumber ?? undefined,
      weightUnit: defaultValues?.weightUnit ?? undefined,
      weight: defaultValues?.weight ?? undefined,
      heightUnit: defaultValues?.heightUnit ?? undefined,
      height: defaultValues?.height ?? undefined,
      feet: defaultValues?.feet ?? undefined,
      inches: defaultValues?.inches ?? undefined,
    } as DosespotPatient,
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      value.dateOfBirth = value.dateOfBirth === '' ? null : value.dateOfBirth;
      onSubmit(value);
    },
  });

  return personalInfoForm;
};

export default useAddDosespotPatientForm;
