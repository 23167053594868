import { MessageDTO } from '@aster/app/message/shared/dtos';
import { MessageCard } from './MessageCard';
import { useAuth } from '../../../authentication/AuthProvider';

type MessageListProps = { messages: MessageDTO[] };

export const MessageList = ({ messages }: MessageListProps) => {
  const { profile } = useAuth();

  return (
    <ul className="mx-4 mt-4 flex-1 pr-4">
      {messages.map((message) => (
        <MessageCard
          key={message.id}
          message={message}
          messageDirection={
            profile?.id === message.senderID ? 'sent' : 'received'
          }
        ></MessageCard>
      ))}
    </ul>
  );
};
