import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useObstetricHistoryInfoForm } from '../../hooks/useObstetricHistoryInfoForm';
import Typography from '../../../../components/Typography';
import { Button } from '@aster/client/ui/Button/Button';
import { FormControl } from '@aster/client/ui/FormControl/FormControl';
import { Label } from '@aster/client/ui/Label/Label';
import {
  RadioGroup,
  RadioIndicator,
  RadioItem,
} from '@aster/client/ui/Radio/Radio';
import { Input } from '@aster/client/ui/Input/Input';
import {
  defaultOtherPregnancyOutcomes,
  defaultPregnanancyLocationOfDelivery,
  defaultPregnancyInfo,
  defaultPregnancyOutcomes,
} from '../../defaults';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { InputMask } from '@react-input/mask';
import { FormError } from '@aster/client/ui/FormControl/FormError';
import { Checkbox } from '@aster/client/ui/Checkbox/Checkbox';
import { Fragment } from 'react/jsx-runtime';
import Child from './Child';
import { FieldApi, Validator } from '@tanstack/react-form';
import { ZodType } from 'zod';
import { PregnancyInfoDTO } from '@aster/app/core/shared/dtos/patient';

// @ts-expect-error this matches TanStack's form inner types
type Pregnancy = PatientInfoDTO['pregnancies'][number];

export type PregnancyField = FieldApi<
  Pregnancy,
  'pregnancies',
  undefined,
  Validator<Pregnancy, ZodType>,
  Pregnancy
>;

type FieldValueType = ReturnType<
  typeof useObstetricHistoryInfoForm
>['state']['values']['pregnancies'][number];

const Pregnancy = ({
  index,
  field,
  fieldValue,
  obstetricHistoryInfoForm,
  readonly,
}: {
  index: number;
  field: PregnancyField;
  fieldValue: FieldValueType;
  obstetricHistoryInfoForm: ReturnType<typeof useObstetricHistoryInfoForm>;
  readonly: boolean;
}) => {
  return (
    <div className="flex flex-col p-6 gap-y-6 border border-gray-300 rounded-[8px]">
      <div className="flex justify-between">
        <Typography variant={'h6'} customClass="text-black">
          Pregnancy {index + 1}
        </Typography>
        <Button
          type="button"
          variant="secondary"
          size="default"
          onClick={async () => {
            await field.removeValue(index);
            void obstetricHistoryInfoForm.handleSubmit();
          }}
          disabled={readonly}
        >
          <FontAwesomeIcon icon={faTrash} />
          Remove
        </Button>
      </div>
      <obstetricHistoryInfoForm.Field
        name={`pregnancies[${index}].pregnancy.pregnancyOutcome`}
      >
        {(subField) => (
          <FormControl>
            <Label className="mb-3">
              What was the outcome of this pregnancy?
            </Label>
            <RadioGroup
              className="flex flex-col items-start gap-3"
              value={
                subField.state.value as PregnancyInfoDTO['pregnancyOutcome']
              }
              onValueChange={(value) => {
                subField.handleChange(
                  value as PregnancyInfoDTO['pregnancyOutcome']
                );
                void obstetricHistoryInfoForm.handleSubmit();
              }}
            >
              {defaultPregnancyOutcomes.map((outcome) => (
                <div key={outcome.value} className="flex gap-2 items-center">
                  <RadioItem
                    className="shrink-0"
                    id={`${subField.name}-${outcome.value}`}
                    value={outcome.value}
                    disabled={readonly}
                  >
                    <RadioIndicator />
                  </RadioItem>
                  <Label
                    htmlFor={`${subField.name}-${outcome.value}`}
                    className="m-0 cursor-pointer text-aster-secondary font-normal"
                  >
                    {outcome.text}
                  </Label>
                </div>
              ))}
            </RadioGroup>
          </FormControl>
        )}
      </obstetricHistoryInfoForm.Field>
      <div className="flex flex-col">
        <Label className="mb-2">Labor: First stage duration</Label>
        <div className="flex gap-4">
          <obstetricHistoryInfoForm.Field
            name={`pregnancies[${index}].pregnancy.hoursFirstStageLabor`}
          >
            {(subField) => (
              <FormControl>
                <Input
                  placeholder="Hours"
                  type="number"
                  value={subField.state.value === 0 ? '' : subField.state.value}
                  onChange={(event) => {
                    const numberValue = Number(event.target.value);
                    if (numberValue > -1) {
                      subField.handleChange(numberValue);
                    }
                  }}
                  onBlur={() => {
                    if (subField.state.meta.isDirty) {
                      void obstetricHistoryInfoForm.handleSubmit();
                    }
                  }}
                  disabled={readonly}
                />
              </FormControl>
            )}
          </obstetricHistoryInfoForm.Field>
          <obstetricHistoryInfoForm.Field
            name={`pregnancies[${index}].pregnancy.minutesFirstStageLabor`}
          >
            {(subField) => (
              <FormControl>
                <Input
                  placeholder="Minutes"
                  type="number"
                  value={subField.state.value === 0 ? '' : subField.state.value}
                  onChange={(event) => {
                    const numberValue = Number(event.target.value);
                    if (numberValue > -1 && numberValue < 60) {
                      subField.handleChange(numberValue);
                    }
                  }}
                  onBlur={() => {
                    if (subField.state.meta.isDirty) {
                      void obstetricHistoryInfoForm.handleSubmit();
                    }
                  }}
                  disabled={readonly}
                />
              </FormControl>
            )}
          </obstetricHistoryInfoForm.Field>
        </div>
      </div>

      <div className="flex flex-col">
        <Label className="mb-2">Labor: Second stage duration</Label>
        <div className="flex gap-4">
          <obstetricHistoryInfoForm.Field
            name={`pregnancies[${index}].pregnancy.hoursSecondStageLabor`}
          >
            {(subField) => (
              <FormControl>
                <Input
                  placeholder="Hours"
                  type="number"
                  value={subField.state.value === 0 ? '' : subField.state.value}
                  onChange={(event) => {
                    const numberValue = Number(event.target.value);
                    if (numberValue > -1) {
                      subField.handleChange(numberValue);
                    }
                  }}
                  onBlur={() => {
                    if (subField.state.meta.isDirty) {
                      void obstetricHistoryInfoForm.handleSubmit();
                    }
                  }}
                  disabled={readonly}
                />
              </FormControl>
            )}
          </obstetricHistoryInfoForm.Field>
          <obstetricHistoryInfoForm.Field
            name={`pregnancies[${index}].pregnancy.minutesSecondStageLabor`}
          >
            {(subField) => (
              <FormControl>
                <Input
                  placeholder="Minutes"
                  type="number"
                  value={subField.state.value === 0 ? '' : subField.state.value}
                  onChange={(event) => {
                    const numberValue = Number(event.target.value);
                    if (numberValue > -1 && numberValue < 60) {
                      subField.handleChange(numberValue);
                    }
                  }}
                  onBlur={() => {
                    if (subField.state.meta.isDirty) {
                      void obstetricHistoryInfoForm.handleSubmit();
                    }
                  }}
                  disabled={readonly}
                />
              </FormControl>
            )}
          </obstetricHistoryInfoForm.Field>
        </div>
      </div>
      <obstetricHistoryInfoForm.Field
        name={`pregnancies[${index}].pregnancy.dateOfBirthOrEndOfPregnancy`}
        validators={{
          onBlur: ({ value }: { value: string }) => {
            if (!value) return null;
            if (value.length > 1 && !dayjs(value).isValid()) {
              return 'Invalid date';
            }
            return null;
          },
        }}
      >
        {(subField) => (
          <FormControl>
            <Label className="mb-2">Date of birth or end of pregnancy</Label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <InputMask
                component={Input}
                name={subField.name}
                mask="MM/DD/YYYY"
                separate
                showMask
                replacement={{
                  D: /\d/,
                  M: /\d/,
                  Y: /\d/,
                }}
                value={subField.state.value as string}
                onChange={(event) => {
                  const value =
                    event.target.value === 'MM/DD/YYYY'
                      ? ''
                      : event.target.value;
                  subField.handleChange(value ?? undefined);
                }}
                onBlur={(evt) => {
                  subField.handleBlur();
                  if (
                    (dayjs(evt.target.value).isValid() ||
                      evt.target.value === '') &&
                    subField.state.meta.isDirty
                  ) {
                    void obstetricHistoryInfoForm.handleSubmit();
                  }
                }}
                disabled={readonly}
              />
            </LocalizationProvider>
            <FormError id={`err-${subField.name}`}>
              {subField.state.meta.errors?.join('\r')}
            </FormError>
          </FormControl>
        )}
      </obstetricHistoryInfoForm.Field>
      <obstetricHistoryInfoForm.Field
        name={`pregnancies[${index}].pregnancy.amountOfGestationWeeks`}
      >
        {(subField) => (
          <FormControl>
            <Label className="mb-2">How many weeks in gestation?</Label>
            <Input
              placeholder="Weeks"
              type="number"
              value={subField.state.value === 0 ? '' : subField.state.value}
              onChange={(event) => {
                const numberValue = Number(event.target.value);
                if (numberValue > -1 && numberValue <= 52) {
                  subField.handleChange(numberValue);
                }
              }}
              onBlur={() => {
                if (subField.state.meta.isDirty) {
                  void obstetricHistoryInfoForm.handleSubmit();
                }
              }}
              disabled={readonly}
            />
          </FormControl>
        )}
      </obstetricHistoryInfoForm.Field>
      <obstetricHistoryInfoForm.Field
        name={`pregnancies[${index}].pregnancy.otherPregnancyOutcomes`}
      >
        {(subField) => (
          <FormControl>
            <Label className="mb-3">
              During this pregnancy or delivery did you experience any of the
              following?
            </Label>
            <div className="flex flex-wrap gap-2">
              <div className="flex flex-col gap-2">
                {defaultOtherPregnancyOutcomes.slice(0, 10).map((outcome) => (
                  <div key={outcome.value} className="flex gap-2 relative">
                    <Checkbox
                      id={`${subField.name}.${outcome.value}`}
                      key={outcome.value}
                      checked={
                        subField.state.value?.find(
                          (item) => item.value === outcome.value
                        )?.checked
                      }
                      onCheckedChange={(value) => {
                        if (value === 'indeterminate') return;
                        const updatedItems = subField.state.value?.map((item) =>
                          item.value === outcome.value
                            ? {
                                ...item,
                                checked: value,
                              }
                            : item
                        );
                        if (updatedItems) {
                          subField.handleChange(updatedItems);
                          void obstetricHistoryInfoForm.handleSubmit();
                        }
                      }}
                      disabled={readonly}
                    />
                    <Label
                      htmlFor={`${subField.name}.${outcome.value}`}
                      className="cursor-pointe text-aster-secondary font-normal"
                    >
                      {outcome.text}
                    </Label>
                  </div>
                ))}
              </div>
              <div className="flex flex-col gap-2">
                {defaultOtherPregnancyOutcomes.slice(10).map((outcome) => (
                  <div key={outcome.value} className="flex gap-2 relative">
                    <Checkbox
                      id={`${subField.name}.${outcome.value}`}
                      key={outcome.value}
                      checked={
                        subField.state.value?.find(
                          (item) => item.value === outcome.value
                        )?.checked
                      }
                      onCheckedChange={(value) => {
                        if (value === 'indeterminate') return;
                        const updatedItems = subField.state.value?.map((item) =>
                          item.value === outcome.value
                            ? {
                                ...item,
                                checked: value,
                              }
                            : item
                        );
                        if (updatedItems) {
                          subField.handleChange(updatedItems);
                          void obstetricHistoryInfoForm.handleSubmit();
                        }
                      }}
                      disabled={readonly}
                    />
                    <Label
                      htmlFor={`${subField.name}.${outcome.value}`}
                      className="cursor-pointer text-aster-secondary font-normal"
                    >
                      {outcome.text}
                    </Label>
                  </div>
                ))}
              </div>
            </div>
          </FormControl>
        )}
      </obstetricHistoryInfoForm.Field>
      <obstetricHistoryInfoForm.Field
        name={`pregnancies[${index}].pregnancy.complications`}
      >
        {(subField) => (
          <FormControl>
            <Input
              placeholder="Other complications"
              value={subField.state.value}
              onChange={(event) => subField.handleChange(event.target.value)}
              onBlur={() => {
                if (subField.state.meta.isDirty) {
                  void obstetricHistoryInfoForm.handleSubmit();
                }
              }}
              disabled={readonly}
            />
          </FormControl>
        )}
      </obstetricHistoryInfoForm.Field>
      <obstetricHistoryInfoForm.Field
        name={`pregnancies[${index}].pregnancy.locationOfDelivery`}
      >
        {(subField) => (
          <FormControl>
            <Label className="mb-3">
              What was the location of the delivery?
            </Label>
            <RadioGroup
              className="flex flex-col items-start gap-3"
              value={
                subField.state.value as PregnancyInfoDTO['locationOfDelivery']
              }
              onValueChange={(value) => {
                subField.handleChange(
                  value as PregnancyInfoDTO['locationOfDelivery']
                );
                void obstetricHistoryInfoForm.handleSubmit();
              }}
            >
              {defaultPregnanancyLocationOfDelivery.map((location) => (
                <div key={location.value} className="flex gap-2 items-center">
                  <RadioItem
                    className="shrink-0"
                    id={`${subField.name}-${location.value}`}
                    value={location.value}
                    disabled={readonly}
                  >
                    <RadioIndicator />
                  </RadioItem>
                  <Label
                    htmlFor={`${subField.name}-${location.value}`}
                    className="m-0 cursor-pointer text-aster-secondary font-normal"
                  >
                    {location.text}
                  </Label>
                </div>
              ))}
            </RadioGroup>
          </FormControl>
        )}
      </obstetricHistoryInfoForm.Field>
      <obstetricHistoryInfoForm.Field
        name={`pregnancies[${index}].pregnancy.locationOfDeliveryOther`}
      >
        {(subField) => (
          <FormControl>
            <Input
              placeholder="Other location of delivery"
              value={subField.state.value}
              onChange={(event) => {
                subField.handleChange(event.target.value);
              }}
              onBlur={() => {
                if (subField.state.meta.isDirty) {
                  void obstetricHistoryInfoForm.handleSubmit();
                }
              }}
              disabled={readonly}
            />
          </FormControl>
        )}
      </obstetricHistoryInfoForm.Field>
      <Fragment key={fieldValue.children.length}>
        <Typography variant={'h6'} customClass="my-2">
          Children in this pregnancy
        </Typography>
        {fieldValue.children.map((_, j) => (
          <Fragment key={`${index}-${j}`}>
            <Child
              pregnancyIndex={index}
              childIndex={j}
              field={field}
              fieldValue={fieldValue}
              obstetricHistoryInfoForm={obstetricHistoryInfoForm}
              readonly={readonly}
            />
          </Fragment>
        ))}
        <div>
          <Button
            size="default"
            type="button"
            variant="secondary"
            onClick={() => {
              const updatedPregnancies = [...field.state.value];
              const newChild = {
                ...defaultPregnancyInfo.children[0],
              };
              updatedPregnancies[index].children.push(newChild);

              field.handleChange(updatedPregnancies);
              void obstetricHistoryInfoForm.handleSubmit();
            }}
          >
            + Add Child
          </Button>
        </div>
      </Fragment>
    </div>
  );
};

export default Pregnancy;
