import { useQuery } from '@tanstack/react-query';

import axios from '../../../app/axiosConfig';
import { PlanInformation } from '@aster/app/core/shared/dtos/stripe';

const getPlan = async () => {
  const { data } = await axios.get<PlanInformation>('/v2/stripe/plan');

  return data;
};

export const usePlanQuery = () => {
  const { data: plan, ...rest } = useQuery({
    queryKey: ['plan'],
    queryFn: getPlan,
    refetchInterval: 5000,
  });

  return {
    plan,
    ...rest,
  };
};
