import { MessageThreadDTO } from '@aster/app/message/shared/dtos';
import { Button } from '@aster/client/ui/Button/Button';
import {
  faBoxArchive,
  faInboxOut,
  faReply,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMessagesStore } from '../store/message.store';
import { useArchiveMessageThreadMutation } from '../mutations/archive-thread.mutation';
import { useUnarchiveMessageThreadMutation } from '../mutations/unarchive-thread.mutation';

type MessageThreadActionsProps = {
  messageThread: MessageThreadDTO;
  onReply: () => void;
  currentActiveSearchQueryKey: (string | number)[];
  currentArchivedSearchQueryKey: (string | number)[];
};
export const MessageThreadActions = ({
  messageThread,
  onReply,
  currentActiveSearchQueryKey,
  currentArchivedSearchQueryKey,
}: MessageThreadActionsProps) => {
  const clearMessageThreadID = useMessagesStore(
    (state) => state.clearMessageThreadID
  );
  const selectedMessageThreadID = useMessagesStore(
    (state) => state.selectedMessageThreadID
  );
  const archiveMessageThread = useArchiveMessageThreadMutation({
    messageThreadID: selectedMessageThreadID as string,
    currentActiveSearchQueryKey,
    currentArchivedSearchQueryKey,
  });
  const unarchiveMessageThread = useUnarchiveMessageThreadMutation({
    messageThreadID: selectedMessageThreadID as string,
    currentActiveSearchQueryKey,
    currentArchivedSearchQueryKey,
  });

  return (
    <div className="flex items-center gap-2 lg:mb-auto lg:ml-auto mt-4 lg:mt-0">
      {messageThread.status === 'active' && (
        <>
          <Button
            onClick={() => onReply()}
            title="Reply"
            aria-label="Reply"
            variant="outline"
            className="size-10"
          >
            <FontAwesomeIcon icon={faReply} />
          </Button>
          <Button
            onClick={() => {
              clearMessageThreadID();
              archiveMessageThread.mutate(selectedMessageThreadID as string);
            }}
            title="Archive"
            aria-label="Archive"
            variant="outline"
            className="size-10"
          >
            <FontAwesomeIcon icon={faBoxArchive} />
          </Button>
        </>
      )}
      {messageThread.status === 'archived' && (
        <Button
          onClick={() => {
            clearMessageThreadID();
            unarchiveMessageThread.mutate(selectedMessageThreadID as string);
          }}
          title="Unarchive"
          aria-label="Unarchive"
          variant="outline"
          className="size-10"
        >
          <FontAwesomeIcon icon={faInboxOut} />
        </Button>
      )}
    </div>
  );
};
