import { PatientInfoDTO } from '@aster/app/core/shared/dtos/patient';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import dayjs from 'dayjs';

export type PregnancyInfoFields = Pick<
  PatientInfoDTO,
  | 'pregnant'
  | 'lmp'
  | 'edd'
  | 'dateOfConception'
  | 'unsureDueDate'
  | 'dateToCalculateDueDate'
  | 'hadUltrasounds'
  | 'ultraSoundDetails'
>;

export const usePregnancyInfoForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues: PatientInfoDTO | undefined;
  onSubmit: (value: Partial<PregnancyInfoFields>) => void;
}) => {
  const pregnancyInfoForm = useForm({
    defaultValues: {
      pregnant: defaultValues?.pregnant ?? null,
      lmp: defaultValues?.lmp
        ? dayjs(defaultValues?.lmp).format('MM/DD/YYYY')
        : undefined,
      edd: defaultValues?.edd
        ? dayjs(defaultValues?.edd).format('MM/DD/YYYY')
        : undefined,
      dateOfConception: defaultValues?.dateOfConception
        ? dayjs(defaultValues?.dateOfConception).format('MM/DD/YYYY')
        : undefined,
      unsureDueDate: defaultValues?.unsureDueDate ?? null,
      dateToCalculateDueDate:
        defaultValues?.dateToCalculateDueDate ?? undefined,
      hadUltrasounds: defaultValues?.hadUltrasounds ?? null,
      ultraSoundDetails: defaultValues?.ultraSoundDetails ?? '',
    } as PregnancyInfoFields,
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      value.edd = value.edd === '' ? null : value.edd;
      value.lmp = value.lmp === '' ? null : value.lmp;
      value.dateOfConception =
        value.dateOfConception === '' ? null : value.dateOfConception;
      onSubmit(value);
    },
  });
  return pregnancyInfoForm;
};
