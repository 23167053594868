import { useCallback, useRef, useState } from 'react';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import PersonalInformation from './forms/PersonalInformation';
import ContactInformation from './forms/ContactInformation';
import MedicalHistoryInformation from './forms/MedicalHistory';
import Lifestyle from './forms/Lifestyle';
import SocialInformation from './forms/SocialInformation';
import Relationship from './forms/Relationship';
import Insurance from './forms/Insurance';
import ContactList from './forms/ContactList';
import GynecologicHistory from './forms/GynecologicHistory';
import Preferences from './forms/Preferences';
import AdditionalInformation from './forms/AdditionalInformation';
import { StyledTab } from '../../components/StyledTab';
import { LinkTab } from '../../components/Tabs';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Divider from '@mui/material/Divider';
import LoadingGuestToken from '../public-forms/LoadingGuestToken';
import { useGuestPatientInfoByToken } from './queries/guest-patient-info.query';
import { useUpdatePatientByTokenMutation } from './mutations/update-patient-by-token.mutation';
import PregnancyInformation from './forms/PregnancyInformation';
import ObstetricInformation from './forms/ObstetricInformation/ObstetricInformation';
import { Button } from '@aster/client/ui/Button/Button';
import { Token } from '@aster/app/core/shared/dtos/token';

export type PublicIntakeFormProps = {
  token: Token;
  onComplete: () => void;
};

export type FormRef = {
  submit: () => void;
};

function PublicIntakeForm({ token, onComplete }: PublicIntakeFormProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('twmd'));
  const [activeTab, setActiveTab] = useState(0);

  const [lastClickedButton, setLastClickedButton] = useState<
    'next' | 'previous' | 'complete' | null
  >(null);
  const { patientInfo, isPatientLoading, refetchPatient } =
    useGuestPatientInfoByToken(token.token);

  const { updatePatientMutation } = useUpdatePatientByTokenMutation(
    token.token,
    async () => {
      void refetchPatient();
      if (lastClickedButton === 'next') {
        await goToNextTab();
      }
    }
  );

  const personalInfoFormRef = useRef<FormRef>(null);
  const contactInfoFormRef = useRef<FormRef>(null);
  const relationshipFormRef = useRef<FormRef>(null);
  const insuranceFormRef = useRef<FormRef>(null);
  const contactListFormRef = useRef<FormRef>(null);
  const gynecologicHistoryFormRef = useRef<FormRef>(null);
  const pregnancyInfoFormRef = useRef<FormRef>(null);
  const obstetricHistoryFormRef = useRef<FormRef>(null);
  const medicalHistoryFormRef = useRef<FormRef>(null);
  const lifestyleFormRef = useRef<FormRef>(null);
  const socialInfoFormRef = useRef<FormRef>(null);
  const additionalInfoFormRef = useRef<FormRef>(null);
  const preferencesFormRef = useRef<FormRef>(null);

  const activeTabMap: { [key: number]: React.RefObject<FormRef> } = {
    0: personalInfoFormRef,
    1: contactInfoFormRef,
    2: relationshipFormRef,
    3: insuranceFormRef,
    4: contactListFormRef,
    6: gynecologicHistoryFormRef,
    7: pregnancyInfoFormRef,
    8: obstetricHistoryFormRef,
    9: medicalHistoryFormRef,
    10: lifestyleFormRef,
    11: socialInfoFormRef,
    12: additionalInfoFormRef,
    13: preferencesFormRef,
  };

  const lastTab = 13;
  const isLastTab = activeTab === lastTab;

  const goToPreviousTab = useCallback(() => {
    setLastClickedButton('previous');
    if (activeTab === 6) {
      setActiveTab((prev) => prev - 2);
    } else {
      setActiveTab((prev) => (prev > 0 ? prev - 1 : prev));
    }
  }, [activeTab, setActiveTab, setLastClickedButton]);

  const submitForm = useCallback(() => {
    setLastClickedButton('next');
    const currentFormRef = activeTabMap[activeTab];
    currentFormRef.current?.submit();
    return;
  }, [activeTab, activeTabMap]);

  const goToNextTab = useCallback(async () => {
    if (lastClickedButton === 'next') {
      if (activeTab === 4) {
        setActiveTab((prev) => prev + 2);
      } else {
        setActiveTab((prev) => (prev < lastTab ? prev + 1 : prev));
      }
    }
    setLastClickedButton(null);
  }, [activeTab, lastClickedButton, setActiveTab]);

  const completeForm = () => {
    setLastClickedButton('complete');
    onComplete();
  };

  if (isPatientLoading) {
    return (
      <LoadingGuestToken text="Just a moment, we're getting your intake form ready." />
    );
  }

  if (patientInfo) {
    return (
      <div className="flex flex-col flex-1 h-full">
        <div className="w-full md:!h-[100px] bg-white px-10 border-b border-grayLight pb-2">
          <div className="grid grid-cols-12">
            <div className="md:col-span-7 col-span-12">
              <div className="flex flex-row mt-5">
                <AssignmentRoundedIcon className="text-gray-500 text-h5 " />
                <span className="text-h5 text-gray-500 ml-1 mt-[-5px]">
                  Patient Profile
                </span>
              </div>
              <div className="">
                <p className="text-h4 text-black font-semibold ml-1">{`${
                  patientInfo.firstName
                } ${
                  patientInfo.preferredName
                    ? `"${patientInfo.preferredName}"`
                    : ''
                } ${patientInfo.lastName}`}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex w-full bg-white md:px-5 flex-1 overflow-y-auto border-bottom">
          <div className="flex flex-col md:flex-row w-full bg-white mt-4 relative overflow-y-auto">
            <Tabs
              orientation={isMobile ? 'horizontal' : 'vertical'}
              scrollButtons
              allowScrollButtonsMobile
              variant="scrollable"
              value={activeTab}
              onChange={(_, newTab) => setActiveTab(newTab)}
              aria-label="Vertical tabs"
              className="min-w-full w-fit md:min-w-[220px] max-w-screen mb-4"
              sx={{
                borderRight: 1,
                borderColor: 'divider',
                overflow: 'auto',
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: 0.3 },
                },
              }}
            >
              <StyledTab label="Personal Information" />
              <StyledTab label="Contact Information" />
              <StyledTab label="Relationship" />
              <StyledTab label="Insurance" />
              <StyledTab label="Contact List" />
              <Divider
                className="bg-grey-200 m-0 h-auto my-4"
                variant="middle"
                orientation={isMobile ? 'vertical' : 'horizontal'}
              />
              <StyledTab label="Gynecologic History" />
              <StyledTab label="Pregnancy Information" />
              <StyledTab label="Obstetric History" />
              <StyledTab label="Medical History" />
              <StyledTab label="Lifestyle" />
              <StyledTab label="Social Information" />
              <StyledTab label="Additional Information" />
              <StyledTab label="Preferences" />
            </Tabs>
            <div className="flex overflow-y-auto w-full h-full relative">
              <LinkTab value={activeTab} index={0}>
                <PersonalInformation
                  ref={activeTabMap[0]}
                  patientInfo={patientInfo}
                  updatePatientMutation={updatePatientMutation}
                  isPatientLoading={isPatientLoading}
                />
              </LinkTab>
              <LinkTab value={activeTab} index={1}>
                <ContactInformation
                  ref={activeTabMap[1]}
                  patientInfo={patientInfo}
                  updatePatientMutation={updatePatientMutation}
                  isPatientLoading={isPatientLoading}
                />
              </LinkTab>
              <LinkTab value={activeTab} index={2}>
                <Relationship
                  ref={activeTabMap[2]}
                  patientInfo={patientInfo}
                  updatePatientMutation={updatePatientMutation}
                  isPatientLoading={isPatientLoading}
                />
              </LinkTab>
              <LinkTab value={activeTab} index={3}>
                <Insurance
                  ref={activeTabMap[3]}
                  patientInfo={patientInfo}
                  updatePatientMutation={updatePatientMutation}
                  isPatientLoading={isPatientLoading}
                />
              </LinkTab>
              <LinkTab value={activeTab} index={4}>
                <ContactList
                  ref={activeTabMap[4]}
                  patientInfo={patientInfo}
                  updatePatientMutation={updatePatientMutation}
                  isPatientLoading={isPatientLoading}
                />
              </LinkTab>
              <LinkTab value={activeTab} index={6}>
                <GynecologicHistory
                  ref={activeTabMap[6]}
                  patientInfo={patientInfo}
                  updatePatientMutation={updatePatientMutation}
                  isPatientLoading={isPatientLoading}
                />
              </LinkTab>
              <LinkTab value={activeTab} index={7}>
                <PregnancyInformation
                  ref={activeTabMap[7]}
                  patientInfo={patientInfo}
                  updatePatientMutation={updatePatientMutation}
                  isPatientLoading={isPatientLoading}
                />
              </LinkTab>
              <LinkTab value={activeTab} index={8}>
                <ObstetricInformation
                  ref={activeTabMap[8]}
                  patientInfo={patientInfo}
                  updatePatientMutation={updatePatientMutation}
                  isPatientLoading={isPatientLoading}
                  token={token}
                />
              </LinkTab>
              <LinkTab value={activeTab} index={9}>
                <MedicalHistoryInformation
                  ref={activeTabMap[9]}
                  patientInfo={patientInfo}
                  updatePatientMutation={updatePatientMutation}
                  isPatientLoading={isPatientLoading}
                />
              </LinkTab>
              <LinkTab value={activeTab} index={10}>
                <Lifestyle
                  ref={activeTabMap[10]}
                  patientInfo={patientInfo}
                  updatePatientMutation={updatePatientMutation}
                  isPatientLoading={isPatientLoading}
                />
              </LinkTab>
              <LinkTab value={activeTab} index={11}>
                <SocialInformation
                  ref={activeTabMap[11]}
                  patientInfo={patientInfo}
                  updatePatientMutation={updatePatientMutation}
                  isPatientLoading={isPatientLoading}
                />
              </LinkTab>
              <LinkTab value={activeTab} index={12}>
                <AdditionalInformation
                  ref={activeTabMap[12]}
                  patientInfo={patientInfo}
                  updatePatientMutation={updatePatientMutation}
                  isPatientLoading={isPatientLoading}
                />
              </LinkTab>
              <LinkTab value={activeTab} index={13}>
                <Preferences
                  ref={activeTabMap[13]}
                  patientInfo={patientInfo}
                  updatePatientMutation={updatePatientMutation}
                  isPatientLoading={isPatientLoading}
                />
              </LinkTab>
              <div className="p-6 flex justify-between h-fit pb-5 gap-6 md:gap-40 fixed md:absolute bottom-0 w-full bg-white">
                <Button
                  className="rounded-[6px]"
                  disabled={
                    updatePatientMutation.status === 'pending' ||
                    activeTab === 0
                  }
                  variant={'outline'}
                  onClick={goToPreviousTab}
                >
                  Previous
                </Button>
                <Button
                  isLoading={updatePatientMutation.status === 'pending'}
                  className="rounded-[6px]"
                  disabled={updatePatientMutation.status === 'pending'}
                  variant={'default'}
                  onClick={isLastTab ? completeForm : submitForm}
                >
                  {isLastTab ? 'Complete' : 'Next'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
}

export default PublicIntakeForm;
