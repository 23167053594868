import { PatientInfoDTO } from '@aster/app/core/shared/dtos/patient';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';

export type RelationshipInfoFields = Pick<
  PatientInfoDTO,
  | 'relationshipStatus'
  | 'partnerName'
  | 'partnerRace'
  | 'partnerEthnicity'
  | 'partnerPreferredLanguage'
  | 'partnerOccupation'
  | 'partnerEducation'
  | 'partnerPhone'
>;

export const useReleationshipInfoForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues: PatientInfoDTO | undefined;
  onSubmit: (value: Partial<RelationshipInfoFields>) => void;
}) => {
  const relationshipForm = useForm({
    defaultValues: {
      relationshipStatus: defaultValues?.relationshipStatus ?? undefined,
      partnerName: defaultValues?.partnerName ?? undefined,
      partnerRace: defaultValues?.partnerRace ?? undefined,
      partnerEthnicity: defaultValues?.partnerEthnicity ?? undefined,
      partnerPreferredLanguage:
        defaultValues?.partnerPreferredLanguage ?? undefined,
      partnerOccupation: defaultValues?.partnerOccupation ?? undefined,
      partnerEducation: defaultValues?.partnerEducation ?? undefined,
      partnerPhone: defaultValues?.partnerPhone ?? undefined,
    },
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      onSubmit(value);
    },
  });

  return relationshipForm;
};
