import {
  PatientListItemDTO,
  PatientSearchArgsDTO,
} from '@aster/app/core/shared/dtos/patient';
import {
  PaginatedResult,
  searchArgsToString,
} from '@aster/app/core/shared/dtos/search';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import axios from '../../../app/axiosConfig';

const fetchPatients = async (searchArgs: PatientSearchArgsDTO = {}) => {
  const response = await axios.get<PaginatedResult<PatientListItemDTO>>(
    `/v2/patients?${searchArgsToString(searchArgs)}`
  );
  return response.data;
};

export function useSearchPatientsQuery(
  searchArgs: PatientSearchArgsDTO = {
    search: '',
    page: 0,
    pageSize: 10,
  }
) {
  const {
    data: paginatedPatients,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: [
      'patients',
      searchArgs.search,
      searchArgs.page,
      searchArgs.pageSize,
      searchArgs.sortBy,
      searchArgs.sortDir,
      ...(searchArgs.statusFilter ?? []),
      searchArgs.startDueDate,
      searchArgs.endDueDate,
      ...(searchArgs.gestationalAgeFilter ?? []),
    ].filter((x) => x != null),
    queryFn: () => fetchPatients(searchArgs),
    placeholderData: keepPreviousData,
  });

  return {
    paginatedPatients,
    arePatientsLoading: isLoading,
    arePatientsFetching: isFetching,
  };
}
