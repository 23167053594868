import {
  PaginatedResult,
  searchArgsToString,
} from '@aster/app/core/shared/dtos/search';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import axios from '../../../app/axiosConfig';
import {
  MessageThreadListItemDTO,
  SearchMessageThreadDTO,
} from '@aster/app/message/shared/dtos';

const searchMessageThreads = async (
  searchArgs: SearchMessageThreadDTO = {}
) => {
  const response = await axios.get<PaginatedResult<MessageThreadListItemDTO>>(
    `/v2/message/thread?${searchArgsToString(searchArgs)}`
  );
  return response.data;
};

export function useSearchMessageThreadQuery(
  searchArgs: SearchMessageThreadDTO = {
    search: '',
    page: 0,
    pageSize: 10,
  }
) {
  const queryKey = [
    'messageThreads',
    searchArgs.search,
    searchArgs.page,
    searchArgs.pageSize,
    searchArgs.sortBy,
    searchArgs.sortDir,
    searchArgs.staffID,
    ...(searchArgs.statusFilter ?? []),
  ].filter((x) => x != null);

  const {
    data: paginatedMessageThreads,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () => searchMessageThreads(searchArgs),
    placeholderData: keepPreviousData,
    refetchInterval: 30000,
  });

  return {
    paginatedMessageThreads,
    areMessageThreadsLoading: isLoading,
    areMessageThreadsFetching: isFetching,
    currentSearchQueryKey: queryKey,
  };
}
