import { useQuery } from '@tanstack/react-query';
import { EncounterDTO } from '@aster/app/core/shared/dtos/encounter';
import axios from '../../../app/axiosConfig';

const fetchEncounters = async (patient: string) => {
  const response = await axios.get<EncounterDTO[]>(
    `encounters/byPatient/${patient}`
  );
  return response.data;
};

export const useGetEncounters = (patient: string) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['encountersByPatient', patient],
    queryFn: () => fetchEncounters(patient),
  });

  return { data, isLoading, refetch };
};
