import React, { useMemo, useState } from 'react';

import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HourglassBottomOutlinedIcon from '@mui/icons-material/HourglassBottomOutlined';
import { CircularProgress, Divider, Snackbar } from '@mui/material';
import {
  GridColDef,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Button } from '@aster/client/ui/Button/Button';
import { cn } from '@aster/client/utils';

import MemberOptionsMenu from './MemberOptionsMenu';
import InviteMembersModal from './modals/InviteMembersModal';
import RevokeInviteModal from './modals/RevokeInviteModal';
import useInviteMembersMutation from './mutations/invite-members-mutation';

import axios from '../../app/axiosConfig';
import CircleWithInitials from '../../components/CircleWIthInitials';
import StyledDataGrid from '../../components/StyledDataGrid';
import Typography from '../../components/Typography';
import {
  MutationScope,
  createMutationScopeID,
} from '../../mutations/utils/create-mutation-scope-id';
import { colors } from '../../theme';
import { usePlanQuery } from './queries/usePlanQuery';
import { Link } from 'react-router';
import { StaffListItemDTO } from '@aster/app/core/shared/dtos/staff';

const CustomAlert = ({
  className,
  title,
  body,
  action,
}: {
  className?: string;
  title: string;
  body: string;
  action?: React.ReactNode;
}) => {
  return (
    <div
      className={cn(
        'p-4 bg-gray-100 rounded-md flex gap-4 items-center',
        className
      )}
    >
      <div className="text-primary flex-1">
        <div className="space-x-3 content-center font-medium text-base">
          <FontAwesomeIcon
            icon={faCircleInfo}
            className="w-4 text-aster-secondary"
          />
          <span>{title}</span>
        </div>
        <div className="pl-7 text-[14px]">{body}</div>
      </div>
      {action && <div>{action}</div>}
    </div>
  );
};

const MembersAndBilling = () => {
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [openRevokeModal, setOpenRevokeModal] = useState(false);
  const [selectedToken, setSelectedToken] = useState('');
  const [selectedEmail, setSelectedEmail] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const queryClient = useQueryClient();

  const { plan, isLoading: isPlanLoading } = usePlanQuery();

  const revokeInvite = async (token: string) =>
    axios.post(`token/${token}/invalidate`);

  const resendInvite = async (email: string) => {
    setSnackBarMessage(`Resent invite for ${email}`);
    setOpenSnackbar(true);
    await axios.post(`staffs/invite`, { email }).then(() => {
      void queryClient.invalidateQueries({ queryKey: ['pendingInvites'] });
    });
  };

  const openRevokeModalHandler = (token: string, email: string) => {
    setSelectedToken(token);
    setSelectedEmail(email);
    setOpenRevokeModal(true);
  };

  const fetchMembers = async () => {
    const response = await axios.get<StaffListItemDTO[]>('staffs');
    return response.data;
  };

  const fetchPendingInvites = async () => {
    const response = await axios.get(`staffs/pending-invites`);
    return response.data;
  };

  const revokeStaffInviteMutation = useMutation({
    mutationKey: ['revokeStaffInvite'],
    mutationFn: revokeInvite,
    scope: {
      id: createMutationScopeID(MutationScope.MEMBER),
    },
    onMutate: async (token) => {
      setSnackBarMessage(`Revoked invite for ${selectedEmail}`);
      setOpenSnackbar(true);
      const previousPendingInvites = queryClient.getQueryData([
        'pendingInvites',
      ]);
      queryClient.setQueryData(['pendingInvites'], (old: any) => {
        return old.filter((f: any) => f.token !== token);
      });
      return { previousPendingInvites };
    },
    onError: (_err, _token, context) => {
      queryClient.setQueryData(
        ['pendingInvites'],
        context?.previousPendingInvites
      );
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: ['pendingInvites'] });
    },
  });

  const { inviteMembersMutation } = useInviteMembersMutation();

  const { data: pendingInvites, isLoading: pendingInvitesLoading } = useQuery({
    queryKey: ['pendingInvites'],
    queryFn: fetchPendingInvites,
  });
  const { data: staffData, isLoading } = useQuery({
    queryKey: ['staffs'],
    queryFn: fetchMembers,
  });

  const gridRows = useMemo(() => {
    if (!pendingInvitesLoading && !isLoading) {
      const staffRow = staffData
        ? staffData.map((r: any) => ({
            id: r.id,
            name: `${r.firstName} ${r.lastName}`,
            email: r.email,
            role:
              r.role.charAt(0).toUpperCase() +
              r.role.slice(1) +
              ' - Full Access',
          }))
        : [];
      const pendingStaffRows = pendingInvites
        ? pendingInvites
            .filter((f: any) => f.token && f.metadata.targetEmail)
            .map((r: any) => {
              if (r.metadata.targetEmail && r.token) {
                return {
                  id: r.token,
                  name: 'Pending...',
                  email: r.metadata.targetEmail,
                  role: '',
                };
              }
              return undefined;
            })
        : [];
      return [...staffRow, ...pendingStaffRows];
    }
    return [];
  }, [pendingInvitesLoading, isLoading, staffData, pendingInvites]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      width: 400,
      renderCell: (params) => {
        const { name, email, role } = params.row;
        return (
          <div className="flex items-center">
            {role.length ? (
              <CircleWithInitials
                name={name}
                height={40}
                width={40}
                textVariant="p-sm"
              />
            ) : (
              <div className="h-[40px] w-[40px] mx-2 flex items-center justify-center">
                <HourglassBottomOutlinedIcon />
              </div>
            )}
            <div className="flex flex-col m-1">
              {role ? (
                <>
                  <Typography
                    customClass="font-semibold"
                    variant="body"
                    text={name}
                  />
                  <Typography variant="bodySmall" text={email} />
                </>
              ) : (
                <>
                  <Typography
                    customClass="font-semibold text-gray-500"
                    variant="body"
                    text={name}
                  />
                  <Typography
                    variant="bodySmall"
                    customClass="text-gray-500"
                    text={email}
                  />
                </>
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: 'role',
      width: 200,
    },
    {
      field: 'id',
      flex: 1,
      align: 'right',

      renderCell: (params) => {
        const { role, id, email } = params.row;
        if (role) {
          return null;
        }
        return (
          <MemberOptionsMenu
            token={id}
            email={email}
            sendInvite={() => resendInvite(email)}
            openRevokeModal={() => openRevokeModalHandler(id, email)}
          />
        );
      },
    },
  ];

  const CustomToolBar = () => (
    <div className="flex justify-between m-2">
      <div className="flex flex-1">
        <GridToolbarQuickFilter className="flex-1 mr-8" />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </div>
      <span className="flex items-center text-h6 text-aster-secondary mx-4">
        {staffData?.length ?? 0}&nbsp;member
        {(staffData?.length ?? 0) > 1 ? 's' : ''}
      </span>
    </div>
  );

  const canSeePlanControls = !isPlanLoading && plan?.type === 'standard';
  const canAddMembers = plan?.availableSeats || plan?.type !== 'standard';

  if (isLoading || pendingInvitesLoading) {
    return (
      <div className="flex flex-col justify-center items-center w-full h-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="full-w-container flex-col">
      <Typography variant="h2" customClass="mb-4">
        Members & Billing
      </Typography>
      <Divider className="my-6" />
      <div className="grid grid-cols-4 gap-6">
        <div className="grid grid-rows-3">
          <Typography variant="h5" customClass="font-normal">
            Your plan
          </Typography>
          <Typography variant="h4">
            {isPlanLoading ? '...' : plan?.name ?? 'N/A'}
          </Typography>
          {canSeePlanControls && (
            <Link to={plan?.customerPortalUpgradeUrl ?? ''} target="_blank">
              <Button
                variant="ghost"
                size="sm"
                className="w-fit bg-gray-100 hover:bg-gray-200 mt-2"
                disabled={isPlanLoading || !plan?.customerPortalUpgradeUrl}
              >
                Upgrade Plan
              </Button>
            </Link>
          )}
        </div>
        <div className="grid grid-rows-3">
          <Typography variant="h5" customClass="font-normal">
            Monthly seats
          </Typography>
          <Typography variant="h4">
            {isPlanLoading ? '...' : plan?.seats ?? 0}
          </Typography>
          {canSeePlanControls && (
            <Link to={plan?.customerPortalUpgradeUrl ?? ''} target="_blank">
              <Button
                variant="ghost"
                size="sm"
                className="w-fit bg-gray-100 hover:bg-gray-200 mt-2"
                disabled={isPlanLoading || !plan?.customerPortalUpgradeUrl}
              >
                Adjust Seats
              </Button>
            </Link>
          )}
        </div>
        <div className="grid grid-rows-3">
          <Typography variant="h5" customClass="font-normal">
            Monthly invoice total
          </Typography>
          <Typography variant="h4">
            {isPlanLoading ? '...' : plan?.price ?? 0}
          </Typography>
          {canSeePlanControls && (
            <Link
              to={plan?.customerPortalUrl ?? ''}
              target="_blank"
              className="mt-2 text-aster-main text-sm font-medium"
            >
              See cost breakdown
            </Link>
          )}
        </div>
        <div className="grid grid-rows-3">
          <Typography variant="h5" customClass="font-normal">
            Next billing date
          </Typography>
          <Typography variant="h4">
            {isPlanLoading ? '...' : plan?.nextBillingDate}
          </Typography>
        </div>
      </div>
      <Divider className="my-6" />
      {!canAddMembers && (
        <CustomAlert
          className="mb-6"
          title="You've used all your seats for this plan."
          body="Upgrade to invite more team members."
          action={
            plan?.customerPortalUrl ? (
              <Link to={plan.customerPortalUrl} target="_blank">
                <Button>Upgrade</Button>
              </Link>
            ) : null
          }
        />
      )}
      {plan?.type === 'custom' && (
        <CustomAlert
          className="mb-6"
          title="You're on a custom plan."
          body="For any subscription/billing related inquiry, please reach out to us directly."
        />
      )}
      <div className="flex mb-6">
        <div className="w-full">
          <Typography variant="h4" customClass="mb-1">
            Your Team
          </Typography>
        </div>
        <div className="flex w-full items-center justify-end gap-3">
          <Typography
            variant="body"
            customClass="inline-flex text-aster-secondary"
          >
            {isPlanLoading || plan?.type !== 'standard'
              ? ''
              : `${plan?.availableSeats ?? 0} ${
                  plan?.availableSeats === 1 ? 'seat' : 'seats'
                } available`}
          </Typography>
          <Button
            onClick={() => (canAddMembers ? setOpenInviteModal(true) : null)}
            disabled={!canAddMembers}
          >
            Add Members
          </Button>
        </div>
      </div>
      <StyledDataGrid
        className="grid w-full"
        pageSizeOptions={[10, 25, 50]}
        disableColumnFilter
        disableDensitySelector
        disableColumnSelector
        disableColumnMenu
        rows={gridRows}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 6,
            },
          },
        }}
        // columnHeaders config gives console warning but removes tables headers
        slots={{ toolbar: CustomToolBar, columnHeaders: () => null }}
        columns={columns}
        autoHeight
        disableRowSelectionOnClick
      />
      {Boolean(plan?.availableSeats) && (
        <InviteMembersModal
          open={openInviteModal}
          title="Invite to your practice"
          description="By adding this member you are granting them full access."
          confirm="Send invites"
          dismiss="Cancel"
          handleClose={() => setOpenInviteModal(false)}
          handleConfirm={inviteMembersMutation}
          handleCancel={() => setOpenInviteModal(false)}
        />
      )}
      <RevokeInviteModal
        open={openRevokeModal}
        title="Revoke invite"
        email={selectedEmail}
        token={selectedToken}
        confirm="Confirm"
        dismiss="Cancel"
        handleClose={() => setOpenRevokeModal(false)}
        handleConfirm={() => revokeStaffInviteMutation.mutate(selectedToken)}
        handleCancel={() => setOpenRevokeModal(false)}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        autoHideDuration={3000}
        message={snackBarMessage}
        sx={{
          backgroundColor: colors.gray,
          color: colors.white,
        }}
      />
    </div>
  );
};

export default MembersAndBilling;
